@import '../../_lib/style/param';
.elmo-datatable {
  &__select-message {
    color: $gray-rgba-700;
    font-size: 13.5px;
    letter-spacing: 0.7px;
    line-height: 16px;

    span {
      color: $primary;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
