@import '../_lib/style/_param.scss';
@import '../_lib/style/mixin';

.elmo-radio-buttons {
  position: relative;
  z-index: 0;

  .elmo-radio {
    transition: border 0.2s;
    display: flex;
    margin-bottom: 0;
    height: 48px;
    border: 1px solid $gray-400;
    margin-right: -1px;

    &:focus {
      box-shadow: 0 0 3px $primary-border;
    }

    &__element {
      position: absolute;
      height: 100%;
      margin-right: 0;
    }

    &__label {
      font-weight: 400;
      padding: 0.5em 1em;
      align-self: center;
      white-space: nowrap;
    }

    &--checked {
      border: 2px solid $primary;
      z-index: 1;

      .elmo-radio__label {
        color: $primary;
      }
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    .MuiSvgIcon-root {
      margin-left: -6px;
      margin-right: -6px;
    }
  }

  .elmo-radio__icon {
    display: none;
  }

  &--col {
    flex-direction: column;

    .elmo-radio {
      margin: 0 0 -1px 0;

      &:first-child {
        border-radius: 3px 3px 0 0;
      }

      &:last-child {
        border-radius: 0 0 3px 3px;
      }
    }
  }

  &--solid {
    margin-right: -2px;

    .elmo-radio {
      padding-left: 14px;
      padding-right: 14px;
      cursor: pointer;
      background-color: #ffffff;
      border: 2px solid transparent;
      height: 48px;
      border-radius: 3px;
      display: inline-flex;
      margin-right: 2px;
      box-shadow: $shadow-100;
      transition: border-color $transition-speed-normal,
        background-color $transition-speed-normal,
        color $transition-speed-normal;

      &--checked {
        background-color: $primary-bg;
        color: $primary;
        outline: none;
      }

      &:not(.elmo-radio--checked) {
        .elmo-badge {
          background-color: transparent;
          border: 1px solid $gray-700;
          color: $gray-800;
        }
      }

      &:focus:not(.elmo-radio--disabled) {
        border: 2px solid $primary;
        background-color: $primary-bg;
      }

      &:hover:not(.elmo-radio--disabled) {
        background-color: $gray-200;
      }

      &:active:not(.elmo-radio--disabled) {
        background-color: $gray-300;
      }

      &__label {
        padding: 0;
        color: $black;

        .MuiSvgIcon-root {
          margin-left: -4px;
          margin-right: -4px;
        }
      }

      &:first-child,
      &:last-child {
        border-radius: 3px;
      }
    }

    &.elmo-radio-buttons--col {
      .elmo-radio {
        margin-bottom: 2px;
      }
    }
  }

  &--grouped {
    box-shadow: $shadow-100;
    border-radius: 3px;

    .elmo-radio {
      padding-left: 16px;
      padding-right: 16px;
      cursor: pointer;
      background-color: #ffffff;
      border: none;
      height: 48px;
      display: inline-flex;
      transition: background-color $transition-speed-normal,
        color $transition-speed-normal;

      &--checked {
        background-color: $primary-bg;
        color: $primary;
        outline: none;
        overflow: hidden;

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background-color: transparent;
          box-shadow: $shadow-100;
        }

        &:before {
          left: -1px;
        }

        &:after {
          right: -1px;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }

      &__label {
        padding: 0;
        color: $black;

        .MuiSvgIcon-root {
          margin-left: -4px;
          margin-right: -4px;
        }
      }
    }

    &.elmo-radio-buttons--col {
      .elmo-radio {
        margin-bottom: 2px;
      }
    }
  }
}
