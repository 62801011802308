.day-draggable-wrapper {
  transform: translateZ(0);
}

.draggable-placeholder {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  pointer-events: none !important;
  opacity: 0.5;

   & * {
     pointer-events: none !important;
   }
}

