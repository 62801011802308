@import '../_lib/style/param';
@import '../_lib/style/mixin';

.elmo-datatable {
  $this: &;
  width: 100%;

  &--scroll {
    overflow-x: auto;

    #{$this}-table {
      width: auto;
      min-width: 100%;
    }
  }

  .hand-cursor {
    cursor: pointer;
  }

  &-wrapper {
    position: relative;
    display: inline-block;
  }

  &-table {
    th,
    td {
      padding: 12px;
      min-width: 120px;

      &.elmo-datatable__row-actions,
      &.elmo-datatable__row-checkbox {
        min-width: 0;
      }
    }

    th {
      border-style: solid;
      border-color: $gray;
      border-width: 0 0 1px 0;
      word-break: break-word;
    }

    &--full-width {
      min-width: 100%;
      table-layout: fixed;
    }
  }

  &--is-header-sticky {
    #{$this}__head {
      #{$this}__th,
      #{$this}__td {
        position: sticky;
        top: 0;
        background-color: $gray-200;
        z-index: 2;

        @include desktop {
          top: 0 !important;
        }
      }
    }

    &#{$this}--is-first-column-sticky {
      #{$this}__head {
        #{$this}__th:first-child,
        #{$this}__td:first-child {
          z-index: 3;
        }
      }
    }
  }

  &--is-first-column-sticky {
    tr {
      #{$this}__th:first-child,
      #{$this}__td:first-child {
        position: sticky;
        left: 0;
        background-color: $gray-200;
        z-index: 1;
      }
    }
  }
}
