@import "./src/assets/css/variables";

.input-with-icon {
  position: relative;

  &__icon-wrapper {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    padding: 3px;
  }

  ::placeholder {
    color: $gray-rgba-400;
  }
  ::-webkit-input-placeholder {
    color: $gray-rgba-400;
  }
  :-ms-input-placeholder {
    color: $gray-rgba-400;
  }
  ::-moz-placeholder {
    color: $gray-rgba-400;
  }

  &__icon-wrapper--no-icon {
    display: none;
  }

  & + .time-input-helper {
    position: absolute;
    top: -30px;
    z-index: -1;
    background: $gray-700;
    border: 1px solid $gray-700;
    border-radius: 3px;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity .1s ease-in, height .1s ease-in .3s;

    &.visible-hint {
      opacity: 1;
      height: 28px;
      transition: height .05s ease-in-out .15s, opacity .1s ease-in .3s;
      z-index: 1;
      span {
        display: block;
      }
    }

    span {
      display: none;
      padding: 4px 5px 3px;
      letter-spacing: 0;
      font-size: 12px;
      color: #fff;
      line-height: 18px;
    }
  }
}