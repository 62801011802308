@import '../../../../assets/css/variables';

.users-modal-search {
  .elmo-search__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.list {

  $this: &;

  .user-status {
    display: block;
    border-radius: 100%;
    margin-right: 10px;
    position: relative;
    top: 9px;
    min-width: 6px;
    max-width: 6px;
    max-height: 6px;
    min-height: 6px;
    width: 6px;
    height: 6px;
    &.green {
      background: #37c597;
    }
    &.red {
      background: #b11040;
    }
  }

  .list-scroller {
    overflow-y: scroll;
  }

  &__unassigned-item {
    padding: 15px 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-400;
    border-top: 1px solid $gray-400;
    svg {
      fill: $warning-dark;
      margin-right: 10px;
    }
    &.selected {
      background: $info-bg;
    }
  }

  &__group {
    &--item {
      padding: 15px 5px 0;
      display: flex;
      align-items: flex-start;
      position: relative;
      z-index: 0;
      cursor: pointer;
      &.selected {
        background: $info-bg;
        z-index: 1;
        border-bottom: 1px solid $gray-400;
        border-top: 1px solid $gray-400;
        #{$this}__group--user-info {
          border-bottom-color: transparent;
        }
        #{$this}__group--additional-info {
          display: block;
        }
      }
    }
    &--additional-info {
      display: none;
    }
    &--user-info {
      margin-left: 12px;
      border-bottom: 1px solid $gray-400;
      width: 100%;
      padding-bottom: 15px;
    }
    &--toggle {
      padding: 15px 5px;
      border-bottom: 1px solid $gray-400;
    }
  }
}