.shift-swap-proposal-item,
.shift-swap-list-item {
  border-radius: 6px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px #d6d6d6;
  .card-content {
    padding-left: 68px;
  }
  .card-actions {
    justify-content: flex-end;
    border-top: 1px solid #d6d6d6;
    span:first-of-type {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
}