@import 'src/elmo-elements/_lib/style/index';
.elmo-header__title-addon {
  display: flex;
  min-height: 48px;
  align-items: center;
  flex-shrink: 0;
  .elmo-badge {
  }
  .elmo-badge__label {
    display: none;
    @include mobile {
      display: inline-block;
    }
  }
}
