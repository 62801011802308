@import '../../../../../../assets/css/variables';
@import '../../../../../../assets/css/mixin';

.unavailability-calendar {

  position: relative;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__cell {
    height: 32px;
    margin: 1px;
    width: 100%;
    text-align: right;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    @include mobile {
      height: 60px;
      margin: 2px;
    }
    @include desktop {
      height: 120px;
    }
  }

  &__day-name {
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin: 1px;
    @include mobile {
      margin: 2px;
    }
  }

}