@import '../../_lib/style/index';

.elmo-nativetable-head {
  .elmo-nativetable-th {
    padding: 12px;
    font-weight: normal;
    color: $gray-rgba-700;
    font-size: 13.5px;
    letter-spacing: 0.7px;
    line-height: 24px;
    vertical-align: bottom;

    &--has-btn-custom {
      position: relative;
      padding-right: calc(48px + 0px); //button-width + spacing(text & button)

      .elmo-nativetable__btn-custom {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
