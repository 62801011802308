@import '../_lib/style/index';

.oxygen-dropdown-base {
  &__menu {
    opacity: 1;
    visibility: visible;
    box-shadow: $shadow-300;
    border: 1px solid $gray-400;
    border-radius: 5px;
    list-style-type: none;
    width: 200px;
    background: $white;
    padding: 8px 0;
    margin: 8px 0;
    overflow: auto;
    max-height: 50vh;

    &--is-width-auto {
      width: auto;
    }
  }

  &__divider {
    height: 1px;
    border: 0;
    color: $gray-rgba-400;
    background: $gray-rgba-400;
    margin: 4px 0 4px 16px;

    &--short {
      margin-left: (
        16px + 16px + 24px
      ); // left spacing + icon width + margin-right from icon
    }
  }

  &__menu-item {
    padding: 12px 16px;
    line-height: 1.5;
    cursor: pointer;
    text-align: left;
    position: relative;
    display: flex;

    &:hover,
    &:focus {
      outline: none;
      background: $primary-bg;
      color: $primary;
    }

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    &--active {
      outline: none;
      background: $primary-bg;
      color: $primary;
    }

    &--disabled {
      color: $gray-600;
      cursor: default;

      &:hover,
      &:focus {
        color: $gray-600;
        background: $white;
      }
    }

    &--danger {
      color: $secondary;

      &:hover {
        background: $danger-bg;
        color: $secondary;
      }
    }

    .MuiSvgIcon-root {
      margin-right: 16px;
      float: left; // using float to avoid white space between icon and text

      &.float-right {
        float: right;
        margin-right: 0;
        margin-left: 16px;
      }
    }

    &--has-deafult-divider,
    &--has-short-divider {
      margin-bottom: 9px;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -5px;
        left: 16px;
        pointer-events: none;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.14);
      }
    }

    &--has-short-divider {
      &:after {
        left: 16px + 16px + 24px;
      }
    }
  }
}
