@import '../../_lib/style/param';

.elmo-filter {
  &__modal {
    padding: 3px;

    button.elmo-filter__modal-button {
      transform: translateX(-4px);
      box-shadow: $shadow-100;
      border: none;
      color: $black;
      padding-left: 12px;
      padding-right: 12px;
      background-color: #fff;
      margin-right: -3px;

      &:focus {
        background-color: $primary-bg;
      }

      .elmo-btn__label {
        display: flex;
        align-items: center;
      }

      &--active {
        background: $primary-bg;
        color: $primary;
      }
    }
  }

  .elmo-modal {
    &__footer {
      .elmo-btn__label {
        width: 100%;
      }
    }
  }
}
