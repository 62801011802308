@import '../_lib/style/index';

.elmo-listtable {
  font-size: 16px;
  line-height: 24px;
  color: $gray-800;
  position: relative;

  &__row-checkbox {
    height: 24px;
    margin-left: -8px;
    margin-right: 16px;
    align-self: flex-start;

    .elmo-checkbox {
      margin-right: 0;

      .elmo-checkbox__element {
        margin-right: 0;
      }
    }
  }

  &__header-wrapper,
  &__row-wrapper {
    display: flex;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 2px;
    }
  }
}
