.elmo-datatable {
  td {
    vertical-align: top;

    .elmo-avatar {
      display: flex;

      &__label-icon {
        margin-top: -6px;
        margin-bottom: -6px;
      }

      .elmo-avatar__label {
        margin-left: 24px;
        white-space: nowrap;
      }
    }
  }
}
