@import '../_lib/style/param';

.elmo-multilevel-modal {
  $this: &;

  &__main {
    .elmo-modal__content {
      opacity: 1;
      transition: opacity 1s ease-in 1s;

      @media (min-width: $tablet-max) {
        opacity: 1;
        transition: all 0.3s ease-in;
      }
    }
  }

  &.is-sub-modal {
    #{$this}__main {
      .elmo-modal__content {
        opacity: 1;
        transition: all 0.3s ease-in;

        @media (min-width: $tablet-max) {
          opacity: 0;
          transition: all 1s ease-in;
        }
      }
    }
  }

  #{$this}__sub {
    animation: zIndexDown 0.3s forwards;
    position: relative;

    .elmo-modal__content {
      opacity: 0;
      transition: all 0.3s ease-in;

      @media (min-width: $tablet-max) {
        max-width: 0;
        transition: all 0.5s ease-in;
      }
    }

    &.is-active {
      animation: zIndexUp 0.3s forwards;

      .elmo-modal__content {
        transition: all 0.3s ease-in;
        opacity: 1;

        @media (min-width: $tablet-max) {
          max-width: 1000px;
          transition: all 0.5s ease-in;
        }
      }
    }
  }
}

@keyframes zIndexUp {
  0% {
    z-index: -10;
  }

  100% {
    z-index: 1300;
  }
}

@keyframes zIndexDown {
  0% {
    z-index: 1300;
  }

  100% {
    z-index: -10;
  }
}
