@import '../../assets/css/variables';
@import '../../assets/css/mixin';

.notes-icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  padding: 15px 0 0;
  margin-left: -5px;

  &.disabled {
    .notes-icon {
      color: $gray-600;
      span {
        color: $gray-600;
      }
      &:hover {
        background: $gray-rgba-300;
      }
      svg {
        fill: $gray-600;
      }
    }
  }
}

.notes-icon {
  cursor: pointer;
  position: relative;
  z-index: 1;
  color: $primary;
  display: flex;
  align-items: center;
  padding: 5px 7px;
  margin-left: -5px;

  &:hover {
    background: $primary-bg;
    border-radius: 3px;
    span {
      text-decoration: underline;
    }
  }
  span {
    display: inline-block;
    margin-left: 3px;
    font-size: 13px;
    vertical-align: top;
    color: $primary;
  }
  svg {
    fill: $primary;
  }
}