.summary-info {
  $this: &;
  color: rgba(0, 0, 0, 0.67);

  margin-bottom: 8px;
  border-bottom: 1px solid #DDDDDD;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &__raw {
    height: 22px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  #{$this} &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    padding-right: 18px;
    width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: -9px;
  }
  &__value {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: .2px;
    font-weight: 500;
    white-space: nowrap;

  }
  &__currency {
    font-weight: normal;
  }
  &__value-long {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 98px;
    white-space: nowrap;
  }
}
