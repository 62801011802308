.card-contextual-menu {
  height: 0;
  width: 45px;
  overflow: hidden;
  display: block;
  margin-left: auto;
}

.context-cut-action {
  opacity: .5;
}

.context-target-action {
  background: #e6f1ff;
  &.shift-card {
    background: #e6f1ff;
  }
}