@import '../../assets/css/variables';

.global-loading-overlay {
  position: fixed;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity $transition-speed-normal;
  opacity: 0;
  z-index: 1;

  &.is-loading {
    opacity: 1;
    pointer-events: auto;
  }

  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    opacity: $overlayOpacity;
  }
}
