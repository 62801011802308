@import '../_lib/style/param';

$block-size: 48px;
$calendar-block-size: 40px;

.elmo-datepicker {
  position: relative;

  &__input-wrapper {
    position: relative;
    width: 100%;

    input:read-only {
      background: #fff;
    }

    .btn-clear {
      background-color: transparent;
      border: none;
      cursor: pointer;
      height: 46px;
      width: 48px;
      box-sizing: border-box;
      position: absolute;
      top: 1px;

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .btn-clear {
      color: $body-color;
      right: 1px;
      border-radius: 3px;
    }
  }
}

.elmo-datepicker-calendar {
  z-index: 1000;
}
