.new-navigation {
  .roster-view-scrollable-wrapper {
    > div {
      top: 155px;
    }
  }

  .layout-header {
    background-color: rgb(237, 240, 245);
    position: fixed;
    top: 0;
    margin-top: 91px;
    right: 0;
    left: 0;
    z-index: 1099;

    @media (min-width: 992px) {
      padding-left: 280px;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: 91px;
      background-color: rgb(237, 240, 245);
    }
  }
}
