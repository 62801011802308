.warning-msg {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  background-color: #b1101e;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 1;
  margin-left: 15px;
  svg {
    width: 16px;
  }
}