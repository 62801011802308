@import 'src/elmo-elements/_lib/style/index';
/* Header Tabs */

.elmo-tabs {
  display: block;
  position: relative;
  z-index: 1;
  margin-bottom: 0;

  @include mobile {
    margin-bottom: -8px;
  }

  @include tablet {
    margin-bottom: -16px;
  }

  @include desktop {
    margin-bottom: -24px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    left: -1px;
    top: 0;
    height: 100%;
    width: 20px;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
    /* IE6-9 */
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    right: -1px;
    top: 0;
    height: 100%;
    width: 20px;
    background: -moz-linear-gradient(
      right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
    /* IE6-9 */
  }

  $this: &;

  &__nav {
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    padding-left: 0px;

    @include mobile {
      padding-left: 24px;
    }

    @include tablet {
      padding-left: 32px;
    }

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
    }

    > ul {
      white-space: nowrap;
      position: relative;

      > #{ $this }__item {
        margin: 0 12px;
        display: inline-block;

        a {
          transition: all 0.25s;
          display: block;
          color: $gray-700;
          @include text-style-button;
          line-height: 48px; // place line-height here so it override from above;
          position: relative;
          cursor: pointer;

          &:after {
            content: '';
            transition: all 0.2s;
            display: block;
            position: absolute;
            width: 0%;
            height: 5px;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            background-color: $gray-600;
          }

          &:hover:after {
            width: 100%;
          }
        }

        &--active {
          a {
            color: $gray-800;
            cursor: default;

            &:after {
              width: 100%;
              background-color: $primary;
            }
          }
        }
      }
    }
  }

  @include tablet {
    &:before,
    &:after {
      display: none;
    }
  }
}
