@import '../../_lib/style/param';

.elmo-uploaded-file {
  display: flex;
  height: 50px;
  margin-bottom: 10px;
  align-items: center;
  padding: 0 0 0 12px;
  color: $gray-800;
  font-size: 16px;
  border-radius: 3px;
  background: $gray-300;

  &.elmo-uploaded-file__rejected {
    border: 1px $danger-light solid;
  }

  & :last-child {
    margin-left: auto;
    cursor: pointer;
  }

  & button.elmo-btn--default {
    color: $gray-800;
  }
}
