$color: #0077c8;

.summary-tabs {

  &__wrap {
    display: flex;
    background: $color;
    border-radius: 0 3px 0 0;
    position: relative;
  }

  &__tab {
    cursor: pointer;
    background: #EDEDED;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
    padding: 4px 0 0 0;
    border-radius: 3px 3px 0 0;
    flex-grow: 1;
    text-align: center;
    &--active {
      background: #ffffff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.14);
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
