@import '../_lib/style/index';

.elmo-buttoncard {
  $this: &;
  $borderRadius: 3px;
  min-height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: $shadow-100;
  border-radius: $borderRadius;
  padding: 8px 8px 8px 24px;
  cursor: pointer;
  background-color: #fff;
  text-align: left;
  border: 1px solid transparent;
  outline: 1px solid transparent;
  transition: outline $transition-speed-normal,
    border-color $transition-speed-normal,
    background-color $transition-speed-normal, color $transition-speed-normal,
    box-shadow $transition-speed-normal;
  position: relative;
  margin-bottom: 24px;

  &:focus:not(.is-disabled) {
    outline: 1px solid $gray-400;
    border-color: $gray-400;
  }

  &:hover:not(.is-disabled) {
    background-color: $primary-bg;
    box-shadow: $shadow-200;
  }

  &:active:not(.is-disabled) {
    color: $primary-dark;
  }

  &.is-disabled {
    cursor: not-allowed;
    background-color: $gray-300;
  }

  &:after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    pointer-events: none;
    border: 2px solid transparent;
    transition: border-color $transition-speed-normal;
    border-radius: $borderRadius;
  }

  &:active:not(.is-disabled):after {
    border-color: $primary;
  }

  &__icon {
    margin-right: 16px;
    color: $gray-700;

    #{$this}.is-disabled & {
      color: $gray-600;
    }

    span,
    svg {
      display: block;
    }
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    min-width: 0;
  }

  &__title {
    color: $gray-800;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;

    #{$this}.is-disabled & {
      color: $gray-600;
    }

    #{$this}:hover:not(.is-disabled) & {
      color: $primary-light;
    }
  }

  &__description {
    color: $gray-700;
    font-size: 13.5px;

    #{$this}.is-disabled & {
      color: $gray-600;
    }
  }

  &__arrow {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-700;

    #{$this}.is-disabled & {
      color: $gray-600;
    }
  }
}
