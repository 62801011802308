@import 'src/assets/css/index';

.locations-dropdown-wrapper {
  min-width: 228px;
  background-color: $white;
  border-radius: 3px;
  @media (max-width: 940px) {
    min-width: 120px;
  };
  @include mobile-only {
    min-width: 44px;
    width: 44px;
    overflow: hidden;
    flex: none;
    button.elmo-btn {
      min-width: 44px;
      padding: 0 13px;
    }
  }

  #locations-dropdown {
    .elmo-btn__label {
      color: #212121;
      font-size: 14px;
    }
  }

  .locations-dropdown__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
