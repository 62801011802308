.upcoming-shift {
  display: flex;
  &__date {
    flex: none;
    width: 51px;
  }
  &__content {
    flex-grow: 1;
  }
  &__container {
    border: 1px solid #8FC3FF;
    border-radius: 3px;
    width: 32px;
  }
  &__day {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #19579F;
  }
  &__month {
    text-align: center;
    border-top: 1px solid #8FC3FF;
    color: #2972C4;
    font-size: 13.5px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}