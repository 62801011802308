@import '../_lib/style/_mixin.scss';

.elmo-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none; // Need to override the margin added by reboot
  color: $gray-rgba-700;
  justify-content: flex-end;
  margin: 24px 0;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  }

  .elmo-dropdown__button {
    background-color: #fff;
  }

  @include small-mobile-only {
    justify-content: center;
    flex-wrap: wrap;
  }
}
