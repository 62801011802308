@import 'src/assets/css/variables';

.templates-list {

  $this: &;

  &__block {
    margin-bottom: 1rem;
  }

  .override-checkbox {
    padding: 15px 8px;
  }

  &__empty-item {
    padding: 15px 5px 15px 8px;
    display: flex;
    align-items: center;
  }

  .current-item {
    padding: 11px 5px 9px 8px;
    &.selected {
      background: $info-bg;
    }
  }

  &__group {
    position: relative;
    .pin-r {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
    &--item {
      padding: 5px 0 0;
      display: flex;
      align-items: flex-start;
      position: relative;
      z-index: 0;
      cursor: pointer;
      svg {
        max-width: 16px;
      }
      &.selected {
        background: $info-bg;
        z-index: 1;
        border-bottom: 1px solid $gray-400;
        border-top: 1px solid $gray-400;
        .pin-r {
          display: none;
        }
      }
    }
    &--toggle {
      padding: 11px 40px 9px 8px;
      border-bottom: 1px solid $gray-400;
    }
  }
}