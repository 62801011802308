@import '../../_lib/style/param';

.elmo-filter {
  .elmo-filter__dropdown {
    padding: 3px;

    .elmo-dropdown__button {
      box-shadow: $shadow-100;
      border: none;
      color: $black;
      padding-left: 12px;
      padding-right: 12px;
      margin-left: -4px;

      &:focus {
        box-shadow: inset 0 0 0 2px $primary;
        background-color: $white;
      }
    }

    .elmo-dropdown__menu-item {
      display: flex;
      justify-content: space-between;

      .elmo-badge {
        margin: 0;
        background-color: transparent;
        border: 1px solid $gray-700;
        color: $gray-800;
      }
    }
  }
}
