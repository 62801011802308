@use "sass:math";
@import '../../vars';
@import '../../../../../../assets/css/mixin';

.day-navigation-badge {
  @include text-style-body-sm();

  position: absolute;
  padding: 0 10px;
  border-radius: 9999px;
  color: $white;
  transform: translateX(-50%);

  span:first-child {
    white-space: nowrap;
  }

  &--primary {
    background-color: $primary;
  }

  &--gray-500 {
    background-color: $gray-500;
  }

  &__divider {
    position: absolute;
    top: 100%;
    left: 50%;
    height: $navigationYSpacing;
    width: $currentDayDividerWidth;
    margin-left: -(math.div($currentDayDividerWidth, 2));

    &--primary {
      background-color: $primary;
    }

    &--gray-500 {
      background-color: $gray-500;
    }
  }
}
