.no-pointer-events {
  pointer-events: none;
  background-size: 0 !important;

  .droppable-wrapper {
    pointer-events: none !important;
    .hidden-card {
      display: none;
    }
  }

  .delayed-placeholder {
    display: none;
  }
}