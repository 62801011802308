@import './param';
// Variables used in the project (not copied from elmo-elements)

$cellMinHeight: 48px;
$cellMinHeightTall: 66.3px;
$cellMinWidth: 60px;
$cellBorderRadius: 3px;
$cellDividerBorder: 1px dashed $gray-200;
$cellDividerBorderDark: 1px dashed $gray-400;
$overlayOpacity: 0.35;
$cellWeekMinWidth: 130px;

// elmo-container:
// TODO replace with mui solution
$contentXSpacing: (
  default: 16px,
  mobile: 36px,
  tablet: 44px
);

// TODO replace with mui solution
$contentYSpacing: (
  default: 48px,
  mobile: 36px,
  tablet: 44px
);

// roster
$legendWidth: (
  default: 48px,
  mobile: 171px
);
$xGap: 4px;
$yGap: 4px;
$navigationYSpacing: 13px;
// roster week view
$weekGridMinWidth: 940px;

