.swap-responses {
  margin-top: 10px;
  &__badge {
    margin-right: 10px;
  }
}
.geolocation-info {
  display: flex;
  align-self: center;
  align-content: center;
  font-size: 12px;
  color: gray;
}

.my-rosters-list-item {
  margin-bottom: 10px;
  box-shadow: 0 4px 6px #d6d6d6;
  border-radius: 6px;
  .card-actions {
    justify-content: flex-end;
    border-top: 1px solid #d6d6d6;
    span:first-of-type {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
}