@import '../../../../../../../../assets/css/variables';
@import '../../../../../../../../assets/css/mixin';

.calendar-navigation {
  display: flex;
  margin-bottom: 35px;
  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 180px;
    @include mobile {
      max-width: 240px;
    }
  }
  &__date {
    width: 140px;
    text-align: center;
    * {
      display: inline-block;
      vertical-align: middle;
      @include mobile-only {
        font-size: 16px;
      }
    }
  }
  .today-btn {
    position: absolute;
    right: 0;
  }
}