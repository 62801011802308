@import '../_lib/style/param';

.elmo-dropdown {
  $this: &;
  font-size: 16px;
  line-height: 21px;
  text-transform: none;
  letter-spacing: normal;
  color: $gray-rgba-700;
  display: inline-block;

  &--block {
    width: 100%;
  }

  &--full-width {
    position: relative;
    display: block;

    .elmo-popover-base__content {
      width: 100%;
    }

    .elmo-dropdown__menu {
      width: 100%;
    }
  }

  .elmo-badge {
    .MuiSvgIcon-root {
      margin-right: 0px;
    }
  }

  button.elmo-dropdown__button {
    .elmo-btn__label {
      display: flex;

      > .MuiSvgIcon-root:first-child {
        margin-left: -4px;
        margin-right: 12px;
      }
    }

    &.elmo-dropdown__button--full-width {
      width: 100%;

      .elmo-btn__label {
        width: 100%;
        justify-content: space-between;
      }
    }

    &.elmo-dropdown__button--has-border {
      border: 1px solid #dbdbdb;
    }

    &--open {
      outline: none;
      border-color: $primary-light;
      color: $gray-rgba-700;
    }

    &--disabled {
      * {
        color: $gray-600;
      }
    }
  }

  .elmo-dropdown__button__icon {
    display: inline-flex;
    margin-right: -4px;
    margin-left: auto;
    padding-left: 12px;
  }
}
