@import "../../../variables";
@import "./src/assets/css/variables";

.elmo-roster-cell {
  position: relative;

  &.reserved-for-copy {
    background-color: $primary-border;
    .shift-card-view {
      visibility: hidden;
    }
  }

  &.roster-empty-cell {
    border-bottom: 1px dashed #efefef;
  }

  .elmo-loadingoverlay {

    .time-input-helper {
      height: 28px;
    }

    div {
      height: inherit;
      .elmo-input {
        height: 56px;
      }
    }

    .elmo-spinner {
      transform: translateY(46%);
    }

  }

}

.timesheet-empty-cell {
  border-bottom: 1px dashed #efefef;
}