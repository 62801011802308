@import 'src/assets/css/index';
@import 'src/assets/css/variables';

.day-summary-data {
  border-radius: 0 3px 0 3px;
  border: 1px solid #0077c8;
  max-width: 200px;
  background: $white;

  &__content {
    position: relative;
    padding: 16px 0 0 0;
    background: $white;
  }

  @media (max-width: $mobile-min) {
    background: $white;
    box-shadow: $shadow-100;
    border-radius: 0 3px 3px 3px;
    &__content {
      border-radius: 3px;
    }
  }
}
