@import '../../_lib/style/index';

.elmo-radio {
  &__label {
    font-family: $font-family;
    transition: color 0.2s;
    margin-bottom: 0;
    font-weight: 500;
    color: $gray-700;
  }
}
