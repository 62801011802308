// copied from oxygen-elements/breathe/styles

$font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$white: #fff !default;
$gray-100: #f6f6f6 !default;
$gray-200: #eceff1 !default; // breathe specific
$gray-300: #ededed !default;
$gray-400: #dbdbdb !default;
$gray-500: #adadad !default;
$gray-600: #8c8c8c !default;
$gray-700: #545454 !default;
$gray-800: #212121 !default;
$gray-900: #212121 !default;
$gray-rgba-200: rgba(0, 0, 0, 0.04);
$gray-rgba-300: rgba(0, 0, 0, 0.07);
$gray-rgba-400: rgba(0, 0, 0, 0.14);
$gray-rgba-600: rgba(0, 0, 0, 0.45);
$gray-rgba-700: rgba(0, 0, 0, 0.67);
$gray-rgba-800: rgba(0, 0, 0, 0.87);
$white-rgba-200: rgba(255, 255, 255, 0.09);
$white-rgba-300: rgba(255, 255, 255, 0.15);
$white-rgba-400: rgba(255, 255, 255, 0.25);
$white-rgba-600: rgba(255, 255, 255, 0.45);
$white-rgba-700: rgba(255, 255, 255, 0.67);
$white-rgba-800: rgba(255, 255, 255, 0.87);


$primary: #0077c8;
$primary-light: #e6f1fa;
$primary-dark: #225990;
$text-primary: #202124;
$text-secondary: transparent; // TODO
$text-disabled: #6B778C;
$secondary: transparent; // TODO main light dark
$error: transparent; // TODO main light dark
$warning: transparent; // TODO main light dark
$info: transparent; // TODO main light dark
$success: transparent; // TODO main light dark

$transition-speed-normal: 250ms;

$font-weight-base: 400;
$font-weight-bold: 500;

// z indexes
$zIndex-dropdown: 1001;

// breakpoints
$mobile-min: 600px;
$tablet-min: 840px;
$desktop-min: 1024px;
$desktop-lg-min: 1280px;

// Shadows
$shadow-100: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.14);
$shadow-200: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.14);
$shadow-inset-200: inset 0 3px 6px 0 rgba(0, 0, 0, 0.1),
inset 0 3px 6px 0 rgba(0, 0, 0, 0.14);
$shadow-300: 0 10px 20px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.14);
$shadow-400: 0 14px 28px 0 rgba(0, 0, 0, 0.16),
0 10px 10px 0 rgba(0, 0, 0, 0.14);

// mixins
@mixin transition-fix {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}

@mixin text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin mobile {
	@media (min-width: #{$mobile-min}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-min}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-min}) {
		@content;
	}
}

@mixin desktop-lg {
	@media (min-width: #{$desktop-lg-min}) {
		@content;
	}
}

// component specific variables
$menuXPadding: 9px;
