@import '../../_lib/style/param';

.elmo-checkbox {
  &__label {
    transition: color 0.2s;
    margin-bottom: 0;
    font-weight: 500;
    color: $gray-700;
    margin-left: 16px;
  }
}
