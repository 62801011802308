@import '../../assets/css/variables';
.shift-modal-header {
  h2 {
    width: 100%
  }
}

.shift-modal-content {
  min-height: 200px;

  .shift-modal-event-actions {
    margin-top: 20px;
  }

  .elmo-spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -35px;
    margin-left: -25px;
  }
}

.shift-modal {

  .add-break-button {
    margin-bottom: 15px;
    padding: 15px;

    & > span {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 5px;
    }
  }

  .padding-top-medium {
    padding-top: 15px;
  }

  .padding-top-small {
    padding-top: 10px;
  }

  .repeat-shift-btn {
    margin-left: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.14);
    border: 1px solid rgba(0, 0, 0, 0.07);

    &:first-child {
      margin-left: 0;
    }

    &.elmo-checkbutton--checked {
      background: #E5F1FF;
      border: 1px solid #19579F;
    }
    &.elmo-checkbutton--disabled {
      color: #19579f;
      opacity: 1;
    }
  }
  .no-focus-event {
    outline: none;
  }
  .user-btn {
    min-height: $cellMinHeight;
    max-height: $cellMinHeight;
  }
  .text-right {
    text-align: right;
  }
  .shift-modal-inner {
    max-width: 392px;

    .elmo-formitem__control__help {
      display: none;
    }

    &.spacing-top-extra-large {
      margin-top: 38px;
    }

    &.spacing-bottom-medium {
      margin-bottom: 20px;
    }

    &.spacing-bottom-large {
      margin-bottom: 28px;
    }

    &.spacing-bottom-extra-large {
      margin-bottom: 38px;
    }
  }
  .rostered-shift-modal__actions {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 0 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 25px;

    .mode-options {
      display: inline-block;
    }
  }

  .timesheet-modal__actions {
    margin: 25px 0;
  }

  .more-options {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
  }

  .pointer {
    cursor: pointer;
    color: rgba(0, 0, 0, .67);
  }

  &__hidden-options {
    .elmo-row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .day-time-group {
    .group-options-wrapper {
      .elmo-formitem {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 10px;
        }
      }

      .elmo-formitem__control__help {
        display: none;
      }
    }
  }

  .group-options-wrapper {
    &__placeholder {
      opacity: 1;
      max-height: 1000px;
    }

    &__options {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
    }
    &.expanded {
      .group-options-wrapper__placeholder {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
      }
      .group-options-wrapper__options {
        max-height: 1000px;
        overflow: inherit;
        opacity: 1;
      }
    }
  }

  .rows-container {
    .elmo-row {
      width: 100%;
    }
  }

  .elmo-datepicker {
    .elmo-popover-base__content {
      z-index: 10;
    }
  }

  .total-breaks-info {
    padding: 20px 0 25px;
  }
}

_:-ms-fullscreen, :root .shift-modal .elmo-modal__content {
  height: 100%;
}

.approve-timesheet-modal {
  .approve-actions {
    display: flex;
    justify-content: space-between;
  }
}

.break-word {
  word-break: break-word;
}

.timesheet-info-block {
  margin-bottom: 20px;
}

.timesheet-date-breaks-info {
  margin-bottom: 20px;
}

.timesheet-info-notes {
  margin-bottom: 20px;
}

.timesheet-info-map {
  margin-bottom: 20px;
}

.timesheet-info-block__avatar-box {
  display: flex;
  align-items: center;

  .username-text {
    display: inline-block;
    padding-left: 10px;
  }
}

#users-selector-modal,
#timesheet-modal-edit {
  .MuiBackdrop-root {
    opacity: 0 !important;
  }

  .MuiDialog-container {
    transition: none !important;
  }
}