@import '../../_lib/style/index';

.elmo-btn {
  opacity: 1;

  &__label {
    font-family: $font-family;
    text-align: center;
    position: relative;
    z-index: 1;
    @include text-style-body-rg;
  }

  &--is-uppercase {
    .elmo-btn__label {
      @include text-style-button;
    }
  }
}
