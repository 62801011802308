.week-rosters-grid__cell {
  transition: background-color .1s linear;
  -moz-transition: background-color .1s linear;
  -webkit-transition: background-color .1s linear;

  .draggable-wrapper {
    height: inherit;
  }

  &.drag-over-card {
    background: rgba(0, 116, 217, 0.22);
    .shift-card-view {
      opacity: 0;
      transition: opacity .3s ease-in;
    }
  }

  &.drop-denied {
    background: rgba(217, 0, 0, 0.22);
    box-shadow: 0 0 0 1px inset red;
    transition: none;
    .shift-card-view {
      box-shadow: 0 0 3px inset rgba(217, 0, 0, 0.53);
      transition: none;
    }
  }

  .drop-denied + .delayed-placeholder {
    pointer-events: none;
    * {
      pointer-events: none;
    }
    display: none !important;
  }

  .hidden-card {
    opacity: 0;
    transition: opacity .1s linear;
  }

}

.drag-over-card {
  .resizable {
    display: none;
  }
}