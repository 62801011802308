@import '../../_lib/style/param';
@import '../../_lib/style/mixin';

.elmo-filter {
  margin-left: -3px;
  margin-right: -3px;

  > .elmo-filter__buttons {
    flex-wrap: nowrap;
    overflow: hidden;
    padding: 3px;

    @include mobile-only {
      flex-grow: 1;
    }

    .elmo-radio {
      flex-shrink: 1;
      overflow: hidden;

      @include mobile-only {
        flex-grow: 1;
      }

      &__label {
        display: flex;
        overflow: hidden;
        width: 100%;
        justify-content: space-between;

        span {
          // max-width: 20em;

          @include tablet-max {
            // max-width: 10em;
          }

          @include mobile-only {
            // max-width: 5em;
          }

          @include small-mobile-only {
            max-width: 100%;
          }

          @include text-ellipsis;
        }

        .elmo-badge {
          margin: 0 0 0 10px;
          flex-shrink: 0;
        }
      }

      &:first-child,
      &:last-child {
        border-radius: 3px;
      }
    }

    @include small-mobile-only {
      flex-grow: 1;

      .elmo-radio {
        width: 100%;

        &__label {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
