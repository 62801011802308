@import 'src/assets/css/param';

.card-details-wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $mobile-min) {
    justify-content: space-between;
    margin-left: 0;
    margin-bottom: 0;
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
  }

  &__avatar {
    width: 112px;
    position: relative;
    margin: 0 auto;

    @media (min-width: $mobile-min) {
      margin: inherit;
    }
    .elmo-btn {
      position: absolute;
      right: -10px;
      top: 0;
    }
  }

  &__profile-data {
    text-align: left;

    @media (min-width: $mobile-min) {
      width: calc(100% - 132px);
    }

    .elmo-typography.heading-size-rg {
      text-align: center;
      padding-bottom: 12px;

      @media (min-width: $mobile-min) {
        height: 112px;
        line-height: 112px;
        text-align: left;
        padding-bottom: 0;
      }
    }

    svg {
      width: 18px;
    }

    .MuiSvgIcon-root {
      margin-right: 12px;
    }

    .elmo-dl {
      margin-bottom: 0;

      @media (min-width: $mobile-min) {
        margin-top: 15px;
      }

      &__dt {
        height: 24px;
        width: 32px;
      }

      &__dd {
        padding-top: 0;
        margin-bottom: 10px;
        text-overflow: ellipsis;

        a {
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
      }
    }
  }
}
