@import "css/roster-cell";
@import "css/nav-by-keyboard";
@import "css/bulk-delete-mode";
@import "css/no-pointer-events";
@import "css/disabled-blocks";
@import "css/dragging";
@import "css/drag-and-drop-validation";
@import "css/copy-move-placeholder";
@import "css/resizable";
@import "css/drag-in-progress";
@import "css/covid-mode";

.elmo-switcher.expanded {
  z-index: 1;
}

.shift-card-view {
  pointer-events: auto;
}

.week-rosters-grid__group--roster + .week-rosters-grid__group--roster,
.week-rosters-grid__group--timesheet + .week-rosters-grid__group--timesheet {
  padding-top: 4px;
}

.week-rosters-grid__group--roster + .week-rosters-grid__group--timesheet {
  padding-bottom: 4px;
}