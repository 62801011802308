.roster-notes-field {
  min-height: 48px;
  height: 48px;
  overflow: hidden;
}

.notes-placeholder {
  cursor: text;
  padding: 3px;
  word-break: break-word;
  &:hover {
    background: rgba(210, 210, 210, 0.3);
  }
}
