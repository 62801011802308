@import '../_lib/style/param';

.elmo-checkbutton {
  width: 48px;
  height: 48px;
  border: 1px $gray-rgba-400 solid;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 3px;
  cursor: pointer;
  color: $gray-rgba-800;
  margin: 0;

  & + & {
    margin-left: 8px;
  }

  &--checked {
    border: 2px $primary solid;
    color: $primary;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: $gray-rgba-600;
    background-color: $gray-rgba-200;
  }

  span {
    align-self: center;
  }
}
