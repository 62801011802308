@import '../../elmo-elements/_lib/style/index';

.simple-action-card {
  $this: &;
  $xSpacing: 20px;
  $contentLeftSpacing: 64px;
  $lineHeight: 48px;
  $headerYPadding: 5px;

  @mixin optionsAndExpandButtonsSpacing() {
    margin-right: -16px;
    margin-left: 4px;

    @include tablet {
      margin-left: 8px;
    }
  }

  background-color: $white;
  box-shadow: $shadow-100;
  border-radius: 5px;
  margin-bottom: 8px;
  position: relative;

  &__header {
    display: flex;
    padding: $headerYPadding $xSpacing;
  }

  &__header-content {
    @include text-style-body-sm();

    flex: 1 0 0%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: $lineHeight;
    line-height: $lineHeight;
    position: relative;
  }

  &__content-wrapper {
    display: flex;
    padding-right: $xSpacing;
  }

  &__content-inner-wrapper {
    width: 100%;
  }

  &__content {
    padding: 0 0 20px $xSpacing;

    &:not(:first-child) {
      padding-top: 0;
    }
  }

  &__footer {
    border-top: 1px solid $gray-400;
    padding: 8px $xSpacing;
    display: flex;
    justify-content: space-between;
  }

  &__footer-label {
    display: flex;
    align-self: center;
    align-content: center;
    font-size: 12px;
    color: gray;
  }
}
