@import '../../../../../../../../assets/css/variables';
@import '../../../../../../../../assets/css/mixin';

.calendar-card {

  min-height: 100%;
  height: inherit;
  border-radius: 3px;
  position: relative;

  .popover-fix {
    width: 100%;
    height: inherit;
    .elmo-popover__base-content {
      z-index: 3;
    }
    .pin-r {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }

  &__content-wrapper {
    height: inherit;
  }

  &--current {
    background: #fff;
    color: $gray-700;
    padding: 0;
  }

  &--other {
    background: $gray-300;
    color: $gray-400;
    padding: 0 5px;
    @include mobile {
      padding: 5px 10px;
    }
  }

  .is-today {
    background: $primary-light;
    color: #fff;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    line-height: 24px;
    @include mobile-only {
      font-size: 12px;
      width: 19px;
      height: 19px;
      line-height: 19px;
    }
  }

  .day {
    display: inline-block;
    letter-spacing: -1px;
    text-align: center;
    @include mobile-only {
      font-size: 12px;
    }
  }

  .month {
    display: none;
    @include mobile {
      display: inline-block;
    }
  }

  &--content {
    padding-left: 5px;
    padding-right: 5px;
    height: inherit;
    @include mobile {
      padding-left: 30px;
      padding-top: 5px;
      padding-right: 10px;
    }
    .original-status {
      display: none;
      @include desktop {
        display: block;
        position: absolute;
        top: 78%;
        width: calc(100% - 30px);
        text-align: center;
        opacity: .7;
      }
    }
    .card-time {
      position: absolute;
      top: 48%;
      left: 40px;
      display: none;
      height: 17px;
      text-align: left;
      transform: translateY(-50%);
      letter-spacing: 0.5px;
      width: calc(100% - 40px);
      p:first-child {
        margin-bottom: -4px;
      }
      p {
        max-width: 98%;
      }
      @include desktop {
        display: block;
      }
      &.second-description {
        top: 78%;
      }
    }
  }

  &--status {
    width: 22px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 3px 0 0 3px;
    text-align: center;
    color: $gray-800;

    @include mobile {
      width: 30px;
    }

    &.work {
      display: none;
      @include mobile {
        display: block;
      }
    }

    &.overlapped {
      @include mobile {
        background: $primary-bg;
      }
      .status-icon {
        color: $primary;
      }
    }

    &.approved {
      @include mobile {
        background: $gray-400;
      }
      .status-icon {
        color: $gray-800;
      }
    }

    &.leave-approved {
      @include mobile {
        background: $success-bg;
      }
      .status-icon {
        color: $success-border;
      }
    }

    &.pending,
    &.leave-pending {
      @include mobile {
        background: $warning-bg;
      }
      .status-icon {
        color: $warning-dark;
      }
    }

    &.declined,
    &.leave-declined {
      @include mobile {
        background: $danger-bg;
      }
      .status-icon {
        color: $danger-light;
      }
    }
    &.unavailability {
      @include mobile {
        background: $gray-400;
      }
      .status-icon {
        color: $gray-800;
      }
    }

    .status-icon {
      position: absolute;
      top: 49%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      height: 12px;
      svg {
        height: 16px;
      }

      @include mobile {
        height: 26px;
        svg {
          height: 26px;
        }
      }

      &.second-icon {
        top: 78%;
        color: $gray-700;
        display: none;
        @include desktop {
          display: block;
        }
      }

    }

  }

}