.notifications-list {
  .info-placeholder {
    min-height: 28px;
    display: block;
    &.small {
      min-height: 19px;
    }
  }
  &__location {
    min-height: 20px;
  }
  &__title {
    max-width: 75%;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 15px 0;
    .notifications-list__title {
      font-size: 16px;
      line-height: inherit;
    }
    &:after {
      display: block;
      right: -24px;
      height: 1px;
      background: #cccccc;
      width: 105%;
      position: absolute;
      bottom: 0;
      content: '';
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &.is-read {
      * {
        color: #ccc;
      }
      img {
        opacity: 0.4;
      }
    }
  }
  &__content {
    width: calc(100% - 100px);
    position: relative;
    cursor: pointer;
    .time-info {
      position: absolute;
      top: 3px;
      right: -57px;
      font-size: 12px;
      max-width: 87px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &-message {
      margin-top: 10px;
    }
  }
  &__avatar {
    margin-right: 0;
  }
  &__actions {
    right: 0;
    position: relative;
    width: 48px;
    .delete-btn {
      position: absolute;
      top: 50px;
      right: -20px;
    }
  }
}