@import '../_lib/style/index';

$ComponentClassName: '.elmo-card';

#{$ComponentClassName} {
  box-shadow: $shadow-100;
  border-radius: 6px;
  padding: 24px;
  background-color: #fff;
  margin-bottom: 24px;
  outline: 0;
  position: relative;

  &__header-image {
    position: relative;
    overflow: hidden;
    margin-top: -24px;
    margin-left: -24px;
    margin-right: -24px;
    border-radius: 3px 3px 0 0;
    margin-bottom: 14px;
    min-width: calc(100% + 48px);
    height: 116px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    #{$ComponentClassName}__header-badges {
      position: absolute;
      bottom: 4px;
      right: 4px;

      .elmo-badge {
        margin-left: 4px;
      }
    }

    & + #{$ComponentClassName}__header {
      margin-bottom: 16px;
    }
  }

  &__header {
    margin-bottom: 48px;
    .elmo-card__heading {
      overflow-wrap: break-word;
    }
  }

  &__option {
    float: right;
    margin-top: -8px;
    margin-right: -8px;
  }

  &--is-full-height {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0; // to remove unexpected height

    #{$ComponentClassName}__header-image
      + #{$ComponentClassName}__header
      + #{$ComponentClassName}__content {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      #{$ComponentClassName}__content-inner {
        min-width: 100%;

        > .elmo-divider {
          margin-left: -24px;
          margin-right: -24px;
        }
      }
    }
  }

  &--is-clickable {
    cursor: pointer;
    position: relative;
    transition: $transition-speed-normal;
    &:before {
      transition: $transition-speed-normal;

      content: '';
      display: block;
      position: absolute;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      left: -2px;
      top: -2px;
      border: 2px solid $primary-light;
      border-radius: 5px;
      opacity: 0;
    }
    &:hover {
      box-shadow: $shadow-300;
    }
    &:focus {
      outline: none;
      &:before {
        opacity: 1;
      }
    }
    &:active {
      background-color: $gray-rgba-300;
    }
  }
}

.elmo-row {
  > #{$ComponentClassName} {
    width: 100%;
    max-width: 368px;
    margin-left: 12px;
    margin-right: 12px;
  }
}
