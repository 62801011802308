@import 'src/assets/css/index';
.header-navigation {
  background-color: $white;
  border-radius: 3px;
  .elmo-btn {
    vertical-align: top;
  }

  &__container {
    overflow: hidden;
    border: 1px solid #C4C7D0;
    border-radius: 3px;
    height: 44px;
  }

  &__date-range {
    display: inline-flex;
    font-size: 14px;
    color: #030303;
    padding: 10px;
    vertical-align: text-bottom;
    height: 44px;
    cursor: pointer;
    transition: background-color 0.15s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  button.elmo-btn {
    border-top: none;
    border-bottom: none;
    border-radius: 0;
    max-height: 42px;
    max-width: 42px;
    color: #030303;
    .elmo-btn__pressed {
      border-radius: 0;
    }
    .elmo-btn__focused {
      border-radius: 0;
    }
    .elmo-btn__hover {
      border-radius: 0;
    }
  }

  button.elmo-btn.header-navigation__left {
    border-right: 1px solid #C4C7D0;
  }

  button.elmo-btn.header-navigation__right {
    border-left: 1px solid #C4C7D0;
  }

  &__range-icon {
    display: none;
  }

  @include mobile-only {
    button.elmo-btn.header-navigation__left,
    button.elmo-btn.header-navigation__right {
      display: none;
    }

    &__range-content {
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
    }

    &__range-icon {
      display: inline-block;
      transform: rotate(90deg);
      margin-left: 9px;
    }
  }

  @media (max-width: 440px) {
    &__range-content {
      width: 82px;
    }
  }
}

h1.header-navigation__range-content {
  font-size: 14px;
  text-align: center;
  margin-right: 0;
  letter-spacing: 0;
  @include desktop {
    width: 160px;
  }
}
