@import '../../_lib/style/param';

.elmo-datatable {
  &__body {
    tr {
      &:nth-child(odd) {
        background: transparent;
      }
      &:nth-child(even) {
        background: $gray-300;
      }
    }
  }
}
