@import '../../_lib/style/param';

.elmo-pagination {
  &__item {
    background: none;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
    }
    > a {
      text-decoration: none;
    }

    &.disabled {
      color: $gray-600;
    }
  }
}
