@import '../_lib/style/param';

.elmo-filter {
  display: flex;
  font-family: $font-family;
  // margin -3px below to align the component vertically
  margin-top: -3px;
  margin-bottom: -3px;

  &-wrapper {
    margin-bottom: 24px;
  }

  .elmo-filter__dropdown .elmo-dropdown__button,
  .elmo-filter__modal-button {
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    background-color: #fff;
    border: none;
    height: 48px;
    border-radius: 3px;
    display: inline-flex;
  }
}
