@import '../_lib/style/index';

.elmo-bulk-actions {
  width: 100%;
  transition: all 0.25s;
  overflow: hidden;
  transform: translateY(-100%);

  &__wrapper {
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    top: 0;
    padding: 0px;

    @include mobile {
      padding: 8px 32px;
    }

    @include tablet {
      padding: 16px 40px;
    }

    @include desktop {
      padding: 24px 40px;
    }
  }

  // Modifier
  &--open {
    transform: translateY(0);
  }
}
