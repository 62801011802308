@import 'src/elmo-elements/_lib/style/index';
/* Header Back*/

.elmo-header__back-btn-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: -12px;

  button.elmo-btn {
    border: none;

    .elmo-btn__label {
      display: none;
    }
  }

  @include mobile {
    margin-right: 8px; //margin-right: -12px;
  }

  @include tablet {
    // margin-left: -12px;
  }
}
