.event-address {
  &__results {
    list-style: none;
    padding: 0;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.14);
    border: 1px solid rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;

    li {
      padding: 10px 10px;
      &:hover, &.selected {
        background: #e6f1ff;
        color: #19579f;
      }
    }
  }
}