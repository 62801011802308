@import '../../_lib/style/index';

.elmo-bulk-actions__buttons {
  .elmo-btn {
    margin-right: 8px;

    .elmo-btn__label {
      display: none;

      @include tablet {
        display: inline-flex;
      }
    }
  }
}
