.elmo-datatable {
  &__sort {
    display: inline-block;
    margin-left: 4px;

    .MuiSvgIcon-root {
      font-size: 20px;
    }

    &--hidden {
      visibility: hidden;
    }
  }
}
