.drag-over-split-placeholder {
  position: relative;
  background: rgba(0, 116, 217, 0.22);
}

.drag-over-split-placeholder + .delayed-placeholder {
  height: 100%;
  z-index: 1;
  opacity: 1;
  transition: height 0s ease-in .01s, opacity 0s ease-in .01s;
}

.drag-over-split-placeholder.drop-denied + .delayed-placeholder {
  height: 0;
  opacity: 0;
  z-index: 0;
  transition: none;
  display: none !important;
}

.delayed-placeholder {
  position: absolute;
  display: flex;
  width: 100%;
  top: 0;
  height: 0;
  z-index: 0;
  opacity: 0;
  overflow: hidden;
  transition: none;
  div {
    align-content: space-between;
    width: 50%;
    text-align: center;
    border: 0.5px solid #0077c8;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E0E5EF;
    color: $gray-rgba-600;
  }
  .move-cell {
    border-radius: 0 3px 3px 0;
    border-left: none;
    &:before {
      content: 'Move';
    }
  }
  .copy-cell {
    border-radius: 3px 0 0 3px;
    &:before {
      content: 'Copy';
    }
  }
  .drag-over-card {
    background: #fff;
    &:before {
      color: #000;
    }
  }
}