@import '../../_lib/style/index';
.elmo-nativetable-tr {
  vertical-align: top;
  &__valign-middle {
    vertical-align: middle;
  }
  &__valign-bottom {
    vertical-align: bottom;
  }
}
