@import 'src/assets/css/mixin';
@import 'src/assets/css/variables';

.roster-nav-wrapper {
  margin: $navigationYSpacing 0;
  display: flex;
  padding-right: map_get($contentXSpacing, 'default');
  padding-left: map_get($contentXSpacing, 'default');

  @include mobile {
    padding-right: map_get($contentXSpacing, 'mobile');
    padding-left: map_get($contentXSpacing, 'mobile');
  }

  @include tablet {
    padding-right: map_get($contentXSpacing, 'tablet');
    padding-left: map_get($contentXSpacing, 'tablet');
  }
}
