@import '../../style/_index.scss';

.elmo-toggle-btn {
  box-sizing: initial;
  display: inline-block;
  outline: 0;
  width: 48px;
  height: 24px;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 24px;
  padding: 0px;
  transition: all 0.4s ease;
  background-color: #cecece;
  color: #fff;
  box-sizing: border-box;
}

.elmo-toggle-btn::after {
  left: 0;
  position: relative;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-left: 2px;
  margin-top: 2px;
  background: $gray-100;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-shadow: $shadow-100;
}

.elmo-toggle-btn.elmo-toggle-btn--on::after {
  left: 50%;
}

.elmo-toggle-btn.elmo-toggle-btn--on {
  background: $primary;
}

.elmo-toggle-btn.elmo-toggle-btn--on:active {
  box-shadow: none;
}

.elmo-toggle-btn.elmo-toggle-btn--on:active::after {
  width: 44px;
  left: 0;
}

.elmo-toggle-btn:active::after {
  width: 44px;
}

.elmo-toggle-btn__toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
