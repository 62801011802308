#permission-checkbox {
  display: inline;
}

.user-groups-wrap {

  thead th {
    box-shadow: 0 1px 0 0 #bdbdbd;
  }

  td {
    .permission-checkbox {
      justify-content: center;
    }
  }

  .user-groups:nth-child(n+2) {
    th:nth-child(n+2) {
      color: transparent;
    }
  }

  .elmo-datatable--is-first-column-sticky tr .elmo-datatable__th:first-child,
  .elmo-datatable--is-header-sticky .elmo-datatable__head .elmo-datatable__th {
    background: #edf0f5;
  }

  .elmo-datatable--is-first-column-sticky tr .elmo-datatable__td:first-child {
    background: #eef0f6;
    min-width: 200px;
  }

  .elmo-datatable--is-first-column-sticky tr:nth-child(even) .elmo-datatable__td:first-child {
    background: #e3e7eb;
  }

  .elmo-datatable__body tr:nth-child(2n) {
    background: rgba(0, 0, 0, .04);
  }
}