@import '../../../../assets/css/index';
@import '../../../../assets/css/variables';

.elmo-roster-user-legend {
  $this: &;

  background-color: $white;
  min-height: $cellMinHeight;
  min-width: 40px;
  height: 100%;
  border-radius: 6px;
  box-shadow: $shadow-100;

  &.is-disabled {
    opacity: .5;
    position: relative;
    pointer-events: none;
    &:before {
      display: block;
      opacity: .35;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      content: '';
      border-radius: 6px 0 0 6px;
    }
  }

  @media (min-width: $mobile-min) {
    min-width: $cellMinWidth;
  }

  &__mobile-card-placeholder {
    display: flex;
    height: inherit;
    &:after {
      display: block;
      width: 0;
      height: 0;
      content: '';
      border-top: 6px solid transparent;
      border-bottom: 0 solid transparent;
      border-right: 6px solid #000;
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
    @media (min-width: $mobile-min) {
      display: none;
      &:after {
        display: none;
      }
    }
  }

  &__desktop-card-placeholder {
    display: none;
    @media (max-width: $mobile-min) {
      &.is-opened {
        display: flex;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #fff;
        width: 196px;
        box-shadow: 1px 1px 1px $gray-400;
        &:after {
          display: block;
          width: 0;
          height: 0;
          content: '';
          border-bottom: 6px solid transparent;
          border-top: 0 solid transparent;
          border-left: 6px solid #000;
          position: absolute;
          right: 5px;
          bottom: 5px;
        }
      }
    }
    @media (min-width: $mobile-min) {
      display: flex;
    }
  }

  &__icon-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 6px;
    max-width: 36px;
    width: 36px;
    position: relative;
    top: 6px;
  }

  &__content-wrapper {
    min-width: 0;
    padding-right: 8px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  &__label {
    @include text-style-heading-xxs;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 8px;

    #{$this}--unassigned-row &,
    #{$this}--single-row &,
    #{$this}--always-single-row & {
      margin-bottom: 0;
      margin-top: 3px;
    }

    #{$this}--no-label & {
      margin-top: 16px;
    }
  }

  &__duration {
    @include text-style-body-xs;

    display: flex;
    align-items: center;
    color: $gray-700;
    margin-top: -4px;

    &:last-child {
      margin-bottom: 6px;
    }

    #{$this}--unassigned-row &,
    #{$this}--single-row &,
    #{$this}--always-single-row & {
      margin-top: 0;
    }
  }

  &__duration-quantity {
    flex-shrink: 0;
    margin: 4px 4px 0;
    width: 21px;
  }
  &__duration-time {
    flex-shrink: 0;
    margin: 4px 4px 0;
  }

  .grey-avatar {
    .elmo-avatar__label-icon {
      background: rgba(0, 0, 0, 0.07);
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &.ui-roster-legend {
    &.elmo-roster-user-legend--no-label {
      .elmo-roster-user-legend__icon-wrapper {
        top: 5px;
      }
      .elmo-roster-user-legend__label {
        margin-top: 16px;
      }
    }

    .elmo-roster-user-legend__icon-wrapper {
      top: 0;
    }

    .elmo-roster-user-legend__label {
      font-weight: 600;
      line-height: 16px;
      color: rgba(0,0,0,0.87);
      @media (min-width: $mobile-min) {
        margin-top: 9px;
        margin-bottom: 0;
        padding: 0;
      }
    }

    .elmo-roster-user-legend__duration {
      @include text-legend-duration-xs;

      display: flex;
      align-items: center;
      color: $gray-700;
      margin-bottom: 2px;
      margin-top: 0;
    }

    .elmo-avatar.grey-avatar .elmo-avatar__label-icon {
      background: rgba(206,232,251,1);
    }

    #{$this}__label {
      padding-bottom: 2px;
      span {
        font-weight: 500;
        font-size: 12px;
      }
    }

    #{$this}__icon-wrapper {
      svg {
        path:last-child {
          fill: $danger;
        }
      }
    }
  }
}
