.elmo-btn__badge {
  position: absolute;
  right: -4px;
  top: -4px;

  .elmo-badge {
    display: block;
    padding-left: 0;
    padding-right: 0;
    line-height: 20px;
    height: 20px;
    min-width: 20px;

    &__label {
      font-weight: normal;
    }
  }
}
