.toast-notification {
  position: sticky;
  left: 50%;
  bottom: 0;
  transition: all .25s ease-in;
  z-index: 3000;
  .elmo-notification {
    bottom: inherit;
    height: auto;
  }
}