.day-info {
  padding: 0 12px;
  &__value.elmo-typography {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    height: 22px;
    white-space: nowrap;
    span {
      font-weight: normal;
    }
  }

  &__highlighted {
    color: #B1101E;
  }
}
