.unavailability-card-item {
  border-radius: 6px;
  .card-header-options {
    .badge {
      margin-right: 10px;
    }
  }
  .avatar-wrapper {
    display: flex;
    align-items: center;
    font-size: 13px;
    letter-spacing: 0.5px;
  }
  .card-subheader {
    padding-top: 0;
    .MuiCardHeader-avatar {
      width: 36px;
    }
  }
  .card-actions {
    border-top: 1px solid #ccc;
    justify-content: flex-end;
    span:first-of-type {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
  .card-content {
    padding-left: 68px;
  }
  .card-header {
    @media (max-width: 520px) {
      display: flex;
      justify-content: space-between;

      .avatar-wrapper {
        display: block;
        span:last-child {
          display: block;
          padding-left: 52px;
        }
      }

    }
  }
}