@import '../../_lib/style/param';

.elmo-checkbox {
  &__element {
    position: relative;
    width: 24px;
    height: 24px;
    line-height: 1;
    display: inline-block;
    flex-shrink: 0;
    input[type='checkbox'] {
      position: absolute;
      left: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      &:focus + .elmo-checkbox__icon {
        border: 1px solid $primary-border;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: $gray-400;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-collapse: separate;
    &:before {
      transition: all 0.2s;
      display: block;
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-color: #fff;
      border-radius: 2px;
    }
    &:after {
      transition: all 0.2s;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      display: block;
      margin-top: -1px;
      content: '';
    }
  }
}
