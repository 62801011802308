@import '../../../../assets/css/index';

.elmo-roster-unavailability-cell {
  border-radius: 3px;
  &:nth-child(n+1) {
    border-top: 1px dashed $gray-rgba-400;
    &:first-child {
      border: none;
    }
  }

  & > div {
    width: 100%;
  }

  .shift-card {
    &:hover {
      background: transparent;
      cursor: inherit;
    }
  }

  &__card {
    height: 56px;
    min-height: 56px;
    margin-bottom: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
  svg {
    margin-right: 7px;
  }
  .timeoff-time {
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
    font-weight: 600;
  }
}
