@import '../../_lib/style/index';
.elmo-listtable {
  &__col {
    flex-direction: column;
  }
  &__data-label {
    color: $gray-700;
    font-size: 13.5px;
    letter-spacing: 0.6px;
    line-height: 16px;
    margin-top: 20px;
  }
  &__data {
    > .elmo-avatar {
      margin-top: -6px;
      margin-bottom: -6px;
    }
  }
}
