@import "../../../variables";
@import "./src/assets/css/variables";

.bulk-mode {
  .week-rosters-grid.spec-cell-height .elmo-roster-cell {
    &:hover,
    &:focus {
      background-size: 0;
    }
  }
  .shift-card-view,
  .elmo-roster-legend,
  .elmo-roster-user-legend,
  .elmo-roster-site-legend {
    opacity: 0.5;
    transition: background-color .25s linear;
    &:hover {
      opacity: 1;
      background: #fff;
      cursor: pointer;
    }
    .delete-shift-icon {
      display: none;
    }
  }

  .shift-card-view--selected {
    background: $selectedBg;
    opacity: 1;

    &.shift-card-view--top {
      .shift-card-view__content {
        border-radius: 3px 3px 0 0;
      }
    }

    &.shift-card-view--bottom {
      .shift-card__content {
        border-radius: 0 0 3px 3px;
      }
    }

    .shift-card__content {
      box-shadow: 0 0 1px 1px $selectedBorder inset;
    }

    &:hover {
      opacity: 1;
      background: $selectedBg;
    }
  }

  .is-bulk-selected {
    .elmo-roster-legend,
    .elmo-roster-user-legend,
    .elmo-roster-site-legend {
      background: $selectedBg;
      box-shadow: 0 0 1px 1px $selectedBorder;
      opacity: 1;
    }
    .week-rosters-grid {
      box-shadow: 0 0 1px 1px $selectedBorder inset;
      border-radius: 3px;
    }
    .shift-card-view {
      &__content {
        box-shadow: none;
      }
      &.shift-card--bottom {
        .shift-card__content {
          box-shadow: none;
        }
      }
    }
  }

  .shift-card-view--highlighted {
    background: $highlightedBg;
    transition: background-color .15s linear .15s, box-shadow .15s linear .15s;
    box-shadow: 0 0 5px $highlightedBg;
    opacity: 1;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;

    &.shift-card-view--top {
      .shift-card-view__content {
        border-radius: 3px 3px 0 0;
      }
    }

    &.shift-card-view--bottom {
      .shift-card-view__content {
        border-radius: 0 0 3px 3px;
      }
    }

    &:hover {
      opacity: 1;
      background: $highlightedBg;
    }
  }
  .elmo-roster-unavailability-cell {
    .shift-card-view--without-shadow {
      opacity: 0.5;
      background: transparent;
    }
    .shift-card-view {
      &:hover {
        background: transparent;
        cursor: inherit;
      }
    }
  }

  .roster-map {
    .roster-focusable {
      &:focus {
        background: none;
        .shift-card-view {
          box-shadow: none;
        }
        .timesheet-card {
          box-shadow: none;
        }
      }
    }
  }
}