@import '../../../../../../assets/css/variables';

.week-rosters-grid {
  border-radius: 0 6px 6px 0;
  padding: 2px 0 2px 0;
  margin: -2px 0 -2px 0;
  width: 100%;

  .is-disabled {
    background: rgba(255, 255, 255, 0.25);
  }

  &__blocks {
    padding-bottom: 4px;
    min-width: $weekGridMinWidth;
    width: 100%;
    border-radius: 0 4px 4px 0;
    &.is-disabled {
      opacity: .5;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &.unassigned {
      min-height: 100%;
      .elmo-roster-cell--full {
        min-height: 100%;
      }
      .elmo-roster-cell {
        padding-bottom: 1px;
        min-height: 50px;
      }
    }
    &.events {
      min-height: 100%;
      .elmo-roster-cell--full {
        min-height: 100%;
      }
      .elmo-roster-cell {
        min-height: 65px;
      }
      .week-rosters-grid__group-day-block {
        margin-bottom: 2px;
      }
    }
    &-row {
      display: flex;
    }
  }

  &__group-wrapper {
    position: relative;
  }

  &__group {
    display: flex;
    margin-right: -2px;
    margin-left: -2px;

    &-day-block {
      margin: 0 2px;
      width: calc((100% / 7) - 4px);
      &:last-child {
        margin-right: 4px;
      }
      &:first-child {
        margin-left: 4px;
      }
      &.total-info {
        text-align: center;
        background: $gray-rgba-200;
        padding: 4px 0;
        span {
          font-weight: 500;
        }
      }
      &.unavailability-block {
        background: #e0e5ef;
        border-radius: 3px;
        .is-disabled {
          position: relative;
          opacity: .5;
          &:after {
            display: block;
            background: rgba(255, 255, 255, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            content: '';
            border-radius: 6px 0 0 6px;
          }
        }
        .elmo-roster-cell {
          background-color: transparent;
          cursor: default;
        }
      }
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }

    &--absolute {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__cell {
    height: inherit;
    min-height: 48px;
  }

  &.spec-cell-height {
    &.week-rosters-grid__cell {
      min-height: 56px;
    }
    .elmo-roster-cell {
      min-height: 56px;
    }
  }

  .shift-card-wrapper {
    height: inherit;
  }
}
