@import '../_lib/style/mixin';

.elmo-checkbox {
  display: inline-flex;
  position: relative;

  & + & {
    margin-left: 32px;
  }

  &.elmo-checkbox--checked {
    .elmo-checkbox__icon {
      background-color: $primary;

      &:before {
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 1px;
      }

      &:after {
        width: 11px;
        height: 5px;
        padding-top: 1px;
        border: 2px solid $primary;
        border-top-width: 0;
        border-right-width: 0;
      }
    }

    &.elmo-checkbox--disabled {
      .elmo-checkbox__icon {
        &:after {
          border-color: $gray-400;
        }
      }
    }
  }

  &.elmo-checkbox--disabled {
    .elmo-checkbox__icon {
      background-color: $gray-400;

      &:before {
        background-color: $gray-300;
      }
    }

    .elmo-checkbox__element {
      input[type='checkbox'] {
        cursor: not-allowed;
      }
    }

    .elmo-checkbox__label {
      color: $gray-600;
    }
  }

  &.elmo-checkbox--indeterminate {
    .elmo-checkbox__icon {
      background-color: $primary;

      &:before {
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 1px;
      }

      &:after {
        width: 11px;
        height: 0px;
        margin-top: 0px;
        border: 2px solid $primary;
        border-top-width: 0;
        border-right-width: 0;
        border-left-width: 0;
        transform: translateX(-50%) translateY(-50%) rotate(0);
      }
    }
  }

  @include mobile-only {
    display: flex;

    & + & {
      margin-left: 0;
      margin-top: 24px;
    }
  }
}
