@import '../../_lib/style/_param.scss';

.elmo-pagination {
  .elmo-dropdown {
    &__button {
      box-shadow: $shadow-100;
    }

    &__button-text {
      color: $gray-rgba-700;

      svg {
        margin-left: 10px;
      }
    }
  }
}
