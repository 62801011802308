.role-modal-view-with-edit-roles-table {
  &__header-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__change-main-btn {
    cursor: pointer;
  }
}
