@import "../../../../variables";
@import '../../vars';

.timeline {
  &__row {
    position: relative;

    &--with-spacing:not(:last-child) {
      margin-bottom: $yGap;
    }

    &--droppable {
      background-color: $selectedBg;
    }

    &--not-droppable {
      background-color: rgba(210, 144, 144, 0.5);
    }
  }

  &__background {
    display: flex;
    justify-content: space-between;
  }

  // BP2-5020: cardsImprovements = true
  &__improved {
    .shift-card:not(.shift-card--event) {
      .shift-card__descr {
        max-width: 130px;
      }
    }
    .elmo-roster-cell {
      min-height: 56px;
    }
    .elmo-roster-cell--is-tall {
      min-height: 70px;
    }
  }
  // ---------------------

}

.is-collapsable {
  &:not(.is-collapsed) {
    .timeline__improved {
      // BP2-5018
      .card-height-lg {
        max-height: inherit;
        .shift-card__title h3 {
          line-height: 20px;
          font-weight: 400;
        }
        .shift-card__content {
          padding-bottom: 2px;
          padding-top: 3px;
          svg {
            font-size: 14px;
          }
          .shift-card__row:last-child p {
            line-height: 20px;
          }
        }
      }
    }
  }
}
