@import '../../../../../assets/css/variables';
@import '../../../variables';

.shift-card-view {
  $this: &;
  &--top {
    border-radius: $cellBorderRadius $cellBorderRadius 0 0;
  }
  &--bottom {
    border-radius: 0 0 $cellBorderRadius $cellBorderRadius;
  }

  &--colored {
    background-color: #fff;
    transition: background-color 0.15s ease-in-out;
  }

  &--without-shadow {
    box-shadow: none;
  }

  &--clickable {
    cursor: pointer;
  }

  &.is-disabled {
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.5);

    .shift-card-view__location {
      opacity: .7;
    }
  }
}

@for $i from 0 through 19 {
  .role-color-#{$i}-primary {
    .shift-card-view__location {
      background-color: map-get($primaryColors, $i);
    }
    &.shift-card-view--filled-bg {
      .shift-card-view__content {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card-view__content {
          background-color: rgba(250, 250, 250, 0.8);
        }
      }
    }
    &.timesheet-card {
      .shift-card-view__content {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card-view__content {
          background-color: rgba(map-get($primaryColors, $i), 0.4);
        }
      }
    }
    &.has-connected-shift {
      .shift-card-view__location {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card-view__location {
          background-color: rgba(map-get($primaryColors, $i), 0);
        }
        .shift-card-view__content {
          background-color: rgba(map-get($primaryColors, $i), 0.4);
        }
      }
    }
    &.shift-card-view--selected {
      .shift-card-view__content {
        background-color: $selectedBg;
      }
      &:hover {
        .shift-card-view__content {
          background-color: $selectedBg;
        }
      }
      .shift-card-view__location {
        visibility: hidden;
      }
    }
    &.shift-card-view--highlighted {
      .shift-card-view__location,
      .shift-card-view__content {
        background: transparent !important;
      }
    }
  }
}

_:-ms-fullscreen,
:root .shift-card-view .event-date {
  max-width: 85%;
}