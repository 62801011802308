.address-results {
  position: relative;
  height: inherit;
  z-index: 3;
  &__wrapper {
    background: #fff;
    z-index: 1;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 6px;
    position: absolute;
    top: calc(100% - 6px);
    left: 0;
    padding: 8px 16px;
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 10px 0;
    li {
      padding: 6px 15px;
      white-space: nowrap;
      cursor: pointer;
      //color: #ccc;
      //span {
      //  color: #000;
      //}
    }
  }
}

.geofencing-map-wrapper {
  border: 1px solid #bbb;
  overflow: hidden;
  border-radius: 6px;
  min-height: 386px;
  background: #F4F5F6;
}

.geofencing-radius-field-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
  span {
    padding-top: 7px;
    padding-left: 7px;
  }
}
.geofencing-radius-field {
  max-width: 158px;
}