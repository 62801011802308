@import "src/assets/css/_index.scss";
@import "../../../../variables";
.roster-copy-paste {
  display: inline-flex;

  // TODO maybe replace with SwitcherButton component
  .light-button {
    color: $gray-700;
    background-color: $white;
    cursor: pointer;
    &:hover {
      background-color: $gray-100;
    }
    &:focus {
      .elmo-btn__focused {
        display: none;
      }
    }
    &:disabled {
      background-color: transparent;
    }
    box-shadow: $shadow-100;
  }

  & &__copy-btn {
    border-radius: 3px 0 0 3px;

    .elmo-btn__label {
      display: none;

      @include mobile {
        display: inline-flex;
        white-space: nowrap;
      }
    }
  }

  & &__options-btn {
    border-radius: 0 3px 3px 0;
  }
}

_:-ms-fullscreen, :root .roster-copy-paste {
  display: flex;
  align-items: flex-end;
}
