@import 'src/assets/css/index';
@import 'src/assets/css/variables';

.week-summary {
  bottom: 0;
  position: relative;
  z-index: 4;
  $this: &;
  &__wrap {
    margin-left: 20px;
    z-index: 4;
    position: fixed;
    bottom: 0;
    &--hidden {
      width: 180px;
    }
  }

  &__data-summary {
    flex: none;
    min-width: 193px;
    background: #ffffff;
    padding-left: 8px;
  }

  &__days {
    flex-grow: 1;
  }

  &__days-section {
    display: flex;
    padding: 8px 0 0 0;
    border-bottom: 1px solid #DDDDDD;
    &:last-child {
      border-bottom: none;
    }
    @media (max-width: $mobile-min) {
      padding-top: 14px;
      padding-bottom: 3px;
    }
  }

  &__day {
    margin: 0 2px;
    min-width: 110px;
    background: $white;
    width: calc((100% / 7) - 4px);
  }

  &__data {
    display: flex;
  }
}

.summary-mobile {
  display: none;
  @media (max-width: $mobile-min) {
    display: block;
    margin-top: 65px;
  }
}
.summary-desktop {
  display: block;
  @media (max-width: $mobile-min) {
    display: none;
  }
}
