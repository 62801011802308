@import '../../assets/css/index';

.roster-header-navigation {
  display: flex;
  .flex {
    display: flex;
  }
  .center {
    align-items: center;
  }
  &__by-week {
    display: none;
    @include tablet {
      display: flex;
      width: 70px;
      justify-content: space-between;
      margin-right: 40px;
      margin-top: 8px;
      div {
        cursor: pointer;
      }
    }
  }
  &__by-controls {
    max-width: 90%;
    .elmo-popover {
      max-width: 100%;
    }
  }
  &__navigation {
    margin-left: 4px;

    @include mobile {
      margin-left: 16px;
    }
  }
}
