@import '../../../../../../assets/css/variables';

.day-divider__container {
  .is-collapsable {
    .elmo-roster-legend__duration {
      line-height: 15px;
    }
    &.is-collapsed {
      max-height: 57px;
      .toggle-panel-button {
        margin-top: -5px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .elmo-roster-legend {
        padding-top: 5px;
      }
      .roster-row__content-wrapper {
        overflow: hidden;
        margin-bottom: 1px;
      }
      .timeline__row--with-spacing:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.is-collapsable {
  &:not(.is-collapsed) {
    .timeline__improved {
      .shift-card--event {
        .shift-card__row {
          .delete-shift-icon {
            right: -3px;
          }
        }
      }
      .shift-card__content {
        .shift-card__row {
          position: relative;
          .delete-shift-icon {
            top: -5px;
            svg {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  &.is-collapsed {
    max-height: 49px;

    .elmo-roster-legend {
      min-height: 48px;
    }

    .roster-row__left-element-wrapper {
      .event-duration {
        visibility: hidden;
      }
    }

    .week-rosters-grid__group-wrapper:not(.total-info-wrapper) {
      max-height: 0;
      overflow: hidden;
    }

    .total-info {
      height: 48px;
      line-height: 48px;
      padding: 0;
      border: none;
      border-radius: 3px;
    }

    .add-row-button {
      display: none;
    }
  }
  .total-info {
    span:first-child {
      white-space: nowrap;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      strong {
        color: $gray-600
      }
    }
  }
}

_:-ms-fullscreen, :root .is-collapsable.is-collapsed {
  overflow: hidden;
  max-height: 57px;
}
