.timesheet-notes-field {
  min-height: 48px;
  height: 48px;
  overflow: hidden;
  resize:  none;
}

.timesheet-notes-placeholder {
  word-break: break-word;
}

.editable-wrapper {
  padding: 4px;
  transition: background-color .2s ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  &.is-opened {
    &:hover {
      background: none;
    }
  }
  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.85px;
  }
}