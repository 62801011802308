@import './breathe';

.select-placeholder {
  color: $gray-rgba-600;
}

.mui-multiple-select {
  &.select-placeholder {
    line-height: 36px;
    padding: 0 10px;
  }

  > .MuiSelect-multiple {
    padding: 6px 8px;
    min-height: 36px;
  }

  .selected-options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    > div[role='button'] {
      height: auto;
      background-color: $primary-bg;
      padding: 6px 5px;
      border-radius: 3px;
      box-shadow: $shadow-100;

      &:focus {
        outline: 1px solid $primary;
      }

      > span {
        color: $primary;
        font-size: 16px;
      }

      > svg {
        color: $primary;
        font-size: 22px;
        line-height: 24px;

        &:hover {
          color: $primary;
          opacity: 0.8;
        }
      }
    }
  }
}
