.elmo-datatable {
  &__row-actions {
    text-align: right;
    position: relative;

    .elmo-dropdown {
      display: flex;
    }

    .elmo-dropdown__button {
      margin-top: -12px;
      margin-bottom: -12px;
      margin-right: -12px;
    }
  }
}
