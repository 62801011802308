@import "../../../../assets/css/mixin";

.my-timesheet-view {
  &__pager-wrapper {
    @include mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
