// TODO: we need to clean this and put each part in its own module
@import '../../_lib/style/index';

.elmo-radio {
  display: inline-flex;
  position: relative;
  margin-right: 1em;
  margin-bottom: 0.5em;
  outline: none;

  &:focus:not(.elmo-radio--disabled) {
    .elmo-radio__icon {
      background-color: $primary;

      &:before {
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 50%;
      }
    }
  }

  &.elmo-radio--checked {
    .elmo-radio__icon {
      background-color: $primary;

      &:before {
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 50%;
      }

      &:after {
        width: 10px;
        height: 10px;
        background-color: $primary;
      }
    }

    &.elmo-radio--disabled {
      .elmo-radio__icon {
        &:after {
          background-color: $gray-400;
        }
      }
    }
  }

  &:hover:not(.elmo-radio--disabled) {
    .elmo-radio__icon {
      &:before {
        background-color: $primary-bg;
      }
    }
  }

  &:active:not(.elmo-radio--disabled) {
    .elmo-radio__icon {
      background-color: $primary-border;

      &:before {
        background-color: $primary-border;
      }
    }
  }

  &.elmo-radio--disabled {
    .elmo-radio__icon {
      background-color: $gray-400;

      &:before {
        background-color: $gray-300;
      }
    }

    .elmo-radio__element {
      input[type='radio'] {
        cursor: not-allowed;
      }
    }

    .elmo-radio__label {
      color: $gray-600;
    }
  }
}
