@import '../_lib/style/index';
$elmo-search: '.elmo-search';

#{$elmo-search} {
  order: 0;
  width: 100%;
  transition: all 0.25s;
  overflow: hidden;
  transform: translateY(-100%);

  &__wrapper {
    width: inherit;
    box-shadow: none;
    background-color: #fff;
    top: 0;
    padding: 8px 0 8px 12px;

    @include mobile {
      padding: 8px 32px;
    }

    @include tablet {
      padding: 16px 40px;
    }

    @include desktop {
      padding: 24px 40px;
    }
  }

  &__input {
    position: relative;

    .elmo-input {
      height: 48px;
      padding-right: 96px;
    }

    button.elmo-btn {
      position: absolute;
      right: 1px;
      top: 1px;
      height: 46px;
      border: none;
      background-color: #fff;

      &.elmo-search__btn-clear {
        right: 49px;
      }
    }
  }
}

// Modifier
#{$elmo-search}--open {
  transform: translateY(0);
}
