@import '../../../../../../../../assets/css/mixin';
@import '../../../../../../../../assets/css/variables';

.resizable-wrapper {
  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    &:hover {
      .resizable-wrapper__trigger {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &__trigger {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 7px;
    z-index: 1;
    &:before {
      opacity: 1;
    }

    &--right {
      right: 0;
      margin-left: -7px;
      cursor: e-resize;
      &:before {
        left: -5px;
      }
    }

    &--left {
      left: 0;
      margin-right: -7px;
      cursor: w-resize;
      &:before {
        left: -12px;
      }
    }

    &:before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNSAxM0w1Ljk5IDEzTDUuOTkgMTZMMiAxMkw1Ljk5IDhMNS45OSAxMUwxNSAxMUgxOC4wMVY4TDIyIDEyTDE4LjAxIDE2VjEzSDE1WiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC44NyIvPgo8L3N2Zz4K');
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      content: '';
      display: block;
      opacity: 0;
      font-size: 24px;
      position: absolute;
      height: 24px;
      line-height: 1;
      top: 50%;
      margin-top: -12px;
      transition: opacity .2s ease-in-out;
    }
  }

  &__content-wrapper {
    flex: 1 1 0%;
    min-width: 0;
  }

  &__time-marker {
    @include text-style-body-xs();

    position: absolute;
    top: -5px;
    background-color: $white;
    padding: 0 10px;
    border-radius: 9999px;
    white-space: nowrap;

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      transform: translateX(-50%) translateY(100%);
      left: 50%;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $white;
    }

    &--left {
      left: 0;
      transform: translateX(-50%) translateY(-100%);
    }

    &--right {
      right: 0;
      transform: translateX(50%) translateY(-100%);
    }
  }
}
