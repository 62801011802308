@import '../../../../assets/css/index';

.report-scroll-container {
  overflow-x: scroll;
  margin-left: -$ContentPaddingMobile;
  margin-right: -$ContentPaddingMobile;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    margin-left: -$ContentPaddingTablet;
    margin-right: -$ContentPaddingTablet;
  }
  @media (min-width: $desktop-min) {
    margin-left: -$ContentPaddingDesktop;
    margin-right: -$ContentPaddingDesktop;
    padding-left: $ContentPaddingDesktop;
    padding-right: $ContentPaddingDesktop;
  }

  .elmo-nativetable.striped tbody {
    .elmo-nativetable-tr:nth-child(2n) {
      background-color: rgba(0,0,0,.04);
    }
    .elmo-nativetable-tr:nth-child(2n) .elmo-nativetable-td:first-child,
    .elmo-nativetable-tr:nth-child(odd) .elmo-nativetable-td:first-child {
      background: transparent;
    }
  }
}