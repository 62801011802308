@import '../../../../assets/css/index';
@import '../../../../assets/css/variables';

.elmo-roster-legend {
  $this: &;

  background-color: $white;
  min-height: $cellMinHeight;
  min-width: 40px;
  height: 100%;
  border-radius: 6px;

  .toggle-panel-button {
    align-self: baseline;
    width: 38px;
    min-width: 38px;
  }

  &--events__collapsable {
    #{$this}__icon-wrapper {
      max-width: 32px;
      width: 32px;
      text-align: center;
      svg {
        max-width: 100%;
      }
    }
    .add-row-button {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 10px;
      cursor: pointer;
      color: $primary;
      width: 38px;
      min-width: 38px;
    }
    #{$this}__duration-value {
      margin-left: 0;
      font-size: 10px;
      letter-spacing: .25px;
    }
  }

  &.is-disabled {
    opacity: .5;
  }

  @media (min-width: $mobile-min) {
    min-width: $cellMinWidth;
  }

  &__mobile-card-placeholder {
    display: flex;
    height: inherit;
    &:after {
      display: block;
      width: 0;
      height: 0;
      content: '';
      border-top: 6px solid transparent;
      border-bottom: 0 solid transparent;
      border-right: 6px solid #000;
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
    @media (min-width: $mobile-min) {
      display: none;
      &:after {
        display: none;
      }
    }
  }

  &__desktop-card-placeholder {
    display: none;
    @media (max-width: $mobile-min) {
      &.is-opened {
        display: flex;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #fff;
        width: 196px;
        box-shadow: 1px 1px 1px $gray-400;
        &:after {
          display: block;
          width: 0;
          height: 0;
          content: '';
          border-bottom: 6px solid transparent;
          border-top: 0 solid transparent;
          border-left: 6px solid #000;
          position: absolute;
          right: 5px;
          bottom: 5px;
        }
      }
    }
    @media (min-width: $mobile-min) {
      display: flex;
    }
  }

  .is-collapsed {
    .add-row-button {
      display: none;
    }
  }

  &__icon-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 6px;
    max-width: 36px;
    width: 36px;
    position: relative;
    top: 5px;
  }

  &__content-wrapper {
    min-width: 0;
    padding-right: 8px;
    width: 100%;

    svg {
      width: 14px;
      height: 14px;
    }

  }

  &__label {
    @include text-style-heading-xxs;
    margin-top: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;

    #{$this}--unassigned-row &,
    #{$this}--single-row &,
    #{$this}--always-single-row & {
      margin-bottom: 0;
      margin-top: 3px;
    }

    #{$this}--no-label & {
      margin-top: 16px;
    }
  }

  &__duration {
    @include text-style-body-xs;
    display: flex;
    align-items: center;
    color: $gray-700;
    margin-top: -4px;

    //&:last-child {
    //  margin-bottom: 6px;
    //}

    &-value {
      margin-left: -28px;
      margin-bottom: 5px;
      white-space: nowrap;
      letter-spacing: .5px;
    }

    &.event-duration {
      text-align: left;
      &:first-child {
        margin-top: 40px;
      }
    }

    #{$this}--unassigned-row &,
    #{$this}--single-row &,
    #{$this}--always-single-row & {
      margin-top: 0;
    }
  }

  &__duration-quantity {
    flex-shrink: 0;
    margin: 4px 4px 0;
    width: 21px;
  }
  &__duration-time {
    flex-shrink: 0;
    margin: 4px 4px 0;
  }

  .grey-avatar {
    .elmo-avatar__label-icon {
      background: rgba(0, 0, 0, 0.07);
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
