@import '../../../../../../../../assets/css/variables';
@import '../../../../../../../../assets/css/mixin';

.day-popover {
  .elmo-popover-base__content {
    display: none;
    z-index: 1200;
    @include mobile {
      display: block;
    }
  }
  .popover-wrapper {
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #DBDBDB;
    overflow: hidden;
  }
  &__wrapper {
    display: flex;
    padding: 15px 10px;
    background: #fff;
    position: relative;
  }
  &__icon {
    width: 20px;
    text-align: left;
    margin-top: -3px;
  }
  &__content {
    padding-left: 15px;
    width: calc(100% - 20px);
    text-align: left;
    .date {
      margin-top: 10px;
    }
  }
}

.day-details__output-wrapper {
  border-top: 1px solid #cccccc;
  margin-top: 25px;
  padding-top: 25px;
  .day-popover__wrapper {
    width: 100%;
    .day-popover__content {
      position: relative;
    }
    .pin-r {
      position: absolute;
      right: 0;
      top: -15px;
    }
  }
  @include mobile {
    display: none;
  }
}

.popover-day__subtitle {
  display: block;
  font-size: 13px;
  margin-top: -7px;
  letter-spacing: 0.5px;
  color: $gray-700;
  font-weight: 400;
}