@import 'src/elmo-elements/_lib/style/index';
/* Elmo Header - interact with Search/BulkAction */

$this: '.elmo-header';

@mixin header-height($height) {
  + .elmo-header-wrapper__spacing {
    height: #{$height + 48}px; // 48: top menu
  }
}

.elmo-header-wrapper {
  font-family: $font-family;
  position: relative;
  z-index: 2;
  width: 100%;

  & + &__spacing {
    display: none;
  }

  #{ $this }__base {
    transition: all 0.3s;
    opacity: 1;
    max-height: 320px;
    min-height: 48px;
    padding-left: 0px;

    @include mobile {
      padding: 8px 0;
    }

    @include tablet {
      padding: 16px 0;
    }

    @include desktop {
      padding: 24px 0;
    }
  }

  &--bulk-actions #{ $this }__base {
    max-height: 48px;

    @include mobile {
      max-height: 64px;
    }

    @include tablet {
      max-height: 80px;
    }

    @include desktop {
      max-height: 96px;
    }
  }

  &--search #{ $this }__base {
    max-height: 64px;

    @include mobile {
      max-height: 64px;
    }

    @include tablet {
      max-height: 80px;
    }

    @include desktop {
      max-height: 96px;
    }
  }

  #{ $this }__close-btn {
    margin-right: 5px;
  }

  &--search,
  &--bulk-actions {
    #{ $this }__base {
      overflow: hidden;
      opacity: 0;
      z-index: -1;
    }

    .elmo-search,
    .elmo-bulk-actions {
      z-index: 1;
    }
  }

  .elmo-search,
  .elmo-bulk-actions {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
}

// Override header spacing for small screens
@include desktop-max {
  .elmo-layout__main-header {
    .elmo-header-wrapper {
      &.elmo-header-wrapper--search {
        @include header-height(64);

        @include mobile {
          @include header-height(64);
        }

        @include tablet {
          @include header-height(80);
        }

        @include desktop {
          @include header-height(96);
        }
      }

      &.elmo-header-wrapper--bulk-actions {
        @include header-height(48);

        @include mobile {
          @include header-height(64);
        }

        @include tablet {
          @include header-height(80);
        }

        @include desktop {
          @include header-height(96);
        }
      }
    }
  }
}

.elmo-layout--is-sidepanel-open .elmo-header-wrapper {
  @include desktop-max {
    @include desktop {
      width: calc(100% - 344px);
    }
  }
}

.elmo-header {
  $this: &;
  display: block;
  position: relative;
  z-index: 2;
  width: 100%;
  top: 0;

  &:after {
    content: '';
    display: block;
    clear: both;
    width: 100%;
    height: 0;
  }

  &__top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
  }

  &__title-wrapper {
    position: relative;
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    padding-left: 12px;

    @include mobile {
      padding-left: 32px;
    }

    @include tablet {
      padding-left: 40px;
    }
  }

  &__title-block-wrapper {
    overflow: hidden;

    .elmo-header__sub-heading {
      display: block;
      width: 100%;
      color: $gray-700;
      @include text-ellipsis;
      @include text-style-body-xs;

      @include mobile {
        @include text-style-body-sm;
      }
    }
  }
}

.elmo-layout--theme-min {
  .elmo-header-wrapper {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    z-index: 4;

    &:after {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
    }

    & + .elmo-header-wrapper__spacing {
      display: block;
      width: 100%;
      transition: height 0.3s;
    }

    @include header-height(0);

    &.elmo-header-wrapper--has-tabs {
      @include header-height(52);
    }

    #{ $this }__base {
      @include mobile {
        padding: 0 0 8px 0;
      }

      @include tablet {
        padding: 0 0 16px 0;
      }

      @include desktop {
        padding: 0 0 24px 0;
      }
    }
  }
}
