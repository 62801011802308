@import '../_lib/style/mixin';

.elmo-popover {
  $this: &;
  $margin: 4px;
  position: relative;
  display: inline-block;
  outline: 0;

  &__target {
    cursor: pointer;
  }

  &-base__content {
    #{$this}__content {
      &--auto-width {
        width: auto;
      }
      &--no-padding {
        padding: 0;
      }
    }
  }
}

.elmo-popover__content {
  @include popover-content;
}
