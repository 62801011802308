@import '../../_lib/style/index';

.elmo-listtable {
  $this: &;

  &--bulkactions-open {
    #{ $this }__row-wrapper {
      cursor: pointer;

      &--selected {
        background: $primary-bg;
      }
    }
  }

  &__cols {
    flex-grow: 1;
  }

  &__row-wrapper {
    box-shadow: $shadow-100;
    background: $white;
    border-radius: 6px 6px 6px 6px;
    padding: 24px;
    position: relative;

    &--clickable {
      cursor: pointer;
      transition: $transition-speed-normal;
      &:before {
        transition: $transition-speed-normal;

        content: '';
        display: block;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        left: -2px;
        top: -2px;
        border: 2px solid $primary-light;
        border-radius: 5px;
        opacity: 0;
      }
      &:hover {
        box-shadow: $shadow-300;
      }
      &:focus {
        // outline: 2px solid $primary-light;
        outline: none;
        &:before {
          opacity: 1;
        }
      }
      &:active {
        background-color: $gray-rgba-300;
      }
    }

    > .elmo-avatar {
      margin-top: -6px;
      margin-left: -6px;
      margin-bottom: -6px;
      align-self: flex-start;
    }
  }

  &__row-actions-placeholder {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &__row-actions {
    align-self: flex-start;
    margin-right: -16px;
    /* 24(padding-right of parent) - 8(padding-right of what it should be) */
    margin-top: -12px;
    margin-bottom: -12px;
    margin-left: 16px;
    position: relative;

    #{$this}__actions-icon {
      color: $gray-rgba-700;
    }

    .elmo-dropdown {
      &__button {
        &,
        &:focus {
          border: none;
          box-shadow: none;
        }
      }

      &--open {
        button {
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  &__thumbnail {
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 3px;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    align-self: flex-start;
    margin-right: 24px;
    font-size: 12px;

    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
}
