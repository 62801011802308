@import '../_lib/style/index';
.elmo-input {
  padding: 12px 16px;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  text-align: left;
  height: 48px;
  width: 100%;
  @include text-style-body-rg;
  color: $gray-700;
  transition: all 0.25s;
  &:disabled {
    background-color: $gray-300;
    color: $gray-rgba-600;
    -webkit-text-fill-color: unset;
    -webkit-opacity: 1;
  }
  &:read-only {
    background-color: $gray-200;
    -webkit-text-fill-color: unset;
    -webkit-opacity: 1;
  }
  &:focus {
    outline: 0;
    border-color: #129fea;
  }
  &:focus:invalid {
    color: #b94a48;
    border-color: #e9322d;
  }
  &::-webkit-input-placeholder {
    color: $gray-600;
  }
}

.elmo-input {
  &--on-error {
    border: 1px solid #ff9fa7;
    &:focus {
      outline: 0;
      border: 1px solid #d62735;
    }
  }
  &--on-warning {
    border: 1px solid #ffe58f;
    &:focus {
      outline: 0;
      border: 1px solid #ffd666;
    }
  }
  &--on-success {
    border: 1px solid #81e8b3;
    &:focus {
      outline: 0;
    }
  }

  &__wrapper {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    height: 48px;

    .elmo-input {
      border: 0;
      background: none;
      order: 1;
      height: 100%;

      &__adornment {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-700;
        order: 1;

        &--start {
          order: 0;
          padding-left: 16px;
          margin-right: -8px;
        }

        &--end {
          order: 1;
          padding-right: 12px;
          margin-left: -8px;
        }
      }
    }

    &:focus-within {
      border-color: #129fea;
    }

    &--on-error {
      border: 1px solid #ff9fa7;

      &:focus-within {
        outline: 0;
        border: 1px solid #d62735;
      }
    }

    &--on-warning {
      border: 1px solid #ffe58f;
      &:focus-within {
        border: 1px solid #ffd666;
      }
    }

    &--on-success {
      border: 1px solid #81e8b3;
    }

    &--is-disabled {
      background-color: $gray-300;
      color: $gray-rgba-600;
      .elmo-input__adornment {
        color: $gray-rgba-600;
      }
    }

    &--is-read-only {
      background-color: $gray-200;
    }
  }
}
