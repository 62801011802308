@import 'src/assets/css/index';

.elmo-select--checkbox {
  .react-select {
    &__value-container {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__option {
      display: flex;

      &.react-select__option--is-focused {
        background-color: transparent;
        color: $gray-rgba-800;
      }

      &.react-select__option--is-selected {
        background-color: transparent;
        color: $gray-rgba-800;
      }
    }
  }
  .react-select__menu-list {
    padding: 10px 5px;
    & > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
