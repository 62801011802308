@import '../../../../../../assets/css/mixin';
@import '../../vars';

.roster-day-navigation {
  flex: 0 0 auto;
  display: flex;
  position: relative;

  &__col {
    @include text-style-body-sm();

    color: $gray-rgba-700;
    display: flex;
    justify-content: space-between;
  }

  &__label {
    transform: translateX(-50%);
    white-space: nowrap;
  }

  &.improved {
    .roster-day-navigation__col {
      justify-content: center;
    }
    .roster-day-navigation__label {
      transform: translateX(0);
    }
  }
}
