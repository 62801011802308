.covid-print-report-wrapper {
  display: none;
}
.covid-printing-content {
  padding: 25px;
  h3 {
    font-weight: bold;
    text-align: center;
    padding: 15px 0;
  }
  table {
    width: 100%;
    border: 1px solid #666;
    tr {
      background: #ccc;
      border-bottom: 1px solid #666;
      &:nth-child(odd) {
        background: #fff;
      }
    }
    th, td {
      border-right: 1px solid #666;
      padding: 3px;
      &:last-child {
        border-right: none;
      }
    }
  }
}