@import '../../../../../../assets/css/variables';

.shift-break {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: $secondary;
  pointer-events: none;

  &--paid {
    background-color: $primary;
  }

  &--cup {
    background-color: transparent;
    border-left: 1px dashed $gray-700;
    border-right: 1px dashed $gray-700;
  }
}
