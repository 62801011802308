@import '../../_lib/style/index';

.elmo-select-list {
  &__option-header {
    padding: 12px 0 8px 12px;
    border-bottom: 1px solid $gray-rgba-400;
    color: $gray-rgba-700;
    font-size: 13.5px;
    letter-spacing: 0.6px;
  }
}
