@import 'src/assets/css/index';
@import 'src/assets/css/variables';

$color: #0077c8;
$radius: 4px;

.summary-button {
  width: 172px;
  display: inline-flex;

  $this: &;

  &__label {
    color: #ffffff;
    display: inline-flex;
    background: $color;
    border-radius:  $radius $radius 0 0;
    padding: 0 3px 1px 10px;
    cursor: pointer;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 40px;
    p.elmo-typography.text-size-rg {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      position: relative;
      white-space: nowrap;
    }
  }

  &__icon {
    display: inline-block;
    width: 34px;
    text-align: right;
  }

  &__content {
    background: $color;
    border-radius: 0 $radius $radius $radius;
  }

  &__icon-wrapper {
    margin: 8px 0 0 6px;
  }

  &__content-wrapper {
    min-width: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
}
