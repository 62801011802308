@import '../_lib/style/param';

.elmo-form-container {
  $this: &;

  &__inner {
    margin: 0 -12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;

    > .elmo-formitem {
      width: calc(368px + 24px);
      padding-left: 12px;
      padding-right: 12px;

      &.elmo-formitem--is-fluid {
        width: 100%;
      }

      &.elmo-formitem--size-md {
        width: calc(500px + 24px);
      }

      &.elmo-formitem--size-lg {
        width: calc(670px + 24px);
      }
    }

    > hr {
      color: transparent;
      border: none;
      height: 1px;
      width: 100%;
      margin-left: 12px;
      margin-right: 12px;
      position: relative;
      border-width: 1px 0 0 0;
      border-top: 1px solid $gray-rgba-400;
    }

    > .line-break {
      width: 100%;
    }
  }

  &--max-columns-1 {
    #{$this}__inner {
      max-width: 392px;
    }
  }

  &--max-columns-2 {
    #{$this}__inner {
      max-width: 784px;
    }

    .elmo-formitem--is-block-max2 {
      display: block;
    }
  }

  &--max-columns-3 {
    #{$this}__inner {
      max-width: 1176px;
    }
  }
}
