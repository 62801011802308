@import '../_lib/style/index';
.elmo-loadingoverlay {
  $contentOpacity: 0.65;
  position: relative;
  outline: 0;
  &__overlay {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity $transition-speed-normal;
    opacity: 0;
    z-index: 1;
    &.is-loading {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &__content {
    transition: opacity $transition-speed-normal;
    &.is-loading {
      opacity: $contentOpacity;
      pointer-events: none;
    }
  }
}
