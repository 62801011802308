.drag-in-progress {
  .add-another-shift-btn,
  .resizable {
    display: none;
  }
  .shift-card-view {
    pointer-events: none;
    user-select: none;
  }
  .dragging-to-remove {
    .shift-card-view__row {
      display: none;
    }
  }
  .week-view-container {
    will-change: transform, opacity, left, top;
  }
}