
_:-ms-fullscreen, :root .elmo-usercard__avatar--wrapper {
  min-width: 112px;
}

.user-details-card {
  .elmo-card__header {
    position: absolute;
    right: 30px;
  }
  .elmo-usercard__profile-data {
    a {
      text-overflow: ellipsis;
      max-width: 300px;
      overflow: hidden;
      display: block;
    }
  }
}