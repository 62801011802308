.custom-dropdown {
  position: relative;

  &__dropdown-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
