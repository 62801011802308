@import '../../_lib/style/param';

.elmo-listtable {
  &__select-message {
    color: $gray-rgba-700;
    font-size: 13.5px;
    letter-spacing: 0.7px;
    line-height: 16px;
    min-height: 48px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    span {
      color: $primary;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
