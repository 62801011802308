@use 'sass:math';
@import '../../vars';
@import '../../../../../../assets/css/mixin';

.day-divider {
  &__container {
    position: relative;
  }


  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    padding-left: map_get($contentXSpacing, 'default');

    @include mobile {
      padding-left: map_get($contentXSpacing, 'mobile');
    }

    @include tablet {
      padding-left: map_get($contentXSpacing, 'tablet');
    }
  }

  &__positioner {
    height: 100%;
    position: relative;
  }

  &__divider {
    position: absolute;
    top: -($navigationYSpacing);
    width: $currentDayDividerWidth;
    right: 0;
    bottom: 0;
    margin-right: -(math.div($currentDayDividerWidth, 2));

    &--primary {
      background-color: $primary;
    }

    &--gray-500 {
      background-color: $gray-500;
    }
  }
}
