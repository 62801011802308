@import '../../_lib/style/index';

.elmo-listtable {
  $this: &;

  &__header {
    color: $gray-rgba-700;
    font-size: 13.5px;
    letter-spacing: 0.6px;

    &-content {
      &__sortable {
        cursor: pointer;
      }
    }
  }

  &__header-wrapper {
    padding: 0 24px;
    margin-bottom: 8px;

    #{ $this }__row-checkbox {
      align-self: center;
    }

    #{ $this }__cols {
      &--actions {
        margin-right: 48px;
      }
    }
  }

  &#{$this}--bulkactions-open {
    #{$this}__header-wrapper {
      #{ $this }__cols {
        &--actions {
          margin-right: 0;
        }
      }
    }
  }

  &#{$this}--avatar-type--avatar {
    #{$this}__header-wrapper {
      padding-left: 72px;

      #{$this}__header {
        > .elmo-col:first-child {
          margin-left: -46px;
          margin-right: 46px;
        }
      }
    }

    &#{$this}--bulkactions-open {
      #{$this}__header-wrapper {
        padding-left: 24px;

        #{$this}__header {
          > .elmo-col:first-child {
            margin-left: 0;
            margin-right: 0;
          }
        }

        #{$this}__cols--actions {
          margin-left: 48px;
          margin-right: 0;
        }
      }
    }
  }

  &#{$this}--avatar-type--thumbnail {
    #{$this}__header-wrapper {
      #{$this}__cols {
        margin-left: calc(72px + 24px); // thumbnail + margin-right
      }

      #{$this}__header {
        .elmo-col:first-child {
          transform: translateX(-96px);
        }
      }
    }
  }
}
