@import '../../_lib/style/param';

.elmo-datatable {
  $this: &;

  #{$this}__options-button {
    color: $gray-rgba-600;
    // background: none;
    // border: none;
    // color: $gray-rgba-600;
    // width: 48px;
    // height: 48px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      color: $primary;
    }
  }

  &__sort-wrapper {
    &--sortable {
      cursor: pointer;
    }
  }

  &__head {
    border-style: solid;
    border-color: $gray-rgba-400;
    border-width: 0 0 1px 0;

    tr {
      th {
        vertical-align: bottom;
        position: relative;
        height: 48px;

        &.customise:last-child {
          #{$this}__sort-wrapper {
            margin-right: 48px;
          }
        }

        &.bulk-actions:first-child {
          #{$this}__sort-wrapper {
            margin-left: 56px;
          }
        }

        &.elmo-datatable__row-checkbox .elmo-checkbox {
          display: flex;
        }
      }
    }
  }

  th {
    font-weight: normal;
    color: $gray-rgba-700;
    font-size: 13.5px;
    letter-spacing: 0.6px;
  }
}
