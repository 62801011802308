@import '../_lib/style/index';

$paddingXSm: 8px;
$paddingSm: 16px;
$paddingMd: 24px;
$paddingLg: 32px;

$widthDialog: 432px;
$widthSmall: 432px;
$widthMedium: 564px;
$widthLarge: 734px;
$widthXLarge: 1100px;
$widthSide: 344px;

$ySpacingSm: 16px;
$ySpacing: 48px;

.elmo-modal {
  $block: &;
  $modifierSide: '--side';
  $modifierDialog: '--dialog';
  $modifierSmall: '--small';
  $modifierMedium: '--medium';
  $modifierLarge: '--large';
  $modifierXLarge: '--x-large';

  $modifierIsOpen: '--open';

  $modifierHasShadow: '--has-shadow';
  $modifierGrey: '--grey';

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  visibility: hidden;
  opacity: 0;
  z-index: -10;

  outline: none;

  &#{$modifierIsOpen} {
    visibility: visible;
    opacity: 1;
    z-index: 1300;
  }

  &__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.42);

    #{$block + $modifierSide} & {
      transform: translateX(100%);
    }

    #{$block + $modifierIsOpen} & {
      transform: translateX(0);
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    height: 100%;

    outline: none;

    #{$block + $modifierDialog} & {
      margin-top: $ySpacingSm;
      height: calc(100% - #{2 * $ySpacingSm});

      @include mobile {
        justify-content: center;
      }
    }

    #{$block + $modifierSmall} & {
      @include mobile {
        margin-top: $ySpacing;
        height: calc(100% - #{2 * $ySpacing});
      }
    }

    #{$block + $modifierMedium} &,
    #{$block + $modifierLarge} &,
    #{$block + $modifierXLarge} & {
      @include desktop {
        margin-top: $ySpacing;
        height: calc(100% - #{2 * $ySpacing});
      }
    }

    #{$block + $modifierSide} & {
      @include mobile {
        align-items: flex-end;
      }
    }
  }

  &__content {
    min-height: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    width: 100%;
    height: 100%;

    background-color: $white;
    box-shadow: $shadow-300;

    outline: none;

    @mixin maxWidth() {
      max-width: calc(100% - 32px);
      border-radius: 6px;
    }

    #{$block + $modifierDialog} & {
      height: auto;
      @include maxWidth();

      @include mobile {
        width: $widthDialog;
      }
    }

    #{$block + $modifierSmall} & {
      @include mobile {
        height: auto;
        width: $widthSmall;

        @include maxWidth();
      }
    }

    #{$block + $modifierMedium} &,
    #{$block + $modifierLarge} &,
    #{$block + $modifierXLarge} & {
      @include desktop {
        height: auto;
        @include maxWidth();
      }
    }


    #{$block + $modifierMedium} & {
      @include desktop {
        width: $widthMedium;
      }
    }

    #{$block + $modifierLarge} & {
      @include desktop {
        width: $widthLarge;
      }
    }

    #{$block + $modifierXLarge} & {
      @include desktop {
        width: $widthXLarge;
      }
    }

    #{$block + $modifierSide} & {
      @include mobile {
        width: $widthSide;
      }
    }
  }

  &__header {
    $modalPadding: $paddingSm $paddingLg;

    display: flex;
    align-items: center;
    padding: $paddingXSm;
    z-index: 1;
    border-bottom: 1px solid $gray-rgba-400;

    &#{$modifierHasShadow} {
      box-shadow: $shadow-200;
    }

    #{$block + $modifierDialog} & {
      border-bottom: none;
      padding: $paddingMd $paddingSm $paddingSm $paddingSm;

      @include mobile {
        padding: $paddingMd $paddingLg;
      }
    }

    #{$block + $modifierSmall} & {
      padding: 8px;

      @include mobile {
        border-bottom: none;
        padding: $paddingSm;
      }

      @include desktop {
        padding: $modalPadding;
      }
    }

    #{$block + $modifierMedium} &,
    #{$block + $modifierLarge} &,
    #{$block + $modifierXLarge} & {
      @include desktop {
        border-bottom: none;
        padding: $modalPadding;
      }
    }

    #{$block + $modifierSide} & {
      @include mobile {
        border-bottom: none;
        padding: $paddingSm $paddingMd;
      }
    }
  }

  &__header-back {
    margin-left: -4px;

    @include desktop {
      margin-left: -12px;
    }
  }

  &__header-close {
    order: 0;

    #{$block + $modifierDialog} & {
      display: none;
    }

    #{$block + $modifierSmall} &,
    #{$block + $modifierSide} & {
      margin-left: -4px;

      @include mobile {
        order: 2;
        margin-left: 0;
        margin-right: -8px;
      }
    }

    #{$block + $modifierMedium} &,
    #{$block + $modifierLarge} &,
    #{$block + $modifierXLarge} & {
      margin-left: -4px;

      @include desktop {
        order: 2;
        margin-left: 0;
        margin-right: -8px;
      }
    }
  }

  &__header-primary-btn {
    order: 2;

    #{$block + $modifierSmall} &,
    #{$block + $modifierSide} & {
      @include mobile {
        display: none;
      }
    }

    #{$block + $modifierMedium} &,
    #{$block + $modifierLarge} &,
    #{$block + $modifierXLarge} & {
      @include desktop {
        display: none;
      }
    }
  }

  &__header-title {
    order: 1;
    margin-right: auto;

    .elmo-typography {
      @include text-style-title-xs;

      @include mobile {
        @include text-style-title-sm;
      }
    }
  }

  &__body {
    padding: $paddingMd $paddingSm;
    overflow: auto;

    height: 100%;

    outline: none;

    &#{$modifierGrey} {
      background: #f5f5f5;
    }

    #{$block + $modifierDialog} & {
      padding: 0 $paddingSm $paddingSm $paddingSm;
      @include mobile {
        padding: $paddingXSm $paddingLg $paddingLg;
      }
    }

    #{$block + $modifierSmall} &,
    #{$block + $modifierMedium} &,
    #{$block + $modifierLarge} &,
    #{$block + $modifierXLarge} & {
      @include desktop {
        padding: $paddingLg;
      }
    }

    #{$block + $modifierSide} & {
      @include mobile {
        padding: $paddingMd;
      }
    }
  }

  &__footer {
    display: none;
    justify-content: flex-end;
    padding: 16px $paddingSm;

    &#{$modifierHasShadow} {
      position: relative;
      box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.1),
      0 -3px 6px 0 rgba(0, 0, 0, 0.14);
    }

    #{$block + $modifierDialog} & {
      display: flex;
      flex-direction: column-reverse;

      @include mobile {
        flex-direction: row;
      }
    }

    #{$block + $modifierSmall} & {
      @include mobile {
        display: flex;
      }
    }

    #{$block + $modifierSide} & {
      @include mobile {
        display: flex;
        justify-content: flex-start;
      }
    }

    #{$block + $modifierMedium} &,
    #{$block + $modifierLarge} &,
    #{$block + $modifierXLarge} & {
      @include desktop {
        display: flex;
      }
    }

    #{$block + $modifierSmall} &,
    #{$block + $modifierMedium} &,
    #{$block + $modifierLarge} &,
    #{$block + $modifierXLarge} & {
      @include desktop {
        padding: $paddingSm $paddingLg;
      }
    }

    #{$block + $modifierSide} & {
      @include desktop {
        padding: $paddingSm $paddingMd;
      }
    }
  }

  &__footer-close {
    margin-right: 8px;

    #{$block + $modifierSide} & {
      @include mobile {
        display: none;
      }
    }
  }

  &__footer-primary-btn {
    #{$block + $modifierDialog} & {
      margin-bottom: 8px;
    }
  }
}
