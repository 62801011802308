@import "src/assets/css/variables";
@import "src/assets/css/mixin";

.toggle-view {
  position: relative;
  z-index: 0;

  &--radio-buttons {
    display: flex;

    &.elmo-radio-buttons--solid {
      .elmo-radio {
        flex-grow: 1;
        width: 100%;
        padding: 0 8px;

        &__label {
          display: block;
          text-align: center;
          width: 100%;
        }

        &:focus {
          border: 1px solid #c4c7d0;
        }
      }
    }
  }

  @include mobile-only {
    .toggle-view--radio-buttons {
      display: none;
    }
  }

  @include tablet {
    .toggle-view--dropdown {
      display: none;
    }
  }

  .elmo-dropdown__button {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    order: 0;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.14);
    background-color: #e6f1ff;
    border: 1px solid #0077c8;
    color: #19579f;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 4px solid #0077c8;
      position: absolute;
      display: block;
      z-index: 1;
      bottom: 4px;
      left: 50%;
      margin-left: -6px;
    }
  }

  &--text-placeholder {
    .elmo-btn {
      padding: 0 5px;
      .elmo-btn__label {
        .elmo-dropdown__button__icon {
          display: none;
        }
      }
    }
  }
}

_:-ms-fullscreen, :root .toggle-view--radio-buttons.elmo-radio-buttons--solid .elmo-radio {
  width: auto;
}