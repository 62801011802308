.elmo-datatable {
  button.elmo-datatable__bulkactions-btn {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    left: 6px;
    bottom: 0;

    &:focus {
      outline: none;
    }
  }
}
