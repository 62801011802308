@import '../../../../assets/css/variables';
@import '../../variables';

.shift-card {
  box-shadow: 1px 1px 1px $gray-400;
  height: inherit;
  user-select: none;
  overflow: hidden;

  &.cursor-pointer {
    cursor: pointer;
  }

  &--event {
    box-shadow: none;
    .shift-card__content {
      padding-bottom: 2px;
      position: relative;
    }
    .shift-card__descr {
      padding-left: 7px;
    }
    &.card-height-lg {
      max-height: 56px;
      .shift-card__title h3 {
        line-height: 1;
        font-size: 14px;
        font-weight: 400;
      }
      .shift-card__content {
        padding-bottom: 0;
        padding-top: 1px;
        svg {
          font-size: 14px;
        }
        .shift-card__row:last-child p {
          line-height: 18px;
        }
      }
      .has-controls {
        .shift-card__title {
          display: inline-flex;
          align-items: center;
          .shift-card__icons {
            position: relative;
            top: -1px;
            text-align: left;
            .shift-card__icon-wrapper {
              padding-right: 5px;
            }
          }
        }
      }
    }
  }

  &.shift-card--event-type {
    background-color: #e0f8ff;
    border: 1px solid #92c5ce;
    &:hover {
      background-color: #d1f8ff;
    }
  }
  &.shift-card--forecast-type {
    background-color: rgb(239,236,230);
    border: 1px solid rgba(236, 202, 110, 0.8);
    &:hover {
      background-color: rgb(248, 232, 198);
    }
  }

  &--full-height {
    height: 100%;
  }

  &--full {
    border-radius: $cellBorderRadius;
  }
  &--top {
    border-radius: $cellBorderRadius $cellBorderRadius 0 0;
  }
  &--bottom {
    border-radius: 0 0 $cellBorderRadius $cellBorderRadius;
  }
  &--colored {
    background-color: #fff;
    transition: background-color .15s ease-in-out;
  }
  &--without-shadow {
    box-shadow: none;
  }
  &__content {
    display: flex;
    padding: 3px 3px 3px 0;
    min-height: 48px;
    min-width: $cellMinWidth;
    height: inherit;
  }
  &__location-abbr {
    border-radius: 0 10px 3px 0;
    min-width: 24px;
    max-width: 24px;
    margin-right: 8px;
    text-align: center;
    padding: 2px 1px;
    text-transform: uppercase;
    background-color: $gray-400;
    &--timesheet {
      border-radius: 0 0 10px 3px;
    }
  }
  &__descr {
    padding-top: 2px;
    line-height: 1;
    flex: 1 1 0%;
    min-width: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    h3 {
      color: inherit;
    }
    p {
      color: #000;
    }
    .text-size-xs {
      letter-spacing: 0;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.67);
      font-size: 10px;
    }
  }
  &__icon-wrapper {
    display: inline-block;
  }
  &__row {
    display: flex;
    align-items: baseline;
    &.content-centered {
      align-items: center;
    }
  }
  &__title  {
    flex: 1 1 0%;
    min-width: 0;
    white-space: nowrap;
    .elmo-tooltip {
      display: block;
    }
  }
  &__time {
    color: rgba(0, 0, 0, 0.67);
    font-size: 12px;
  }
  &__icons {
    flex: none;
    text-align: right;
  }
  &__messages {
    flex: none;
    width: 25px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.67);
    svg {
      height: 14px;
      width: 14px;
    }
  }
  // TODO: remove
  h5 {
    font-size: 10px !important;
    font-weight: bold !important;
    letter-spacing: -1px;
  }

  .event-date {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 95%;
    white-space: nowrap;
  }

  &__content-improved {
    padding: 0;

    .shift-card__descr {
      padding: 4px 4px 1px;
    }

    .shift-card__location {
      width: 8px;
    }

    .shift-card__title {
      min-height: 14px;
    }

    .shift-card__time {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      letter-spacing: 0.2px;
      color: rgba(0, 0, 0, 0.87);
      position: relative;
    }

    .shift-card__title h3 {
      font-size: 11px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.67);
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }

    .shift-card__title h4 {
      font-size: 11px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.67);
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }

    .shift-card__icons {
      margin-top: -3px;
      svg {
        font-size: 16px;
      }
    }

  }

  &__content-improved-v2 {
    &.shift-card__content {
      min-height: 56px;
    }
    .shift-card__row {
      min-height: 33%;
      position: relative;
    }
    .shift-card__row--spec-height {
      min-height: 25%;
    }
    .shift-card__time {
      font-size: 14px;
      line-height: 18px;
      font-style: normal;
    }
    .shift-card__location {
      width: 4px;
    }
    .shift-card__title h3 {
      font-size: 12px;
      color: rgba(0,0,0,0.87);
      width: 95%;
      max-width: 95%;
      position: absolute;
    }
    .shift-card__descr {
      padding-top: 2px;
    }
    .shift-card__icons {
      margin-top: 0;
      min-width: 40%;
    }
    .breaks-cup {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
    }
    .breaks-duration {
      display: inline-block;
      padding-left: 3px;
      color: rgba(0,0,0,0.87);
    }
    .toggle-contextual-menu {
      display: inline-block;
      vertical-align: middle;
      height: 20px;
      width: 20px;
      color: $gray-rgba-700;
      position: relative;
      right: -4px;
      &:hover {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
        color: $gray-rgba-600;
      }
      svg {
        font-size: 20px;
      }
    }
    .delete-shift-icon {
      right: -4px;
    }
  }

}

@for $i from 0 through 19 {
  .role-color-#{$i}-primary {
    .shift-card__location {
      background-color: map-get($primaryColors, $i);
    }
    &.not-published {
      .shift-card__content {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card__content {
          background-color: rgba(250, 250, 250, 0.8);
        }
      }
    }
    &.timesheet-card {
      .shift-card__content {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card__content {
          background-color: rgba(map-get($primaryColors, $i), 0.4);
        }
      }
    }
    &.has-connected-shift {
      .shift-card__location {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card__location {
          background-color: rgba(map-get($primaryColors, $i), 0);
        }
        .shift-card__content {
          background-color: rgba(map-get($primaryColors, $i), 0.4);
        }
      }
    }
    &.shift-card--selected {
      .shift-card__content {
        background-color: $selectedBg;
      }
      &:hover {
        .shift-card__content {
          background-color: $selectedBg;
        }
      }
      .shift-card__location {
        visibility: hidden;
      }
    }
    &.shift-card--highlighted {
      .shift-card__location,
      .shift-card__content {
        background: transparent !important;
      }
    }
  }
}

_:-ms-fullscreen, :root .shift-card .event-date {
  max-width: 85%;
}
