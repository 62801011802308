@import '../../_lib/style/param';

.elmo-filter {
  &__summary {
    width: 100%;
    margin: 10px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__title {
      display: inline-flex;
    }

    &__item {
      margin-left: 40px;
      font-size: 13.5px;
      margin-right: 16px;
      display: inline-flex;
      align-items: center;
      margin-right: -24px;

      b {
        display: inline-block;
        margin-right: 8px;
      }

      > .MuiSvgIcon-root {
        margin-right: 8px;
        font-size: 20px;
      }
    }

    &__button {
      display: inline-flex;

      .elmo-btn__icon svg {
        font-size: 20px;
      }

      .elmo-btn__label {
        font-size: 13.5px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
    }
  }
}
