@import '../../_lib/style/index';

.elmo-select-list {
  &__option {
    color: $gray-rgba-800;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 0 12px 12px;
    border-bottom: 1px solid $gray-rgba-400;
    align-items: center;
    outline: 0;

    &:first-child {
      border-top: 1px solid $gray-rgba-400;
    }

    &--disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &__item {
    max-width: calc(100% - 48px);
    @include text-ellipsis;
  }

  &__selected {
    width: 48px;
    height: 24px;
    text-align: center;
    color: $primary;
  }
}
