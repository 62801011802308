@import '../../../../assets/css/index';
@import '../../../../assets/css/variables';

.roster-site-view-heading {
  $this: &;
  $rowYSpacing: 8px;

  display: flex;
  position: relative;
  padding-right: map_get($contentXSpacing, 'default');
  padding-bottom: $rowYSpacing;

  @include mobile {
    padding-right: map_get($contentXSpacing, 'mobile');
  }

  @include tablet {
    padding-right: map_get($contentXSpacing, 'tablet');
  }

  &__inner-wrapper {
    position: sticky;
    left: 0;
    padding-left: map_get($contentXSpacing, 'default');
    background-color: $gray-100;
    margin-bottom: -($rowYSpacing);
    padding-bottom: $rowYSpacing;
    z-index: 1;
    padding-top: 8px;

    @include mobile {
      padding-left: map_get($contentXSpacing, 'mobile');
    }

    @include tablet {
      padding-left: map_get($contentXSpacing, 'tablet');
    }
  }

  &__title {
    font-size: 14px;
    line-height: 15px;
    color: rgba(0,0,0,0.87);
    letter-spacing: 0.2px;
  }
}
