@import '../_lib/style/index';

.elmo-select {
  $optionXSpacing: 12px;
  $optionBorderLeftSpacing: 22px;

  @mixin optionBorder() {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: $optionBorderLeftSpacing;
      height: 1px;
      background-color: $gray-rgba-400;
    }
  }

  border: 1px solid $gray-400;
  margin-bottom: 4px;
  border-radius: 3px;
  $this: '.react-select';

  #{ $this }-container {
    &:focus {
      outline: none;
    }
  }

  #{ $this }__single-value {
    //margin:0;
  }

  #{ $this }__control {
    border: none;
    box-shadow: none;
    border-radius: 3px;

    &:hover {
      border-color: transparent;
    }
  }

  #{ $this }__control--is-focused {
    box-shadow: 0 0 0 2px $primary;
  }

  #{ $this }__indicator {
    padding: 11px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  #{ $this }__indicator-separator {
    display: none;
  }

  #{ $this }__value-container {
    padding: 2px 14px;
  }

  #{ $this }__menu {
    box-shadow: $shadow-300;
    border: 1px solid $gray-rgba-400;
    padding: 0;
    overflow: hidden;
  }

  #{ $this }__menu-list {
    padding: 0;
  }

  #{ $this }__group {
    #{ $this }__option {
      @include optionBorder();
      padding: 12px $optionXSpacing 12px #{$optionXSpacing +
        $optionBorderLeftSpacing};
    }

    &:last-child {
      margin-bottom: 16px;
    }
  }

  #{ $this }__group-heading {
    @include text-style-body-sm();
    @include optionBorder();
    text-transform: none;
    color: $gray-rgba-700;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 4px;
    padding-left: $optionBorderLeftSpacing;
    margin-top: 12px;
  }

  #{ $this }__option {
    @include text-style-body-rg();
    color: $gray-rgba-800;
    position: relative;
    padding: 12px;

    &#{ $this }__option--is-focused {
      background-color: $primary-bg;
    }

    &#{ $this }__option--is-selected {
      color: $primary;
      background-color: $primary-bg;
    }

    &:last-child {
    }
  }

  /* Multi */
  .react-select__value-container--is-multi.react-select__value-container--has-value {
    padding-bottom: 0;
  }

  #{ $this }__multi-value {
    margin: 0 18px 4px -10px;
    align-content: center;
    border-radius: 3px;
    background-color: $primary-bg;
    box-shadow: $shadow-100;
  }

  #{ $this }__multi-value__label {
    padding: 0 0 0 8px;
    transition: padding-right 0.25s;
    font-size: 16px;
    line-height: 36px;
    color: $primary;
  }

  #{ $this }__multi-value__remove {
    padding: 0;
    justify-content: center;
    width: 36px;
    height: 36px;
    transition: color 0.25s;

    > svg {
      background-color: $primary;
      color: $primary-bg;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      padding: 1px;
      transition: opacity 0.25s;
    }

    &:hover {
      background-color: transparent;
      color: $primary;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
