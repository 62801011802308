@import "../../../variables";
@import "./src/assets/css/variables";

.resizable {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  display: none;
  min-width: 100%;

  &.is-resizing {
    top: 0;
    bottom: 0;
    box-shadow: 0 0 2px 2px $primary-light;
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 10;
    .resizer {
      pointer-events: auto;
      top: 0;
    }
  }

  .resizer {
    position: absolute;
    width: 16px;
    height: 100%;
    text-align: center;
    color: #2893fc;
    cursor: ew-resize;
    z-index: 1;
    &.resizer-r {
      right: 0;
      &:before {
        right: -12px;
      }
    }
    &.resizer-l {
      left: 0;
      &:before {
        left: -12px;
      }
    }
    &:hover {
      font-weight: bolder;
      font-size: 34px;
    }
    &:before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNSAxM0w1Ljk5IDEzTDUuOTkgMTZMMiAxMkw1Ljk5IDhMNS45OSAxMUwxNSAxMUgxOC4wMVY4TDIyIDEyTDE4LjAxIDE2VjEzSDE1WiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC44NyIvPgo8L3N2Zz4K');
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      content: '';
      display: block;
      font-size: 24px;
      position: absolute;
      height: 24px;
      line-height: 1;
      top: 50%;
      margin-top: -12px;
    }
  }
  &.no-resizer {
    display: none !important;
  }
}

.show-resizer {
  position: relative;
  .resizable {
    display: flex;
    align-items: center;
    align-content: center;
  }
}

.show-resizer + .delayed-placeholder {
  display: none !important;
}