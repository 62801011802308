@import "src/assets/css/_param.scss";

.rta-switcher-button.rta-switcher-button {
  color: $gray-700;
  background-color: $white;
  cursor: pointer;
  box-shadow: $shadow-100;

  &:hover {
    background-color: $gray-100;
  }
  &:focus {
    .elmo-btn__focused {
      display: none;
    }
  }
  &:disabled {
    background-color: transparent;
  }

  &.active {
    background-color: $primary-bg;
    color: $primary;
    outline: none;
  }

  &.disableElevation {
    box-shadow: none;
  }

  &.bordered {
    border: 1px solid #c4c7d0;
  }
}
