@import '../_lib/style/index';

.elmo-formitem {
  $this: &;
  display: inline-block;
  width: 368px;
  max-width: 100%;
  margin-bottom: 8px;
  vertical-align: text-top;

  /* Modifier */
  &--is-fluid {
    display: block;
    width: auto;
  }

  &--size-sm {
  }

  &--size-md {
    width: 500px;
  }

  &--size-lg {
    width: 670px;
  }

  &--is-inline {
    @include desktop {
      #{$this}__control__item {
        display: inline-flex;
      }

      #{$this}__control__item + #{$this}__control__item {
        margin-left: 32px;
      }
    }
  }

  &--is-divider {
    #{$this}__body {
      border-bottom: 1px solid $gray-400;
      margin-bottom: 24px;
      padding-bottom: 8px;
    }
  }

  &--is-icon {
    &:after {
      content: '';
      display: block;
      clear: both;
    }

    #{$this}__icon {
    }

    #{$this}__label {
      margin-top: 12px;
    }

    #{$this}__body {
      float: left;
      width: calc(100% - 40px);
    }
  }

  /* Children */
  &__icon {
    width: 40px;
    min-height: 48px;
    float: left;
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: 16px;
    color: $gray-700;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 8px;

    > label {
      font-weight: 600;
      margin-bottom: 0;
      display: inline-flex;
      align-items: center;

      > svg {
        font-size: 16px;
        margin-right: 8px;
      }
    }

    &-optional {
      margin-left: auto;
    }
  }

  &__control {
    &__item {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      /*
      &--text {
        border: 1px solid $gray-400;
        display: flex;
        border-radius: 3px;
        background-color: #fff;
        position: relative;
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &--checkradio {
        margin-top: 8px;
      }
      */
      #{$this}__control-clear {
        color: $gray-600;
      }
    }

    &__help {
      font-size: 13.5px;
      color: $gray-rgba-700;
      padding: 3px 16px 0px 16px;
      border-radius: 0 0 3px 3px;
      top: -3px;
      min-height: 23px;
    }
  }

  /* Modifiers */
  &--on-error {
    #{$this}__control__help {
      background-color: $danger-bg;
      color: $gray-700;
    }

    #{$this}__control__item {
      .elmo-input,
      .elmo-input__wrapper {
        border-color: $danger-border;
      }
    }
  }

  &--on-warning {
    #{$this}__control__help {
      background-color: $warning-bg;
      color: $gray-700;
    }

    #{$this}__control__item {
      .elmo-input,
      .elmo-input__wrapper {
        border-color: $warning-border;
      }
    }
  }

  &--on-success {
    #{$this}__control__help {
      background-color: $success-bg;
      color: $gray-700;
    }

    #{$this}__control__item {
      .elmo-input,
      .elmo-input__wrapper {
        border-color: $success-border;
      }
    }
  }
}
