@import '../../_lib/style/index';
.elmo-radio {
  &__element {
    position: relative;
    width: 24px;
    height: 24px;
    line-height: 1;
    display: inline-block;
    flex-shrink: 0;
    margin-right: 1em;
    input[type='radio'] {
      position: absolute;
      left: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      display: none;
    }
  }
  &__icon {
    transition: background-color 0.2s;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $gray-400;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-collapse: separate;
    &:before {
      transition: all 0.2s;
      display: block;
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-color: #fff;
      border-radius: 50%;
    }
    &:after {
      transition: all 0.2s;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      display: block;
      content: '';
      border-radius: 50%;
    }
  }
}
