@use 'sass:math';
@import '../../../../../../assets/css/variables';
@import '../../../../../../assets/css/mixin';

.roster-week-navigation {
  min-width: $weekGridMinWidth;
  width: 100%;

  &__days {
    $halfXGap: math.div($xGap, 2);

    margin-right: -($halfXGap);
    margin-left: -($halfXGap);
    display: flex;
    justify-content: space-between;
  }

  &__day {
    @include text-style-heading-xs();
    $dayWidth: math.div(100%, 7);

    width: calc(#{$dayWidth} - #{$xGap});
    text-align: center;
    color: $gray-rgba-700;
    display: flex;
    justify-content: center;
    margin-top: -2px;
    margin-bottom: -2px;
  }

  &__label {
    padding: 2px 10px;
    border-radius: 9999px;

    &.current {
      background-color: $primary;
      color: $white;
    }
  }
}
