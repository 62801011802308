@import '../../../../assets/css/variables';

.elmo-roster-cell {
  background-color: $gray-rgba-200;
  min-height: $cellMinHeight;
  height: 100%;
  min-width: $cellWeekMinWidth;

  &:hover {
    background: $gray-rgba-300;
    cursor: pointer;
  }

  &--is-tall {
    min-height: $cellMinHeightTall;
  }

  &--has-min-width {
    min-width: $cellMinWidth;
  }

  &--full {
    border-radius: $cellBorderRadius;
  }

  &--top {
    border-radius: $cellBorderRadius $cellBorderRadius 0 0;
  }

  &--bottom {
    border-top: $cellDividerBorder;
    border-radius: 0 0 $cellBorderRadius $cellBorderRadius;
  }

  &--placeholder {
    background-size: 0;
    transition: background .1s ease-in-out;
    &:hover {
      background-size: auto;
      transition: background .1s ease-in-out;
    }
  }
}
