@import '../../../../assets/css/index';
@import '../../../../assets/css/variables';

.roster-row {
  $this: &;
  $isDisabled: '.is-disabled';
  $rowYSpacing: 8px;

  display: flex;
  position: relative;
  padding-right: map_get($contentXSpacing, 'default');

  margin-bottom: $rowYSpacing;

  #{$this + $isDisabled} {
    pointer-events: none;
    user-select: none;
  }

  @include mobile {
    padding-right: map_get($contentXSpacing, 'mobile');
  }

  @include tablet {
    padding-right: map_get($contentXSpacing, 'tablet');
  }

  &__left-element-wrapper {
    position: sticky;
    left: 0;
    padding-left: map_get($contentXSpacing, 'default');
    background-color: $gray-100;
    margin-bottom: -($rowYSpacing);
    padding-bottom: $rowYSpacing;
    z-index: 1;

    @include mobile {
      padding-left: map_get($contentXSpacing, 'mobile');
    }

    @include tablet {
      padding-left: map_get($contentXSpacing, 'tablet');
    }
  }

  &__left-element {
    width: map_get($legendWidth, 'default');
    height: 100%;
    position: relative;

    @include mobile {
      width: map_get($legendWidth, 'mobile');
    }

    #{$this + $isDisabled} &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $white;
      opacity: $overlayOpacity;
      z-index: 1;
    }
  }

  &__content-wrapper {
    position: relative;
    margin-left: $xGap;
    width: 100%;

    #{$this + $isDisabled} & {
      opacity: 1 - $overlayOpacity;
    }
  }
}
