@import '../../../../../../assets/css/index';

.rosters-header-wrapper-improved {
  .elmo-bulk-actions__wrapper {
    padding: 8px 40px;
  }

  button.elmo-btn {
    .elmo-btn__label {
      text-transform: initial;
      font-size: 14px;
    }
  }

  .elmo-header-btns__primary {
    button.elmo-btn {
      .elmo-btn__label {
        font-weight: 500;
      }
    }
  }

  .elmo-header__title-wrapper {
    overflow: visible;
  }

  .elmo-header__title-block h1 {
    margin-top: 0;
    line-height: inherit;
    font-weight: 400;
  }

  .header-navigation__date-range {
    font-weight: 400;
    padding-right: 8px;
  }

  .elmo-header-wrapper {
    .elmo-header__top {
      max-height: 44px;
      flex-wrap: wrap;
    }
    .elmo-header__base {
      padding: 10px 0;
      .elmo-btn {
        max-height: 44px;
      }
    }
    // fixes the issue with sticky header placement
    // TODO find another solution
    z-index: 3;
  }

  .elmo-header-wrapper__spacing.elmo-header-wrapper__spacing.elmo-header-wrapper__spacing {
    height: 113px;
  }

  .clipboard-icon {
    position: absolute;
    z-index: 10;
    right: 10px;
    bottom: 10px;
  }
  @include tablet {
    z-index: 4;
    position: relative;
  }
  .elmo-header-btns {
    @include tablet {
      margin-top: 0;
    }
  }
  .publish-in-progress,
  .undo-in-progress {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .shift-overlap-modal__max-height {
    .elmo-modal__content {
      max-height: 500px;
    }
  }
  @include mobile-only {
    button.elmo-btn--primary.elmo-btn--is-disabled {
      .elmo-btn__icon {
        background: transparent !important;
      }
    }
  }

  button.elmo-btn.desktop-only {
    @include mobile-only {
      display: none;
    }
  }
  .mobile-only {
    display: none;
    @include mobile-only {
      display: block;
    }
  }
}

.elmo-layout__main-header {
  .rosters-header-wrapper-improved {
    .elmo-header-wrapper--bulk-actions + .elmo-header-wrapper__spacing {
      height: 113px;
    }
  }
}
