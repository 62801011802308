@import '../_lib/style/index';

button.elmo-btn {
  display: inline-flex;
  align-items: center;
  height: 48px;
  min-width: 48px;
  padding: 0 16px 0 16px;
  border-radius: 3px;
  font-family: $font-family;
  font-size: $font-size;
  transition: all 0.25s;
  border: none;
  justify-content: center;
  position: relative;
  z-index: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

button.elmo-btn {
  $this: '.elmo-btn';

  #{$this}__hover,
  #{$this}__focused,
  #{$this}__pressed {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    opacity: 0;
    z-index: 0;
    transition: opacity $transition-speed-normal;
    border-radius: 3px;
  }

  #{$this}__hover,
  #{$this}__pressed {
    background-color: $gray-rgba-200;
  }

  #{$this}__focused {
    background-color: $gray-rgba-300;
  }

  &:hover {
    #{$this}__hover {
      opacity: 1;
    }
  }

  &:focus {
    #{$this}__focused {
      opacity: 1;
    }
  }

  &:active {
    #{$this}__pressed {
      opacity: 1;
    }
  }

  &--default {
    color: $gray-rgba-700;
    background: transparent;
  }

  &--is-circular {
    background-color: $white;
    border-radius: 50%;

    #{$this}__hover,
    #{$this}__focused,
    #{$this}__pressed {
      border-radius: 50%;
    }
  }

  &--is-left-indent {
    margin-left: -12px;
  }

  &--primary {
    color: $white;
    background-color: $primary;

    #{$this}__hover {
      background-color: $white-rgba-200;
    }

    #{$this}__focused {
      background-color: $white-rgba-300;
    }

    #{$this}__pressed {
      background-color: $white-rgba-400;
    }
  }

  &--danger {
    color: $danger;
    background-color: transparent;
  }

  &--is-text {
    color: $gray-700;
    background: transparent;
    border-color: transparent;

    &.elmo-btn-- {
      &primary {
        // Alternate Action
        color: $primary;
        box-shadow: none;

        #{$this}__hover {
          background-color: $gray-rgba-200;
        }

        #{$this}__focused {
          background-color: $gray-rgba-300;
        }

        #{$this}__pressed {
          background-color: $gray-rgba-200;
        }
      }

      &danger {
        color: $danger;
      }
    }
  }

  &--icon {
    padding-left: 12px;
    padding-right: 12px;

    > .elmo-btn__icon {
      .MuiSvgIcon-root {
        display: block;
      }

      & + .elmo-btn__label {
        margin-left: 12px;
      }
    }

    > .elmo-btn__label {
      display: inline-flex;
      margin-right: 4px;
    }
  }

  &--is-loading {
    .elmo-btn__label,
    .elmo-btn__icon {
      transition: all 0.15s;
      opacity: 0;
    }
  }

  #{$this}__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;

    > .elmo-spinner {
      animation-name: spinner-svg;
      animation-duration: 0.15s;
      animation-iteration-count: 1;
      width: 24px;
      height: 24px;
      color: inherit;

      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &:disabled,
  &--is-disabled {
    cursor: not-allowed;
    color: $gray-600;

    &,
    &:focus,
    &:hover,
    &:active {
      color: $gray-600;
      background: transparent;
    }

    #{$this}__hover,
    #{$this}__focused,
    #{$this}__pressed {
      display: none;
    }
  }

  &--block {
    width: 100%;
    display: block;
  }
}

@keyframes spinner-svg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
