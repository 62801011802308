.isEditable {
  padding: 4px;
  border-radius: 3px;
  transition: background-color .2s ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.breaks-space {
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
}

.break-paid-label {
  position: absolute;
  top: -20px;
}

.absolute-icon {
  position: absolute;
  left: -30px;
  margin-top: 5px;
}

#new-shift-card {
  .elmo-actioncard__content-header {
    display: none;
  }
}

.action-shift-card {
  border-radius: 6px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px #d6d6d6;
  &__content {
    padding-left: 62px;
  }
  &__actions {
    border-top: 1px solid #ccc;
    justify-content: flex-end;
    span:first-of-type {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
  .card-header-options {
    display: flex;
    align-items: center;
  }
  .badge {
    margin-right: 15px;
  }
}
