@import '../../_lib/style/param';

.elmo-select-list {
  &__select-all-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: 48px;
    height: 48px;
    color: $gray-rgba-700;

    &:focus {
      outline: none;
    }

    &--active {
      color: $primary;
    }
  }
}
