.long-text {
  word-break: break-all;
  display: inline-block;
}

#user-details-card {
  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}