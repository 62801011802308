@import '../_lib/style/index';

.elmo-dl {
  $this: &;

  /* Modifyer */
  &--align-vertical {
    margin-bottom: 0;

    > #{$this}__dt {
      margin-bottom: 4px;
      @include text-style-body-sm;
      color: $gray-700;
    }

    > #{$this}__dd {
      padding-bottom: 16px;
      margin-bottom: 16px;

      &--has-divider {
        border-bottom: 1px solid $gray-400;
      }
    }
  }

  &--align-icons {
    display: flex;
    flex-wrap: wrap;

    > #{$this}__dt {
      height: 48px;
      width: 40px;
      display: flex;
      align-items: center;
    }

    > #{$this}__dd {
      display: block;
      width: calc(100% - 40px);
      padding-top: 12px;
      margin-bottom: 8px;

      &:after {
        display: block;
        content: '';
        clear: both;
      }
    }
  }

  &--align-horizontal {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;

    > #{$this}__dt {
      display: flex;
      align-items: center;
      height: 48px;
      width: 50%;
      @include text-style-body-sm;
      color: $gray-700;
      margin-bottom: 0;

      &--has-divider:nth-of-type(n + 2) {
        border-top: 1px solid $gray-400;
      }
    }

    > #{$this}__dd {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
      width: 50%;
      margin-bottom: 0;

      &--has-divider:nth-of-type(n + 2) {
        border-top: 1px solid $gray-400;
      }
    }
  }
}
