.full-screen-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.16);
  .elmo-spinner-container {
    transform: translate(50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}