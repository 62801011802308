.dragging {
  position: fixed;
  z-index: 0;
  .shift-card-view {
    margin-top: 10px;
    margin-left: 10px;
    opacity: .8;
    .shift-card-view__row {
      display: none;
    }
  }
  &.dragging-to-remove {
    top: -1000px;
  }
}