.elmo-col-1,
.elmo-col-2,
.elmo-col-3,
.elmo-col-4,
.elmo-col-5,
.elmo-col-6,
.elmo-col-7,
.elmo-col-8,
.elmo-col-9,
.elmo-col-10,
.elmo-col-11,
.elmo-col-12,
.elmo-col-13,
.elmo-col-14,
.elmo-col-15,
.elmo-col-16,
.elmo-col-17,
.elmo-col-18,
.elmo-col-19,
.elmo-col-20,
.elmo-col-21,
.elmo-col-22,
.elmo-col-23,
.elmo-col-24,
.elmo-col,
.elmo-col-auto,
.elmo-col-xs-1,
.elmo-col-xs-2,
.elmo-col-xs-3,
.elmo-col-xs-4,
.elmo-col-xs-5,
.elmo-col-xs-6,
.elmo-col-xs-7,
.elmo-col-xs-8,
.elmo-col-xs-9,
.elmo-col-xs-10,
.elmo-col-xs-11,
.elmo-col-xs-12,
.elmo-col-xs-13,
.elmo-col-xs-14,
.elmo-col-xs-15,
.elmo-col-xs-16,
.elmo-col-xs-17,
.elmo-col-xs-18,
.elmo-col-xs-19,
.elmo-col-xs-20,
.elmo-col-xs-21,
.elmo-col-xs-22,
.elmo-col-xs-23,
.elmo-col-xs-24,
.elmo-col-xs,
.elmo-col-xs-auto,
.elmo-col-sm-1,
.elmo-col-sm-2,
.elmo-col-sm-3,
.elmo-col-sm-4,
.elmo-col-sm-5,
.elmo-col-sm-6,
.elmo-col-sm-7,
.elmo-col-sm-8,
.elmo-col-sm-9,
.elmo-col-sm-10,
.elmo-col-sm-11,
.elmo-col-sm-12,
.elmo-col-sm-13,
.elmo-col-sm-14,
.elmo-col-sm-15,
.elmo-col-sm-16,
.elmo-col-sm-17,
.elmo-col-sm-18,
.elmo-col-sm-19,
.elmo-col-sm-20,
.elmo-col-sm-21,
.elmo-col-sm-22,
.elmo-col-sm-23,
.elmo-col-sm-24,
.elmo-col-sm,
.elmo-col-sm-auto,
.elmo-col-md-1,
.elmo-col-md-2,
.elmo-col-md-3,
.elmo-col-md-4,
.elmo-col-md-5,
.elmo-col-md-6,
.elmo-col-md-7,
.elmo-col-md-8,
.elmo-col-md-9,
.elmo-col-md-10,
.elmo-col-md-11,
.elmo-col-md-12,
.elmo-col-md-13,
.elmo-col-md-14,
.elmo-col-md-15,
.elmo-col-md-16,
.elmo-col-md-17,
.elmo-col-md-18,
.elmo-col-md-19,
.elmo-col-md-20,
.elmo-col-md-21,
.elmo-col-md-22,
.elmo-col-md-23,
.elmo-col-md-24,
.elmo-col-md,
.elmo-col-md-auto,
.elmo-col-lg-1,
.elmo-col-lg-2,
.elmo-col-lg-3,
.elmo-col-lg-4,
.elmo-col-lg-5,
.elmo-col-lg-6,
.elmo-col-lg-7,
.elmo-col-lg-8,
.elmo-col-lg-9,
.elmo-col-lg-10,
.elmo-col-lg-11,
.elmo-col-lg-12,
.elmo-col-lg-13,
.elmo-col-lg-14,
.elmo-col-lg-15,
.elmo-col-lg-16,
.elmo-col-lg-17,
.elmo-col-lg-18,
.elmo-col-lg-19,
.elmo-col-lg-20,
.elmo-col-lg-21,
.elmo-col-lg-22,
.elmo-col-lg-23,
.elmo-col-lg-24,
.elmo-col-lg,
.elmo-col-lg-auto,
.elmo-col-xl-1,
.elmo-col-xl-2,
.elmo-col-xl-3,
.elmo-col-xl-4,
.elmo-col-xl-5,
.elmo-col-xl-6,
.elmo-col-xl-7,
.elmo-col-xl-8,
.elmo-col-xl-9,
.elmo-col-xl-10,
.elmo-col-xl-11,
.elmo-col-xl-12,
.elmo-col-xl-13,
.elmo-col-xl-14,
.elmo-col-xl-15,
.elmo-col-xl-16,
.elmo-col-xl-17,
.elmo-col-xl-18,
.elmo-col-xl-19,
.elmo-col-xl-20,
.elmo-col-xl-21,
.elmo-col-xl-22,
.elmo-col-xl-23,
.elmo-col-xl-24,
.elmo-col-xl,
.elmo-col-xl-auto,
.elmo-col-xxl-1,
.elmo-col-xxl-2,
.elmo-col-xxl-3,
.elmo-col-xxl-4,
.elmo-col-xxl-5,
.elmo-col-xxl-6,
.elmo-col-xxl-7,
.elmo-col-xxl-8,
.elmo-col-xxl-9,
.elmo-col-xxl-10,
.elmo-col-xxl-11,
.elmo-col-xxl-12,
.elmo-col-xxl-13,
.elmo-col-xxl-14,
.elmo-col-xxl-15,
.elmo-col-xxl-16,
.elmo-col-xxl-17,
.elmo-col-xxl-18,
.elmo-col-xxl-19,
.elmo-col-xxl-20,
.elmo-col-xxl-21,
.elmo-col-xxl-22,
.elmo-col-xxl-23,
.elmo-col-xxl-24,
.elmo-col-xxl,
.elmo-col-xxl-auto,
.elmo-col-xxxl-1,
.elmo-col-xxxl-2,
.elmo-col-xxxl-3,
.elmo-col-xxxl-4,
.elmo-col-xxxl-5,
.elmo-col-xxxl-6,
.elmo-col-xxxl-7,
.elmo-col-xxxl-8,
.elmo-col-xxxl-9,
.elmo-col-xxxl-10,
.elmo-col-xxxl-11,
.elmo-col-xxxl-12,
.elmo-col-xxxl-13,
.elmo-col-xxxl-14,
.elmo-col-xxxl-15,
.elmo-col-xxxl-16,
.elmo-col-xxxl-17,
.elmo-col-xxxl-18,
.elmo-col-xxxl-19,
.elmo-col-xxxl-20,
.elmo-col-xxxl-21,
.elmo-col-xxxl-22,
.elmo-col-xxxl-23,
.elmo-col-xxxl-24,
.elmo-col-xxxl,
.elmo-col-xxxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

.elmo-col {
  // -ms-flex-preferred-size: 0;
  // flex-basis: 0;
  // -webkit-box-flex: 1;
  // -ms-flex-positive: 1;
  // flex-grow: 1;
  // max-width: 100%;
  // box-sizing: border-box;
}

.elmo-col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: auto;
  max-width: none;
}

/* span */

.elmo-col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 4.16667%;
  flex: 0 0 4.16667%;
  max-width: 4.16667%;
}

.elmo-col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.elmo-col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.elmo-col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.elmo-col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20.83333%;
  flex: 0 0 20.83333%;
  max-width: 20.83333%;
}

.elmo-col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.elmo-col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 29.16667%;
  flex: 0 0 29.16667%;
  max-width: 29.16667%;
}

.elmo-col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.elmo-col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 37.5%;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.elmo-col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.elmo-col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45.83333%;
  flex: 0 0 45.83333%;
  max-width: 45.83333%;
}

.elmo-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.elmo-col-13 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 54.16667%;
  flex: 0 0 54.16667%;
  max-width: 54.16667%;
}

.elmo-col-14 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.elmo-col-15 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 62.5%;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.elmo-col-16 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.elmo-col-17 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70.83333%;
  flex: 0 0 70.83333%;
  max-width: 70.83333%;
}

.elmo-col-18 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.elmo-col-19 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 79.16667%;
  flex: 0 0 79.16667%;
  max-width: 79.16667%;
}

.elmo-col-20 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.elmo-col-21 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 87.5%;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.elmo-col-22 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.elmo-col-23 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 95.83333%;
  flex: 0 0 95.83333%;
  max-width: 95.83333%;
}

.elmo-col-24 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

/* order */

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 26;
  -ms-flex-order: 25;
  order: 25;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.order-13 {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-14 {
  -webkit-box-ordinal-group: 15;
  -ms-flex-order: 14;
  order: 14;
}

.order-15 {
  -webkit-box-ordinal-group: 16;
  -ms-flex-order: 15;
  order: 15;
}

.order-16 {
  -webkit-box-ordinal-group: 17;
  -ms-flex-order: 16;
  order: 16;
}

.order-17 {
  -webkit-box-ordinal-group: 18;
  -ms-flex-order: 17;
  order: 17;
}

.order-18 {
  -webkit-box-ordinal-group: 19;
  -ms-flex-order: 18;
  order: 18;
}

.order-19 {
  -webkit-box-ordinal-group: 20;
  -ms-flex-order: 19;
  order: 19;
}

.order-20 {
  -webkit-box-ordinal-group: 21;
  -ms-flex-order: 20;
  order: 20;
}

.order-21 {
  -webkit-box-ordinal-group: 22;
  -ms-flex-order: 21;
  order: 21;
}

.order-22 {
  -webkit-box-ordinal-group: 23;
  -ms-flex-order: 22;
  order: 22;
}

.order-23 {
  -webkit-box-ordinal-group: 24;
  -ms-flex-order: 23;
  order: 23;
}

.order-24 {
  -webkit-box-ordinal-group: 25;
  -ms-flex-order: 24;
  order: 24;
}

/* offset */

.elmo-offset-1 {
  margin-left: 4.16667%;
}

.elmo-offset-2 {
  margin-left: 8.33333%;
}

.elmo-offset-3 {
  margin-left: 12.5%;
}

.elmo-offset-4 {
  margin-left: 16.66667%;
}

.elmo-offset-5 {
  margin-left: 20.83333%;
}

.elmo-offset-6 {
  margin-left: 25%;
}

.elmo-offset-7 {
  margin-left: 29.16667%;
}

.elmo-offset-8 {
  margin-left: 33.33333%;
}

.elmo-offset-9 {
  margin-left: 37.5%;
}

.elmo-offset-10 {
  margin-left: 41.66667%;
}

.elmo-offset-11 {
  margin-left: 45.83333%;
}

.elmo-offset-12 {
  margin-left: 50%;
}

.elmo-offset-13 {
  margin-left: 54.16667%;
}

.elmo-offset-14 {
  margin-left: 58.33333%;
}

.elmo-offset-15 {
  margin-left: 62.5%;
}

.elmo-offset-16 {
  margin-left: 66.66667%;
}

.elmo-offset-17 {
  margin-left: 70.83333%;
}

.elmo-offset-18 {
  margin-left: 75%;
}

.elmo-offset-19 {
  margin-left: 79.16667%;
}

.elmo-offset-20 {
  margin-left: 83.33333%;
}

.elmo-offset-21 {
  margin-left: 87.5%;
}

.elmo-offset-22 {
  margin-left: 91.66667%;
}

.elmo-offset-23 {
  margin-left: 95.83333%;
}

/* align-self */

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

/* flex-shrink */

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

/* flex-grow */

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

/* flex */

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

@media (min-width: 600px) {
  .elmo-col-xs {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .elmo-col-xs-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .elmo-col-xs-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }

  .elmo-col-xs-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .elmo-col-xs-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .elmo-col-xs-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .elmo-col-xs-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }

  .elmo-col-xs-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .elmo-col-xs-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }

  .elmo-col-xs-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .elmo-col-xs-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .elmo-col-xs-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .elmo-col-xs-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }

  .elmo-col-xs-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .elmo-col-xs-13 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }

  .elmo-col-xs-14 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .elmo-col-xs-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .elmo-col-xs-16 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .elmo-col-xs-17 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }

  .elmo-col-xs-18 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .elmo-col-xs-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }

  .elmo-col-xs-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .elmo-col-xs-21 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .elmo-col-xs-22 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .elmo-col-xs-23 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }

  .elmo-col-xs-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xs-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xs-last {
    -webkit-box-ordinal-group: 26;
    -ms-flex-order: 25;
    order: 25;
  }

  .order-xs-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xs-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xs-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xs-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xs-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xs-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xs-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xs-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xs-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xs-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xs-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xs-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xs-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .order-xs-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xs-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order: 14;
    order: 14;
  }

  .order-xs-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order: 15;
    order: 15;
  }

  .order-xs-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order: 16;
    order: 16;
  }

  .order-xs-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order: 17;
    order: 17;
  }

  .order-xs-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order: 18;
    order: 18;
  }

  .order-xs-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order: 19;
    order: 19;
  }

  .order-xs-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }

  .order-xs-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order: 21;
    order: 21;
  }

  .order-xs-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order: 22;
    order: 22;
  }

  .order-xs-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order: 23;
    order: 23;
  }

  .order-xs-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order: 24;
    order: 24;
  }

  .elmo-offset-xs-0 {
    margin-left: 0;
  }

  .elmo-offset-xs-1 {
    margin-left: 4.16667%;
  }

  .elmo-offset-xs-2 {
    margin-left: 8.33333%;
  }

  .elmo-offset-xs-3 {
    margin-left: 12.5%;
  }

  .elmo-offset-xs-4 {
    margin-left: 16.66667%;
  }

  .elmo-offset-xs-5 {
    margin-left: 20.83333%;
  }

  .elmo-offset-xs-6 {
    margin-left: 25%;
  }

  .elmo-offset-xs-7 {
    margin-left: 29.16667%;
  }

  .elmo-offset-xs-8 {
    margin-left: 33.33333%;
  }

  .elmo-offset-xs-9 {
    margin-left: 37.5%;
  }

  .elmo-offset-xs-10 {
    margin-left: 41.66667%;
  }

  .elmo-offset-xs-11 {
    margin-left: 45.83333%;
  }

  .elmo-offset-xs-12 {
    margin-left: 50%;
  }

  .elmo-offset-xs-13 {
    margin-left: 54.16667%;
  }

  .elmo-offset-xs-14 {
    margin-left: 58.33333%;
  }

  .elmo-offset-xs-15 {
    margin-left: 62.5%;
  }

  .elmo-offset-xs-16 {
    margin-left: 66.66667%;
  }

  .elmo-offset-xs-17 {
    margin-left: 70.83333%;
  }

  .elmo-offset-xs-18 {
    margin-left: 75%;
  }

  .elmo-offset-xs-19 {
    margin-left: 79.16667%;
  }

  .elmo-offset-xs-20 {
    margin-left: 83.33333%;
  }

  .elmo-offset-xs-21 {
    margin-left: 87.5%;
  }

  .elmo-offset-xs-22 {
    margin-left: 91.66667%;
  }

  .elmo-offset-xs-23 {
    margin-left: 95.83333%;
  }

  .align-self-xs-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xs-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xs-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xs-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xs-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xs-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .flex-shrink-xs-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-shrink-xs-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-grow-xs-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-grow-xs-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xs-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
}

@media (min-width: 840px) {
  .elmo-col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .elmo-col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .elmo-col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }

  .elmo-col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .elmo-col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .elmo-col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .elmo-col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }

  .elmo-col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .elmo-col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }

  .elmo-col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .elmo-col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .elmo-col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .elmo-col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }

  .elmo-col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .elmo-col-sm-13 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }

  .elmo-col-sm-14 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .elmo-col-sm-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .elmo-col-sm-16 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .elmo-col-sm-17 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }

  .elmo-col-sm-18 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .elmo-col-sm-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }

  .elmo-col-sm-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .elmo-col-sm-21 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .elmo-col-sm-22 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .elmo-col-sm-23 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }

  .elmo-col-sm-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 26;
    -ms-flex-order: 25;
    order: 25;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .order-sm-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order: 14;
    order: 14;
  }

  .order-sm-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order: 15;
    order: 15;
  }

  .order-sm-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order: 16;
    order: 16;
  }

  .order-sm-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order: 17;
    order: 17;
  }

  .order-sm-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order: 18;
    order: 18;
  }

  .order-sm-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order: 19;
    order: 19;
  }

  .order-sm-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }

  .order-sm-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order: 21;
    order: 21;
  }

  .order-sm-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order: 22;
    order: 22;
  }

  .order-sm-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order: 23;
    order: 23;
  }

  .order-sm-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order: 24;
    order: 24;
  }

  .elmo-offset-sm-0 {
    margin-left: 0;
  }

  .elmo-offset-sm-1 {
    margin-left: 4.16667%;
  }

  .elmo-offset-sm-2 {
    margin-left: 8.33333%;
  }

  .elmo-offset-sm-3 {
    margin-left: 12.5%;
  }

  .elmo-offset-sm-4 {
    margin-left: 16.66667%;
  }

  .elmo-offset-sm-5 {
    margin-left: 20.83333%;
  }

  .elmo-offset-sm-6 {
    margin-left: 25%;
  }

  .elmo-offset-sm-7 {
    margin-left: 29.16667%;
  }

  .elmo-offset-sm-8 {
    margin-left: 33.33333%;
  }

  .elmo-offset-sm-9 {
    margin-left: 37.5%;
  }

  .elmo-offset-sm-10 {
    margin-left: 41.66667%;
  }

  .elmo-offset-sm-11 {
    margin-left: 45.83333%;
  }

  .elmo-offset-sm-12 {
    margin-left: 50%;
  }

  .elmo-offset-sm-13 {
    margin-left: 54.16667%;
  }

  .elmo-offset-sm-14 {
    margin-left: 58.33333%;
  }

  .elmo-offset-sm-15 {
    margin-left: 62.5%;
  }

  .elmo-offset-sm-16 {
    margin-left: 66.66667%;
  }

  .elmo-offset-sm-17 {
    margin-left: 70.83333%;
  }

  .elmo-offset-sm-18 {
    margin-left: 75%;
  }

  .elmo-offset-sm-19 {
    margin-left: 79.16667%;
  }

  .elmo-offset-sm-20 {
    margin-left: 83.33333%;
  }

  .elmo-offset-sm-21 {
    margin-left: 87.5%;
  }

  .elmo-offset-sm-22 {
    margin-left: 91.66667%;
  }

  .elmo-offset-sm-23 {
    margin-left: 95.83333%;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .flex-shrink-sm-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-shrink-sm-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-grow-sm-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-grow-sm-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
}

@media (min-width: 1024px) {
  .elmo-col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .elmo-col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .elmo-col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }

  .elmo-col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .elmo-col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .elmo-col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .elmo-col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }

  .elmo-col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .elmo-col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }

  .elmo-col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .elmo-col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .elmo-col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .elmo-col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }

  .elmo-col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .elmo-col-md-13 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }

  .elmo-col-md-14 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .elmo-col-md-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .elmo-col-md-16 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .elmo-col-md-17 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }

  .elmo-col-md-18 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .elmo-col-md-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }

  .elmo-col-md-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .elmo-col-md-21 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .elmo-col-md-22 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .elmo-col-md-23 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }

  .elmo-col-md-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 26;
    -ms-flex-order: 25;
    order: 25;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .order-md-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order: 14;
    order: 14;
  }

  .order-md-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order: 15;
    order: 15;
  }

  .order-md-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order: 16;
    order: 16;
  }

  .order-md-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order: 17;
    order: 17;
  }

  .order-md-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order: 18;
    order: 18;
  }

  .order-md-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order: 19;
    order: 19;
  }

  .order-md-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }

  .order-md-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order: 21;
    order: 21;
  }

  .order-md-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order: 22;
    order: 22;
  }

  .order-md-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order: 23;
    order: 23;
  }

  .order-md-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order: 24;
    order: 24;
  }

  .elmo-offset-md-0 {
    margin-left: 0;
  }

  .elmo-offset-md-1 {
    margin-left: 4.16667%;
  }

  .elmo-offset-md-2 {
    margin-left: 8.33333%;
  }

  .elmo-offset-md-3 {
    margin-left: 12.5%;
  }

  .elmo-offset-md-4 {
    margin-left: 16.66667%;
  }

  .elmo-offset-md-5 {
    margin-left: 20.83333%;
  }

  .elmo-offset-md-6 {
    margin-left: 25%;
  }

  .elmo-offset-md-7 {
    margin-left: 29.16667%;
  }

  .elmo-offset-md-8 {
    margin-left: 33.33333%;
  }

  .elmo-offset-md-9 {
    margin-left: 37.5%;
  }

  .elmo-offset-md-10 {
    margin-left: 41.66667%;
  }

  .elmo-offset-md-11 {
    margin-left: 45.83333%;
  }

  .elmo-offset-md-12 {
    margin-left: 50%;
  }

  .elmo-offset-md-13 {
    margin-left: 54.16667%;
  }

  .elmo-offset-md-14 {
    margin-left: 58.33333%;
  }

  .elmo-offset-md-15 {
    margin-left: 62.5%;
  }

  .elmo-offset-md-16 {
    margin-left: 66.66667%;
  }

  .elmo-offset-md-17 {
    margin-left: 70.83333%;
  }

  .elmo-offset-md-18 {
    margin-left: 75%;
  }

  .elmo-offset-md-19 {
    margin-left: 79.16667%;
  }

  .elmo-offset-md-20 {
    margin-left: 83.33333%;
  }

  .elmo-offset-md-21 {
    margin-left: 87.5%;
  }

  .elmo-offset-md-22 {
    margin-left: 91.66667%;
  }

  .elmo-offset-md-23 {
    margin-left: 95.83333%;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .flex-shrink-md-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-shrink-md-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-grow-md-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-grow-md-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
}

@media (min-width: 1280px) {
  .elmo-col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .elmo-col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .elmo-col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }

  .elmo-col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .elmo-col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .elmo-col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .elmo-col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }

  .elmo-col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .elmo-col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }

  .elmo-col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .elmo-col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .elmo-col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .elmo-col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }

  .elmo-col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .elmo-col-lg-13 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }

  .elmo-col-lg-14 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .elmo-col-lg-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .elmo-col-lg-16 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .elmo-col-lg-17 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }

  .elmo-col-lg-18 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .elmo-col-lg-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }

  .elmo-col-lg-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .elmo-col-lg-21 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .elmo-col-lg-22 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .elmo-col-lg-23 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }

  .elmo-col-lg-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 26;
    -ms-flex-order: 25;
    order: 25;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .order-lg-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order: 14;
    order: 14;
  }

  .order-lg-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order: 15;
    order: 15;
  }

  .order-lg-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order: 16;
    order: 16;
  }

  .order-lg-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order: 17;
    order: 17;
  }

  .order-lg-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order: 18;
    order: 18;
  }

  .order-lg-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order: 19;
    order: 19;
  }

  .order-lg-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }

  .order-lg-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order: 21;
    order: 21;
  }

  .order-lg-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order: 22;
    order: 22;
  }

  .order-lg-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order: 23;
    order: 23;
  }

  .order-lg-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order: 24;
    order: 24;
  }

  .elmo-offset-lg-0 {
    margin-left: 0;
  }

  .elmo-offset-lg-1 {
    margin-left: 4.16667%;
  }

  .elmo-offset-lg-2 {
    margin-left: 8.33333%;
  }

  .elmo-offset-lg-3 {
    margin-left: 12.5%;
  }

  .elmo-offset-lg-4 {
    margin-left: 16.66667%;
  }

  .elmo-offset-lg-5 {
    margin-left: 20.83333%;
  }

  .elmo-offset-lg-6 {
    margin-left: 25%;
  }

  .elmo-offset-lg-7 {
    margin-left: 29.16667%;
  }

  .elmo-offset-lg-8 {
    margin-left: 33.33333%;
  }

  .elmo-offset-lg-9 {
    margin-left: 37.5%;
  }

  .elmo-offset-lg-10 {
    margin-left: 41.66667%;
  }

  .elmo-offset-lg-11 {
    margin-left: 45.83333%;
  }

  .elmo-offset-lg-12 {
    margin-left: 50%;
  }

  .elmo-offset-lg-13 {
    margin-left: 54.16667%;
  }

  .elmo-offset-lg-14 {
    margin-left: 58.33333%;
  }

  .elmo-offset-lg-15 {
    margin-left: 62.5%;
  }

  .elmo-offset-lg-16 {
    margin-left: 66.66667%;
  }

  .elmo-offset-lg-17 {
    margin-left: 70.83333%;
  }

  .elmo-offset-lg-18 {
    margin-left: 75%;
  }

  .elmo-offset-lg-19 {
    margin-left: 79.16667%;
  }

  .elmo-offset-lg-20 {
    margin-left: 83.33333%;
  }

  .elmo-offset-lg-21 {
    margin-left: 87.5%;
  }

  .elmo-offset-lg-22 {
    margin-left: 91.66667%;
  }

  .elmo-offset-lg-23 {
    margin-left: 95.83333%;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .flex-shrink-lg-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-shrink-lg-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-grow-lg-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-grow-lg-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
}

@media (min-width: 1440px) {
  .elmo-col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .elmo-col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .elmo-col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }

  .elmo-col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .elmo-col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .elmo-col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .elmo-col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }

  .elmo-col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .elmo-col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }

  .elmo-col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .elmo-col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .elmo-col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .elmo-col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }

  .elmo-col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .elmo-col-xl-13 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }

  .elmo-col-xl-14 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .elmo-col-xl-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .elmo-col-xl-16 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .elmo-col-xl-17 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }

  .elmo-col-xl-18 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .elmo-col-xl-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }

  .elmo-col-xl-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .elmo-col-xl-21 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .elmo-col-xl-22 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .elmo-col-xl-23 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }

  .elmo-col-xl-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 26;
    -ms-flex-order: 25;
    order: 25;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .order-xl-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order: 14;
    order: 14;
  }

  .order-xl-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order: 15;
    order: 15;
  }

  .order-xl-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order: 16;
    order: 16;
  }

  .order-xl-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order: 17;
    order: 17;
  }

  .order-xl-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order: 18;
    order: 18;
  }

  .order-xl-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order: 19;
    order: 19;
  }

  .order-xl-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }

  .order-xl-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order: 21;
    order: 21;
  }

  .order-xl-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order: 22;
    order: 22;
  }

  .order-xl-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order: 23;
    order: 23;
  }

  .order-xl-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order: 24;
    order: 24;
  }

  .elmo-offset-xl-0 {
    margin-left: 0;
  }

  .elmo-offset-xl-1 {
    margin-left: 4.16667%;
  }

  .elmo-offset-xl-2 {
    margin-left: 8.33333%;
  }

  .elmo-offset-xl-3 {
    margin-left: 12.5%;
  }

  .elmo-offset-xl-4 {
    margin-left: 16.66667%;
  }

  .elmo-offset-xl-5 {
    margin-left: 20.83333%;
  }

  .elmo-offset-xl-6 {
    margin-left: 25%;
  }

  .elmo-offset-xl-7 {
    margin-left: 29.16667%;
  }

  .elmo-offset-xl-8 {
    margin-left: 33.33333%;
  }

  .elmo-offset-xl-9 {
    margin-left: 37.5%;
  }

  .elmo-offset-xl-10 {
    margin-left: 41.66667%;
  }

  .elmo-offset-xl-11 {
    margin-left: 45.83333%;
  }

  .elmo-offset-xl-12 {
    margin-left: 50%;
  }

  .elmo-offset-xl-13 {
    margin-left: 54.16667%;
  }

  .elmo-offset-xl-14 {
    margin-left: 58.33333%;
  }

  .elmo-offset-xl-15 {
    margin-left: 62.5%;
  }

  .elmo-offset-xl-16 {
    margin-left: 66.66667%;
  }

  .elmo-offset-xl-17 {
    margin-left: 70.83333%;
  }

  .elmo-offset-xl-18 {
    margin-left: 75%;
  }

  .elmo-offset-xl-19 {
    margin-left: 79.16667%;
  }

  .elmo-offset-xl-20 {
    margin-left: 83.33333%;
  }

  .elmo-offset-xl-21 {
    margin-left: 87.5%;
  }

  .elmo-offset-xl-22 {
    margin-left: 91.66667%;
  }

  .elmo-offset-xl-23 {
    margin-left: 95.83333%;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .flex-shrink-xl-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-shrink-xl-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-grow-xl-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-grow-xl-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
}

@media (min-width: 1880px) {
  .elmo-col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .elmo-col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .elmo-col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }

  .elmo-col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .elmo-col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .elmo-col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .elmo-col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }

  .elmo-col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .elmo-col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }

  .elmo-col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .elmo-col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .elmo-col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .elmo-col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }

  .elmo-col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .elmo-col-xxl-13 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }

  .elmo-col-xxl-14 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .elmo-col-xxl-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .elmo-col-xxl-16 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .elmo-col-xxl-17 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }

  .elmo-col-xxl-18 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .elmo-col-xxl-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }

  .elmo-col-xxl-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .elmo-col-xxl-21 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .elmo-col-xxl-22 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .elmo-col-xxl-23 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }

  .elmo-col-xxl-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xxl-last {
    -webkit-box-ordinal-group: 26;
    -ms-flex-order: 25;
    order: 25;
  }

  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .order-xxl-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xxl-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order: 14;
    order: 14;
  }

  .order-xxl-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order: 15;
    order: 15;
  }

  .order-xxl-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order: 16;
    order: 16;
  }

  .order-xxl-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order: 17;
    order: 17;
  }

  .order-xxl-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order: 18;
    order: 18;
  }

  .order-xxl-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order: 19;
    order: 19;
  }

  .order-xxl-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }

  .order-xxl-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order: 21;
    order: 21;
  }

  .order-xxl-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order: 22;
    order: 22;
  }

  .order-xxl-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order: 23;
    order: 23;
  }

  .order-xxl-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order: 24;
    order: 24;
  }

  .elmo-offset-xxl-0 {
    margin-left: 0;
  }

  .elmo-offset-xxl-1 {
    margin-left: 4.16667%;
  }

  .elmo-offset-xxl-2 {
    margin-left: 8.33333%;
  }

  .elmo-offset-xxl-3 {
    margin-left: 12.5%;
  }

  .elmo-offset-xxl-4 {
    margin-left: 16.66667%;
  }

  .elmo-offset-xxl-5 {
    margin-left: 20.83333%;
  }

  .elmo-offset-xxl-6 {
    margin-left: 25%;
  }

  .elmo-offset-xxl-7 {
    margin-left: 29.16667%;
  }

  .elmo-offset-xxl-8 {
    margin-left: 33.33333%;
  }

  .elmo-offset-xxl-9 {
    margin-left: 37.5%;
  }

  .elmo-offset-xxl-10 {
    margin-left: 41.66667%;
  }

  .elmo-offset-xxl-11 {
    margin-left: 45.83333%;
  }

  .elmo-offset-xxl-12 {
    margin-left: 50%;
  }

  .elmo-offset-xxl-13 {
    margin-left: 54.16667%;
  }

  .elmo-offset-xxl-14 {
    margin-left: 58.33333%;
  }

  .elmo-offset-xxl-15 {
    margin-left: 62.5%;
  }

  .elmo-offset-xxl-16 {
    margin-left: 66.66667%;
  }

  .elmo-offset-xxl-17 {
    margin-left: 70.83333%;
  }

  .elmo-offset-xxl-18 {
    margin-left: 75%;
  }

  .elmo-offset-xxl-19 {
    margin-left: 79.16667%;
  }

  .elmo-offset-xxl-20 {
    margin-left: 83.33333%;
  }

  .elmo-offset-xxl-21 {
    margin-left: 87.5%;
  }

  .elmo-offset-xxl-22 {
    margin-left: 91.66667%;
  }

  .elmo-offset-xxl-23 {
    margin-left: 95.83333%;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .flex-shrink-xxl-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-shrink-xxl-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-grow-xxl-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-grow-xxl-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
}

@media (min-width: 2480px) {
  .elmo-col-xxxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .elmo-col-xxxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .elmo-col-xxxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }

  .elmo-col-xxxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .elmo-col-xxxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .elmo-col-xxxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .elmo-col-xxxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }

  .elmo-col-xxxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .elmo-col-xxxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }

  .elmo-col-xxxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .elmo-col-xxxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .elmo-col-xxxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .elmo-col-xxxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }

  .elmo-col-xxxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .elmo-col-xxxl-13 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }

  .elmo-col-xxxl-14 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .elmo-col-xxxl-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .elmo-col-xxxl-16 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .elmo-col-xxxl-17 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }

  .elmo-col-xxxl-18 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .elmo-col-xxxl-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }

  .elmo-col-xxxl-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .elmo-col-xxxl-21 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .elmo-col-xxxl-22 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .elmo-col-xxxl-23 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    max-width: 95.83333%;
  }

  .elmo-col-xxxl-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xxxl-last {
    -webkit-box-ordinal-group: 26;
    -ms-flex-order: 25;
    order: 25;
  }

  .order-xxxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xxxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xxxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xxxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xxxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xxxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xxxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xxxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xxxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xxxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xxxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xxxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xxxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .order-xxxl-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xxxl-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order: 14;
    order: 14;
  }

  .order-xxxl-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order: 15;
    order: 15;
  }

  .order-xxxl-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order: 16;
    order: 16;
  }

  .order-xxxl-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order: 17;
    order: 17;
  }

  .order-xxxl-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order: 18;
    order: 18;
  }

  .order-xxxl-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order: 19;
    order: 19;
  }

  .order-xxxl-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }

  .order-xxxl-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order: 21;
    order: 21;
  }

  .order-xxxl-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order: 22;
    order: 22;
  }

  .order-xxxl-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order: 23;
    order: 23;
  }

  .order-xxxl-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order: 24;
    order: 24;
  }

  .elmo-offset-xxxl-0 {
    margin-left: 0;
  }

  .elmo-offset-xxxl-1 {
    margin-left: 4.16667%;
  }

  .elmo-offset-xxxl-2 {
    margin-left: 8.33333%;
  }

  .elmo-offset-xxxl-3 {
    margin-left: 12.5%;
  }

  .elmo-offset-xxxl-4 {
    margin-left: 16.66667%;
  }

  .elmo-offset-xxxl-5 {
    margin-left: 20.83333%;
  }

  .elmo-offset-xxxl-6 {
    margin-left: 25%;
  }

  .elmo-offset-xxxl-7 {
    margin-left: 29.16667%;
  }

  .elmo-offset-xxxl-8 {
    margin-left: 33.33333%;
  }

  .elmo-offset-xxxl-9 {
    margin-left: 37.5%;
  }

  .elmo-offset-xxxl-10 {
    margin-left: 41.66667%;
  }

  .elmo-offset-xxxl-11 {
    margin-left: 45.83333%;
  }

  .elmo-offset-xxxl-12 {
    margin-left: 50%;
  }

  .elmo-offset-xxxl-13 {
    margin-left: 54.16667%;
  }

  .elmo-offset-xxxl-14 {
    margin-left: 58.33333%;
  }

  .elmo-offset-xxxl-15 {
    margin-left: 62.5%;
  }

  .elmo-offset-xxxl-16 {
    margin-left: 66.66667%;
  }

  .elmo-offset-xxxl-17 {
    margin-left: 70.83333%;
  }

  .elmo-offset-xxxl-18 {
    margin-left: 75%;
  }

  .elmo-offset-xxxl-19 {
    margin-left: 79.16667%;
  }

  .elmo-offset-xxxl-20 {
    margin-left: 83.33333%;
  }

  .elmo-offset-xxxl-21 {
    margin-left: 87.5%;
  }

  .elmo-offset-xxxl-22 {
    margin-left: 91.66667%;
  }

  .elmo-offset-xxxl-23 {
    margin-left: 95.83333%;
  }

  .align-self-xxxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xxxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .flex-shrink-xxxl-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-shrink-xxxl-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-grow-xxxl-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-grow-xxxl-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
}
