.week-rosters-grid {
  &__blocks {
    &.is-disabled {
      .elmo-roster-cell,
      .shift-card,
      .shift-card-view {
        pointer-events: none !important;
      }

      .delete-shift-icon,
      .toggle-contextual-menu,
      .resizer {
        display: none !important;
      }
    }
  }
  .week-rosters-grid__cell {
    &.is-disabled {
      .elmo-roster-cell,
      .shift-card-view,
      .single-time-off,
      .leave-card,
      .multiple-time-offs {
        pointer-events: none !important;
      }

      .shift-card-view,
      .single-time-off,
      .leave-card,
      .multiple-time-offs {
        opacity: .8;
      }

      .delete-shift-icon,
      .toggle-contextual-menu,
      .resizer {
        display: none !important;
      }
    }
  }
}
.roster-row.is-disabled {
  .shift-card-view {
    pointer-events: none;
    .shift-card-view__button-delete {
      display: none !important;
    }
  }
}