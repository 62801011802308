.expandable-block {
  position: relative;
  padding: 5px 1em;
  border: 1px dashed #ccc;
  margin-bottom: 10px;

  $this: &;

  .p-right {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    z-index: 2;
  }

  &__wrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height .15s ease-in-out;
  }

  &__content {
    opacity: 0;
  }

  &.is-opened {
    border: none;
    padding: 40px 0 0;

    .p-right {
      top: 13px;
      right: -5px;
    }

    #{$this}__wrapper {
      overflow: inherit;
      max-height: 1000px;
    }

    #{$this}__content {
      opacity: 1;
      transition: opacity .25s ease-in-out;
    }
  }
}
