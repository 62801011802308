@import '../_lib/style/param';
.elmo-fileupload-droparea {
  width: 100%;
  height: 150px;
  border: dashed 1px $gray-400;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  outline: none;
  background: #fff;
  border-radius: 3px;
  color: $gray-700;

  .MuiSvgIcon-root {
    color: $gray-700;
  }

  .cloudUploadIcon {
    margin-bottom: 12px;
  }
}

.elmo-fileupload-files {
  width: 100%;
}

.elmo-fileupload-input {
  outline: none;
}
