@import '../../_lib/style/mixin';

.elmo-pagination {
  &__range {
    @include small-mobile-only {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}
