@import 'src/assets/css/index';
@import 'src/assets/css/variables';

.summary-days {
  margin-top: 21px;
  overflow: hidden;
  margin-left: -1px;

  &__total {
    padding: 17px 0 7px 0;
    border-radius: 0 3px 3px 3px;
    background: #ffffff;
    border: 1px solid #0077c8;
    border-right: none;
    border-top: none;
    position: relative;
    z-index: 2;
  }

  &__content {
    position: relative;
    display: block;
    border-radius: 0 3px 0 0;
    background: #ffffff;
    border: 1px solid #0077c8;
    border-left: none;
    box-shadow: $shadow-100;
    padding-top: 9px;
  }

/*  mobile styles */
  @media (max-width: $mobile-min) {
    margin-top: 0;
    display: block;
    &__content {
      border-radius: 3px;
      border: 1px solid #0077c8;
      padding: 0;
    }
  }
}
