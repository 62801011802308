@import '../_lib/style/index';

.elmo-spinner {
  display: inline-block;
  font-size: 0;

  > svg {
    animation: rotate 1.2s linear infinite;
    transform-origin: 50% 50%;
  }

  &__path {
    stroke: currentColor;
    stroke-width: 4px;
    stroke-linecap: round;
    animation: dash 2s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
