@mixin tooltip-content {
  // opacity: 0;
  // visibility: hidden;
  background-color: #595959;
  color: #fff;
  font-weight: 500;
  padding: 4px 6px;
  line-height: 1.4;
  border-radius: 3px;
  font-size: 13.5px;
  transition: all 0.25s;
  margin: 8px;
}

.elmo-tooltip {
  $this: &;
  $content: #{$this}__content;
  $margin: 4px;
  display: inline-block;

  #{ $content } {
    @include tooltip-content;
  }

  &:hover {
    #{ $content } {
      z-index: 300;
      opacity: 1;
      visibility: visible;
    }
  }
  &__on-disabled {
    .elmo-popover-base__target {
      position: relative;
      cursor: not-allowed;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }
    }
  }
}

.elmo-tooltip__content {
  @include tooltip-content;
}
