.elmo-listtable {
  &__bulkactions-btn {
    border: none;
    background: none;
    cursor: pointer;
    line-height: 48px;
    padding: 0;
    width: 48px;
    margin-left: -12px;
    margin-right: 8px;
  }

  &__header-col {
    line-height: 48px;
    display: flex;
  }

  &__sort-icon {
    svg {
      height: 20px;
      font-size: 20px;
    }
  }
}
