@import 'src/elmo-elements/_lib/style/index';

.elmo-header__title-block {
  position: relative;
  width: 100%;
  transition: all 0.3s;
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  h1 {
    margin-right: 8px;
    margin-bottom: 0;
    margin-top: 10px;
    @include text-style-title-xs;
    @include text-ellipsis;

    @include mobile {
      margin-top: 8px;
      @include text-style-title-sm;
    }

    @include tablet {
      margin-top: 4px;
      @include text-style-title-rg;
    }
  }
}
