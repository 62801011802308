@import '../_lib/style/index';

.elmo-notification {
  position: fixed;
  overflow: visible;
  z-index: 301;
  $gap: 16px;
  $this: &;
  $item: #{$this}__item;
  $icon: #{$this}__icon;
  $progress: #{$this}__progress;
  max-width: 100%;
  min-width: 288px;
  width: 700px;
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;

  @include tablet {
  }

  &__wrapper {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: calc(100% - 32px);
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap-reverse;

    @include tablet {
      // align-items: flex-start;
      // flex-wrap: wrap;
      // bottom: auto;
      // top: 0;
    }
  }

  #{ $item } {
    position: absolute;
    overflow: hidden;
    border: 1px solid $gray-300;
    background-color: $gray-100;
    border-radius: 6px;
    padding: 12px 16px;
    color: $gray-700;
    box-shadow: $shadow-400;
    opacity: 0;
    transition: all 0.25s;
    width: 100%;
    bottom: 0;
    transform: translateY(100%);
    // margin: 0 $gap $gap $gap;
    z-index: 0;

    #{ $icon } {
      margin-right: 16px;
      display: flex;
    }

    button.elmo-btn {
      border: none;

      &:hover,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }

    .elmo-notification__text {
      flex-grow: 1;
    }

    .elmo-notification__flex {
      display: flex;
      align-items: flex-start;
    }

    .elmo-notification__icon {
      flex-shrink: 0;
      flex-grow: 0;
    }

    .elmo-notification__button {
      border: none;
      background: none;
      padding: 0;
      margin-left: 16px;
      color: $gray-700;
    }

    .elmo-notification__action {
      color: $primary-border;
      flex-shrink: 0;
      flex-grow: 0;
      margin-top: -12px;
      margin-bottom: -12px;
      margin-left: 8px;
    }

    .elmo-notification__close {
      flex-shrink: 0;
      flex-grow: 0;
      color: $white;
      margin-top: -12px;
      margin-bottom: -12px;
      transform: translateX(12px);
      margin-left: auto;
    }

    @include small-mobile-only {
      .elmo-notification__flex {
        flex-wrap: wrap;
      }

      .elmo-notification__icon {
        // width: 40px;
        order: 1;
      }

      .elmo-notification__text {
        width: calc(100% - 48px); // 100% - closebutton
        order: 2;

        &--icon {
          width: calc(100% - 40px - 48px); // 100% - icon - closebutton
        }
      }

      .elmo-notification__action {
        order: 4;
        margin-left: auto;
        margin-right: -16px;
      }

      .elmo-notification__close {
        order: 3;
      }
    }

    #{ $progress } {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 5px;
      background-color: $gray-300;
      transition: width 3s;
      transition-timing-function: linear;
    }

    &--active {
      opacity: 1;
      transform: translateY(0);
      z-index: 1;

      #{ $progress } {
        width: 100%;
      }
    }

    &--dark {
      border-color: $gray-800;
      background-color: $gray-800;
      color: $white;
      border: none;

      #{ $icon } {
        color: $white;
      }

      #{ $progress } {
        background-color: $white;
      }
    }

    &--info {
      border-color: $info-border;
      background-color: $info-bg;

      #{ $icon } {
        color: $info;
      }

      #{ $progress } {
        background-color: $info-border;
      }
    }

    &--success {
      border-color: $success-border;
      background-color: $success-bg;

      #{ $icon } {
        color: $success;
      }

      #{ $progress } {
        background-color: $success-border;
      }
    }

    &--warning {
      border-color: $warning-border;
      background-color: $warning-bg;

      #{ $icon } {
        color: $warning;
      }

      #{ $progress } {
        background-color: $warning-border;
      }
    }

    &--danger {
      border-color: $danger-border;
      background-color: $danger-bg;

      #{ $icon } {
        color: $danger;
      }

      #{ $progress } {
        background-color: $danger-border;
      }
    }
  }
}
