@import '../../_lib/style/index';

.elmo-nativetable {
  &.bordered {
    border: 1px solid $gray-400;
  }

  &.striped {
    tbody {
      .elmo-nativetable-tr {
        border-top: none;

        &:nth-child(odd) {
          background: transparent;

          .elmo-nativetable-th:first-child,
          .elmo-nativetable-td:first-child {
            background-color: $gray-200;
          }
        }

        &:nth-child(even) {
          background: $gray-300;

          .elmo-nativetable-th:first-child,
          .elmo-nativetable-td:first-child {
            background-color: $gray-300;
          }
        }
      }
    }
  }

  tbody {
    .elmo-nativetable-tr {
      border-top: 1px solid $gray-400;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--fixed {
    table-layout: fixed;
  }
}

.elmo-nativetable-responsive {
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &--is-scroll {
    border-right: 1px solid $gray-400;

    .elmo-nativetable-tr {
      .elmo-nativetable-th:first-child,
      .elmo-nativetable-td:first-child {
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          right: 0;
          background-color: $gray-400;
        }
      }
    }
  }
}

.elmo-nativetable-wrapper {
  $wrapper: &;

  &--is-header-sticky {
    .elmo-nativetable-head {
      .elmo-nativetable-th,
      .elmo-nativetable-td {
        position: sticky;
        top: 0;
        background-color: $gray-200;
        z-index: 2;

        @include desktop {
          top: 0 !important;
        }
      }
    }

    &#{$wrapper}--is-first-column-sticky {
      .elmo-nativetable-head {
        .elmo-nativetable-th:first-child,
        .elmo-nativetable-td:first-child {
          z-index: 3;
        }
      }
    }
  }

  &--is-first-column-sticky {
    .elmo-nativetable-tr {
      .elmo-nativetable-th:first-child,
      .elmo-nativetable-td:first-child {
        position: sticky;
        left: 0;
        background-color: $gray-200;
        z-index: 1;
      }
    }
  }
}
