@import '../_lib/style/param';

.elmo-date-range {
  &--vertical {
    > .elmo-row {
      flex-direction: column;

      > .elmo-col {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
