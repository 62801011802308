@import '../../../../../assets/css/variables';
@import '../../../variables';

.shift-card-view {
  $this: &;
  box-shadow: 1px 1px 1px $gray-400;
  height: inherit;
  user-select: none;
  overflow: hidden;

  &:hover {
    background-color: $gray-100;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &.shift-card-view--event-type {
    background-color: #e0f8ff;
    border: 1px solid #92c5ce;
    &:hover {
      background-color: #d1f8ff;
    }
  }
  &.shift-card-view--forecast-type {
    background-color: rgb(239, 236, 230);
    border: 1px solid rgba(236, 202, 110, 0.8);
    &:hover {
      background-color: rgb(248, 232, 198);
    }
  }

  &--full-height {
    height: 100%;
  }

  &--full {
    border-radius: $cellBorderRadius;
  }
  &--top {
    border-radius: $cellBorderRadius $cellBorderRadius 0 0;
  }
  &--bottom {
    border-radius: 0 0 $cellBorderRadius $cellBorderRadius;
  }
  &--colored {
    background-color: #fff;
    transition: background-color 0.15s ease-in-out;
  }
  &--without-shadow {
    box-shadow: none;
  }
  &--clickable {
    cursor: pointer;
  }
  &__content {
    display: flex;
    padding: 3px 3px 3px 0;
    min-height: 48px;
    min-width: $cellMinWidth;
    height: inherit;
  }
  &__location {
    &--transparent {
      background-color: transparent !important;
    }
  }
  &__location-abbr {
    border-radius: 0 10px 3px 0;
    min-width: 24px;
    max-width: 24px;
    margin-right: 8px;
    text-align: center;
    padding: 2px 1px;
    text-transform: uppercase;
    background-color: $gray-400;
    &--timesheet {
      border-radius: 0 0 10px 3px;
    }
  }
  &__descr {
    padding-top: 2px;
    line-height: 1;
    flex: 1 1 0%;
    min-width: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    h3 {
      color: inherit;
    }
    p {
      color: #000;
    }
    .text-size-xs {
      letter-spacing: 0;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.67);
      font-size: 10px;
    }
  }
  &__row {
    display: flex;
    align-items: baseline;
    &.content-centered {
      align-items: center;
    }
  }
  &__title {
    flex: 1 1 0%;
    min-width: 0;
    white-space: nowrap;
    .elmo-tooltip {
      display: block;
    }
  }
  &__time {
    color: rgba(0, 0, 0, 0.67);
    font-size: 12px;
  }
  &__icons {
    flex: none;
    text-align: right;
  }
  &__messages {
    flex: none;
    width: 25px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.67);
    svg {
      height: 14px;
      width: 14px;
    }
  }
  &__button-delete {
    margin-right: -4px;
    opacity: 0;
    visibility: hidden;

    #{$this}:hover & {
      opacity: 1;
      visibility: visible;
    }
  }
  &__add-btn {
    #{$this}:hover & {
      opacity: 1;
      visibility: visible;
    }
  }
  // TODO: remove
  h5 {
    font-size: 10px !important;
    font-weight: bold !important;
    letter-spacing: -1px;
  }

  .event-date {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 95%;
    white-space: nowrap;
  }
  &__content-improved {
    padding: 0;

    .shift-card-view__descr {
      padding: 4px 4px 1px;
    }

    .shift-card-view__location {
      width: 8px;
    }

    .shift-card-view__title {
      min-height: 14px;
    }

    .shift-card-view__time {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      letter-spacing: 0.2px;
      color: rgba(0, 0, 0, 0.87);
      position: relative;
    }

    .shift-card-view__title h3 {
      font-size: 11px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.67);
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }

    .shift-card-view__title h4 {
      font-size: 11px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.67);
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  &__content-improved-v2 {
    &.shift-card-view__content {
      min-height: 56px;
    }
    .shift-card-view__row {
      min-height: 33%;
      position: relative;
    }
    .shift-card-view__row--spec-height {
      min-height: 25%;
    }
    .shift-card-view__time {
      font-size: 14px;
      line-height: 18px;
      font-style: normal;

      &--error {
        color: rgb(177,16,30);
      }
    }
    .shift-card-view__location {
      width: 4px;
    }
    .shift-card-view__title h3 {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.87);
      width: 95%;
      max-width: 95%;
      position: absolute;
    }
    .shift-card-view__descr {
      padding-top: 2px;
    }
    .breaks-duration {
      display: inline-block;
      padding-left: 3px;
      color: rgba(0, 0, 0, 0.87);
    }
    .toggle-contextual-menu {
      display: inline-block;
      vertical-align: middle;
      height: 20px;
      width: 20px;
      color: $gray-rgba-700;
      position: relative;
      right: -4px;
      &:hover {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25),
        0 0 1px rgba(9, 30, 66, 0.31);
        color: $gray-rgba-600;
      }
      svg {
        font-size: 20px;
      }
    }
    .delete-shift-icon {
      right: -4px;
    }
  }

  &:hover {
    .add-another-shift-btn {
      height: 22px;
      bottom: -22px;
      opacity: 1;
      overflow: auto;
    }
  }
}

@for $i from 0 through 19 {
  .role-color-#{$i}-primary {
    .shift-card-view__location {
      background-color: map-get($primaryColors, $i);
    }
    &.shift-card-view--filled-bg {
      .shift-card-view__content {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card-view__content {
          background-color: rgba(250, 250, 250, 0.8);
        }
      }
    }
    &.timesheet-card {
      .shift-card-view__content {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card-view__content {
          background-color: rgba(map-get($primaryColors, $i), 0.4);
        }
      }
    }
    &.has-connected-shift {
      .shift-card-view__location {
        background-color: rgba(map-get($secondaryColors, $i), 0.9);
      }
      &:hover {
        .shift-card-view__location {
          background-color: rgba(map-get($primaryColors, $i), 0);
        }
        .shift-card-view__content {
          background-color: rgba(map-get($primaryColors, $i), 0.4);
        }
      }
    }
    &.shift-card-view--selected {
      .shift-card-view__content {
        background-color: $selectedBg;
      }
      &:hover {
        .shift-card-view__content {
          background-color: $selectedBg;
        }
      }
      .shift-card-view__location {
        visibility: hidden;
      }
    }
    &.shift-card-view--highlighted {
      .shift-card-view__location,
      .shift-card-view__content {
        background: transparent !important;
      }
    }
  }
}

_:-ms-fullscreen,
:root .shift-card-view .event-date {
  max-width: 85%;
}

@import 'src/assets/css/index';

$bodyBgColor: rgba(237, 240, 245, 1);
$selectedMenuItemBgColor: rgba(34, 89, 144, 1);
$emptyRosterCellBgColor: rgba(224, 229, 239, 1);
$primaryBgColor: rgba(0, 119, 200, 1);
$eventTypeCardPrimaryColor: #cae9ff;
$eventTypeCardSecondaryColor: #b1e0ff;
$forecastTypeCardPrimaryColor: #b6dfc3;
$forecastTypeCardSecondaryColor: #8ddba6;

.breath-theme {
  .shift-card-view__content-improved .shift-card-view__time,
  .shift-card-view__content-improved .shift-card-view__title h3,
  .shift-card-view__content-improved .shift-card-view__title h4 {
    font-weight: 400;
  }

  .shift-card-view {
    &__descr {
      .text-size-xs {
        letter-spacing: 0.2px;
        font-size: 11px;
      }
    }
    &.shift-card-view--event-type {
      background-color: $eventTypeCardPrimaryColor;
      border: 1px solid $eventTypeCardSecondaryColor;
      &:hover {
        background-color: $eventTypeCardSecondaryColor;
      }
      .shift-card-view__row {
        &:last-child {
          p {
            font-weight: 500;
          }
        }
      }
      .shift-card-view__title {
        h3 {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
        p {
          font-size: 12px;
        }
      }
    }
    &.shift-card-view--forecast-type {
      background-color: $forecastTypeCardPrimaryColor;
      border: 1px solid $forecastTypeCardSecondaryColor;
      &:hover {
        background-color: $forecastTypeCardSecondaryColor;
      }
      .shift-card-view__row {
        &:last-child {
          p {
            font-weight: 500;
          }
        }
      }
      .shift-card-view__title {
        h3 {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
        p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
  .day-divider__container {
    .is-collapsed {
      .shift-card-view--event-type {
        border: none;
        box-shadow: 0 0 0 1px $eventTypeCardSecondaryColor inset;
      }
      .shift-card-view--forecast-type {
        border: none;
        box-shadow: 0 0 0 1px $forecastTypeCardSecondaryColor inset;
        .shift-card-view__row {
          .shift-card-view__title {
            p {
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}