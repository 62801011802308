$selectedBorder: #19579f;
$selectedBg: #e6f1ff;

$highlightedBorder: #ffaa00;
$highlightedBg: #fffbe0;

$roleColor-0-primary: #BDD289;
$roleColor-0-secondary: #eef4e2;

$roleColor-1-primary: #A8CDF0;
$roleColor-1-secondary: #F3F9FF;

$roleColor-2-primary: #FFC2DD;
$roleColor-2-secondary: #FFF2F9;

$roleColor-3-primary: #BFEEC9;
$roleColor-3-secondary: #EFFBF2;

$roleColor-4-primary: #D2CDF4;
$roleColor-4-secondary: #f0eefb;

$roleColor-5-primary: #F3ECB9;
$roleColor-5-secondary: #FCFAEE;

$roleColor-6-primary: #D8C0C0;
$roleColor-6-secondary: #F7F2F2;

$roleColor-7-primary: #BDEFE9;
$roleColor-7-secondary: #EFFBFA;

$roleColor-8-primary: #FFCFAD;
$roleColor-8-secondary: #FFF3EB;

$roleColor-9-primary: #E4D7C4;
$roleColor-9-secondary: #F9F6F1;

$roleColor-10-primary: #CBC8B9;
$roleColor-10-secondary: #EEEDE8;

$roleColor-11-primary: #BBCFDD;
$roleColor-11-secondary: #E4ECF1;

$roleColor-12-primary: #FFE799;
$roleColor-12-secondary: #FFF5D6;

$roleColor-13-primary: #F2D0B7;
$roleColor-13-secondary: #F9E8DC;

$roleColor-14-primary: #91CAB8;
$roleColor-14-secondary: #E4F2ED;

$roleColor-15-primary: #DA95AB;
$roleColor-15-secondary: #F4E1E7;

$roleColor-16-primary: #D6AF99;
$roleColor-16-secondary: #F3E8E2;

$roleColor-17-primary: #B1D2D1;
$roleColor-17-secondary: #E5F0F0;

$roleColor-18-primary: #E4B4E0;
$roleColor-18-secondary: #F4E1F3;

$roleColor-19-primary: #BAC9C4;
$roleColor-19-secondary: #E0EAE6;

$primaryColors: (
  0: $roleColor-0-primary,
  1: $roleColor-1-primary,
  2: $roleColor-2-primary,
  3: $roleColor-3-primary,
  4: $roleColor-4-primary,
  5: $roleColor-5-primary,
  6: $roleColor-6-primary,
  7: $roleColor-7-primary,
  8: $roleColor-8-primary,
  9: $roleColor-9-primary,
  10: $roleColor-10-primary,
  11: $roleColor-11-primary,
  12: $roleColor-12-primary,
  13: $roleColor-13-primary,
  14: $roleColor-14-primary,
  15: $roleColor-15-primary,
  16: $roleColor-16-primary,
  17: $roleColor-17-primary,
  18: $roleColor-18-primary,
  19: $roleColor-19-primary
);
$secondaryColors: (
  0: $roleColor-0-secondary,
  1: $roleColor-1-secondary,
  2: $roleColor-2-secondary,
  3: $roleColor-3-secondary,
  4: $roleColor-4-secondary,
  5: $roleColor-5-secondary,
  6: $roleColor-6-secondary,
  7: $roleColor-7-secondary,
  8: $roleColor-8-secondary,
  9: $roleColor-9-secondary,
  10: $roleColor-10-secondary,
  11: $roleColor-11-secondary,
  12: $roleColor-12-secondary,
  13: $roleColor-13-secondary,
  14: $roleColor-14-secondary,
  15: $roleColor-15-secondary,
  16: $roleColor-16-secondary,
  17: $roleColor-17-secondary,
  18: $roleColor-18-secondary,
  19: $roleColor-19-secondary
);
