@import '../_lib/style/index';

.elmo-badge {
  $this: &;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  @include text-style-body-xs;
  letter-spacing: 1.25px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: $gray-700;
  color: $white;
  height: 24px;
  line-height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 12px;
  min-width: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  max-width: 100%;
  vertical-align: middle;

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
  }

  &__label {
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__popover-icon {
    opacity: 0.67;
  }

  &--is-circular {
    width: 24px;
    letter-spacing: 0;
    padding: 0;

    .elmo-badge__label {
      white-space: nowrap;
      overflow: hidden;
      padding: 0;
      text-align: center;
    }
  }

  &--grey {
    background-color: $gray-700;
    color: $white;

    &#{$this}--lighter {
      color: $gray-700;
      background-color: $gray-200;
    }
  }

  &--info {
    background-color: $info;

    &#{$this}--lighter {
      color: $gray-700;
      background-color: $info-bg;
    }
  }

  &--success {
    background-color: $success;

    &#{$this}--lighter {
      color: $gray-700;
      background-color: $success-bg;
    }
  }

  &--warning {
    background-color: $warning;

    &#{$this}--lighter {
      color: $gray-700;
      background-color: $warning-bg;
    }

    color: $gray-800;
  }

  &--danger {
    background-color: $danger;

    &#{$this}--lighter {
      color: $gray-700;
      background-color: $danger-bg;
    }
  }

  &--outlined {
    background-color: #fff;
    border: 1px solid $gray-700;
    color: $gray-800;
  }

  &--role-status {
  }

  &--role-label {
    height: 20px;
    line-height: 18px;
    border-radius: 3px;
    border-color: $gray-700;
    padding-left: 3px;
    padding-right: 3px;

    #{$this}__label {
      letter-spacing: 0.4px;
      font-size: 12px;
      color: $gray-700;
      line-height: 18px;
      padding: 0;
    }
  }

  &--role-info {
    background-color: $black;
    border-radius: 3px;
    text-transform: none;
    padding: 15px 5px 15px 5px;
    font-weight: normal;

    .elmo-badge__label {
      padding-left: 5px;
      padding-right: 0;
    }

    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
}
