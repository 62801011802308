@import '../../assets/css/variables';

.standalone-info-icon {
  .elmo-radio {
    &.elmo-radio--disabled .elmo-radio__label {
      color: rgba(0,0,0,.67);
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 4px solid rgba(0,0,0,.67);
      position: absolute;
      display: block;
      z-index: 1;
      bottom: 4px;
      left: 50%;
      margin-left: -6px;
      @media (min-width: $mobile-max) {
        display: none;
      }
    }
  }
}