.awards-list {
  margin-bottom: 25px;
  .accordion-item {
    border-bottom: 1px solid #aaa;
    box-shadow: 0 0 3px #ccc;
    background: #fff;
    margin-bottom: 5px;
  }
}

.accordion-item {
  border-radius: 3px;
  &.current-award {
    font-weight: 500;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      width: 3px;
      background: #0077c8;
      border-radius: 3px 0 0 3px;
    }
    .award-name {
      span:after {
        content: '*';
        display: inline-block;
      }
    }
  }
  &__title {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    &:hover {
      background: #fafafa;
    }
  }
  &__actions {
    margin-left: auto;
    min-width: 98px;
  }
  &__content {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    &.is-expanded {
      border-top: 1px solid #ccc;
      overflow: auto;
      max-height: 1000px;
      padding: 5px 10px;
      background: #fafafa;
    }
  }
  .award-name {
    min-width: 20%;
    max-width: 40%;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .award-description {
    min-width: 30%;
    padding: 0 20px;
    margin: 0 auto;
  }
  &.accordion-header {
    display: flex;
    align-items: center;
    padding: 0 0 10px;
    background: none;
    border: none;
    box-shadow: none;
    font-weight: 500;
  }
  .td-border-right {
    border-right: 1px solid #ccc;
  }
}
.award-content {
  padding: 10px 15px;
  h2 {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  table {
    border: 1px solid #ccc;
  }
  td, th {
    padding: 10px;
  }
  .table-header {
    background: #efefef;
    th {
      color: #000;
      font-weight: 500;
    }
  }
  .pay-element {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    &:last-child {
      border: none;
    }
  }
}