@import '../../../../assets/css/index';
@import '../../../../assets/css/variables';

.roster-view-scrollable-wrapper {
  margin-right: -(map_get($contentXSpacing, 'default'));
  margin-left: -(map_get($contentXSpacing, 'default'));
  margin-bottom: -(map_get($contentYSpacing, 'default'));

  @include mobile {
    margin-right: -(map_get($contentXSpacing, 'mobile'));
    margin-left: -(map_get($contentXSpacing, 'mobile'));
    margin-bottom: -(map_get($contentYSpacing, 'mobile'));
  }

  @include tablet {
    margin-right: -(map_get($contentXSpacing, 'tablet'));
    margin-left: -(map_get($contentXSpacing, 'tablet'));
    margin-bottom: -(map_get($contentYSpacing, 'tablet'));
  }

  &__header-wrapper {
    overflow: hidden;
  }

  &__content-wrapper {
    overflow-x: auto;
    padding-bottom: map_get($contentYSpacing, 'default');

    @include mobile {
      padding-bottom: map_get($contentYSpacing, 'mobile');
    }

    @include tablet {
      padding-bottom: map_get($contentYSpacing, 'tablet');
    }
  }

  &__content-inner {
    display: inline-block;
    min-width: 100%;
  }
}
