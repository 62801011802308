@import '../../../../assets/css/index';
@import '../../../../assets/css/variables';

.elmo-roster-site-legend {
  $this: &;
  $borderRadius: 6px;
  $leftOverlap: -8px;

  background-color: $white;
  min-height: $cellMinHeight;
  min-width: 40px;
  height: 100%;
  border-radius: $borderRadius;
  box-shadow: $shadow-100;

  @media (min-width: $mobile-min) {
    min-width: $cellMinWidth;
  }

  .is-disabled {
    opacity: .5;
    position: relative;
    &:before {
      display: block;
      opacity: .35;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      content: '';
      border-radius: $borderRadius 0 0 $borderRadius;
    }

  }

  &__mobile-card-placeholder {
    display: flex;
    flex-direction: column;
    height: inherit;
    text-align: left;
    &:after {
      display: block;
      width: 0;
      height: 0;
      content: '';
      border-top: 6px solid transparent;
      border-bottom: 0 solid transparent;
      border-right: 6px solid #000;
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
    @media (min-width: $mobile-min) {
      display: none;
      &:after {
        display: none;
      }
    }
  }

  &__desktop-card-placeholder {
    display: none;
    min-height: 100%;
    @media (max-width: $small-mobile-max) {
      &.is-opened {
        display: flex;
        border-radius: $borderRadius;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #fff;
        width: 196px;
        box-shadow: 1px 1px 1px $gray-400;
        #{$this}__icon-wrapper {
          margin-right: 8px;
        }
        #{$this}__label {
          padding-left: 0;
          padding-top: 8px;
          text-align: left;
        }
        &:after {
          display: block;
          width: 0;
          height: 0;
          content: '';
          border-bottom: 6px solid transparent;
          border-top: 0 solid transparent;
          border-left: 6px solid #000;
          position: absolute;
          right: 5px;
          bottom: 5px;
        }
      }
    }
    @media (min-width: $mobile-min) {
      display: flex;
      height: 100%;
    }
  }

  &__icon-wrapper {
    padding: 6px;
    border-radius: $borderRadius $borderRadius 0 0;
    @media (min-width: $mobile-min) {
      flex-grow: 0;
      flex-shrink: 0;
      padding: 16px 6px;
      max-width: 36px;
      width: 36px;
      line-height: 1;
      margin-right: 8px;
    }

    &--desktop {
      border-radius: $borderRadius 0 0 $borderRadius;
    }
  }

  &__icon {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
  }

  &__content-wrapper {
    min-width: 0;
    padding-right: 8px;
    width: 100%;

    svg {
      width: 14px;
      height: 14px;
    }

    .elmo-tooltip {
      display: block;
    }
  }

  &__label {
    @include text-style-heading-xxs;
    padding: 6px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    @media (min-width: $mobile-min) {
      margin-top: 16px;
      margin-bottom: 7px;
      padding: 0;

      #{$this}--unassigned-row &,
      #{$this}--single-row &,
      #{$this}--always-single-row & {
        margin-bottom: 0;
        margin-top: 6px;
      }

      #{$this}--all-label & {
        margin-bottom: 6px;
        margin-top: 16px;
      }

      #{$this}--no-label & {
        margin-top: 16px;
      }
    }
  }

  &__duration {
    @include text-style-body-xs;

    display: flex;
    align-items: center;
    color: $gray-700;
    margin-top: -4px;

    &:last-child {
      margin-bottom: 6px;
    }

    #{$this}--unassigned-row &,
    #{$this}--single-row &,
    #{$this}--always-single-row & {
      margin-top: 0;
      &:last-child {
        display: none;
      }
    }
  }

  &__duration-quantity {
    flex-shrink: 0;
    margin: 4px 4px 0;
    width: 21px;
  }
  &__duration-time {
    flex-shrink: 0;
    margin: 4px 4px 0;
  }

  &.ui-roster-legend {
    .elmo-roster-site-legend__icon-wrapper {
      width: 32px;
      padding: 0;
      @media (min-width: $mobile-min) {
        width: 32px;
        margin-right: 0;
        padding: 6px;
      }
      * {
        display: none;
      }
    }
    .elmo-roster-site-legend__label {
      font-weight: 600;
      line-height: 16px;
      color: rgba(0,0,0,0.87);
      @media (min-width: $mobile-min) {
        margin-top: 9px;
        margin-bottom: 0;
        padding: 0;
      }
    }

    &.elmo-roster-site-legend--no-label {
      .elmo-roster-site-legend__label {
        margin-top: 16px;
      }
      @media (max-width: $small-mobile-max) {
        .is-opened {
          #{$this}__label {
            padding-top: 8px;
            margin-top: 8px;
          }
        }
      }
    }

    .elmo-roster-site-legend__duration {
      @include text-legend-duration-xs;

      display: flex;
      align-items: center;
      color: $gray-700;
      margin-bottom: 2px;
      margin-top: 0;
    }

    @media (max-width: $small-mobile-max) {
      .is-opened {
        #{$this}__label {
          padding-left: 0;
          padding-top: 8px;
          padding-bottom: 0;
        }
      }
    }

  }

}
