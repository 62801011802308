@import "../../assets/css/_param";
@import "../../assets/css/mixin";

$bodyBgColor: rgba(237,240,245,1);
$selectedMenuItemBgColor: rgba(34,89,144,1);
$emptyRosterCellBgColor: rgba(224,229,239,1);
$primaryBgColor: rgba(0,119,200,1);
$eventTypeCardPrimaryColor: #CAE9FF;
$eventTypeCardSecondaryColor: #B1E0FF;
$forecastTypeCardPrimaryColor: #B6DFC3;
$forecastTypeCardSecondaryColor: #8DDBA6;

.breath-theme {
  .roster-row__left-element-wrapper {
    background-color: $bodyBgColor;
  }

  .elmo-nav-menu {
    .is-selected {
      background-color: $selectedMenuItemBgColor;
      a {
        font-weight: 400;
      }
    }
  }

  .elmo-roster-legend.ui-roster-legend,
  .elmo-roster-site-legend.ui-roster-legend {
    .elmo-avatar.grey-avatar .elmo-avatar__label-icon {
      background: rgba(206,232,251,1);
    }
    .elmo-roster-legend__label,
    .elmo-roster-site-legend__label {
      padding-bottom: 2px;
      span {
        font-weight: 500;
        font-size: 12px;
      }
    }
    .elmo-roster-legend__icon-wrapper {
      svg {
        path:last-child {
          fill: $danger;
        }
      }
    }
  }

  .elmo-roster-legend--events {
    .elmo-roster-legend__content-wrapper {
      padding-right: 0;
    }
    .elmo-roster-legend__icon-wrapper {
      max-width: 36px;
      width: 36px;
      svg {
        path {
          fill: #18a55b;
        }
      }
    }
    .elmo-link.add-row-button {
      strong {
        font-weight: 500;
      }
    }
  }

  .summary-button__label p.elmo-typography.text-size-rg,
  .shift-card__content-improved .shift-card__time,
  .shift-card__content-improved .shift-card__title h3,
  .shift-card__content-improved .shift-card__title h4,
  .elmo-btn .elmo-btn__label {
    font-weight: 400;
  }

  .elmo-roster-cell,
  .week-rosters-grid__group-day-block.total-info {
    background-color: $emptyRosterCellBgColor;
  }

  .roster-week-navigation__label {
    &.current {
      background-color: $primaryBgColor;
    }
  }
  .shift-card {
    &__descr {
      .text-size-xs {
        letter-spacing: 0.2px;
        font-size: 11px;
      }
    }
    &.shift-card--event-type {
      background-color: $eventTypeCardPrimaryColor;
      border: 1px solid $eventTypeCardSecondaryColor;
      &:hover {
        background-color: $eventTypeCardSecondaryColor;
      }
      .shift-card__row {
        &:last-child {
          p {
            font-weight: 500;
          }
        }
      }
      .shift-card__title {
        h3 {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
        p {
          font-size: 12px;
        }
      }
    }
    &.shift-card--forecast-type {
      background-color: $forecastTypeCardPrimaryColor;
      border: 1px solid $forecastTypeCardSecondaryColor;
      &:hover {
        background-color: $forecastTypeCardSecondaryColor;
      }
      .shift-card__row {
        &:last-child {
          p {
            font-weight: 500;
          }
        }
      }
      .shift-card__title {
        h3 {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
        p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
  .day-divider__container {
    .is-collapsed {
      .shift-card--event-type {
        border: none;
        box-shadow: 0 0 0 1px $eventTypeCardSecondaryColor inset;
      }
      .shift-card--forecast-type {
        border: none;
        box-shadow: 0 0 0 1px $forecastTypeCardSecondaryColor inset;
        .shift-card__row {
          .shift-card__title {
            p {
              line-height: 14px;
            }
          }
        }
      }
    }
  }
  .elmo-roster-cell--placeholder{
    &:hover {
      background-color: $emptyRosterCellBgColor;
    }
  }
  .roster-map .roster-focusable{
    &:focus {
      background-color: $emptyRosterCellBgColor;
    }
  }
  .week-rosters-grid__group-day-block {
    &.total-info {
      border-radius: 3px;
      .total-info__label {
        max-width: 80%;
        margin: 0 auto;
        height: 100%;
        text-align: center;
        * {
          align-self: center;
        }
        span {
          text-align: right;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: .5px;
        }
      }
      strong {
        font-weight: 400;
        color: rgba(0,0,0,0.45);
      }
    }
  }

  .roster-view-scrollable-wrapper,
  .elmo-sticky--sticked.elmo-sticky--without-spacing:before {
    background-color: $bodyBgColor;
  }

  // AppSwitcher
  .elmo-draw-menu {
    $breathePrimary: #0077c8;
    $breathePrimaryBg: #E6F1FA;
    $this: &;

    .elmo-custom-dropdown__menu-item--has-deafult-divider {
      margin-bottom: 32px;
    }

    .app-switcher__logout-icon {
      color: #545454;
    }

    &__button {
      &--active {
        #{$this}__main-icon {
          background-color: $breathePrimaryBg;
        }

        &:hover #{$this}__main-icon {
          background-color: $breathePrimaryBg;
        }
      }
    }

    &__main-icon {
      color: $breathePrimary;

    }

    &__option-icon {
      color: $breathePrimary;

    }

    &__option {
      $thisOption: &;

      &#{$thisOption}--active {
        background: $breathePrimaryBg;

        &:hover {
          background: $breathePrimaryBg;
        }
      }

      &--clickable {
        &:hover {
          background: $breathePrimaryBg;
        }

        &#{$thisOption}--active {
          background: $breathePrimaryBg;

          &:hover {
            background: $breathePrimaryBg;
          }
        }

        &#{$thisOption}--inactive {
          background: $white;

          &:hover {
            background: $breathePrimaryBg;
          }
        }
      }
    }

    &__option-label {
      &--active {
        color: $breathePrimary;
      }
    }

    &__option-badge {
      background-color: $breathePrimary;
    }
  }

  .roster-site-view-heading__inner-wrapper {
    background-color: $bodyBgColor;
  }

  // BP2-5014
  .toggle-view {
    .elmo-radio-buttons--solid .elmo-radio {
      border-radius: 0;
      border: 1px solid #c4c7d0;
      box-shadow: none;
      margin-right: -1px;
      color: #545454;
      &.radio-btn-left {
        border-radius: 3px 0 0 3px;
        margin-left: 0;
      }
      &.radio-btn-right {
        border-radius: 0 3px 3px 0;
      }
      &.elmo-radio--checked {
        border-color: $primaryBgColor;
        .elmo-radio__label {
          font-weight: 500;
        }
      }
    }
  }
  .roster-filters {
    .roster-filters__checkbox-select,
    .rta-switcher-button {
      box-shadow: none;
      border: 1px solid #c4c7d0;
    }
  }
  .roster-copy-paste {
    .roster-copy-paste__copy-btn {
      box-shadow: none;
      border: 1px solid #c4c7d0;
      color: #515151;
    }
    .roster-copy-paste__options-btn {
      box-shadow: none;
      border: 1px solid #c4c7d0;
      border-left: none;
    }
  }
}

.elmo-header {
  .elmo-tabs__nav>ul>.elmo-tabs__item a {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
  }
}

#add-unavailability {
  span.elmo-btn__label {
    text-transform: capitalize;
  }
}

.MuiPaper-root {
  .flex-start-align {
    .elmo-form-container__inner {
      align-items: flex-start;
    }
  }
  .row-two-cols {
    display: flex;
    justify-content: space-between;
    .elmo-col {
      max-width: 48%;
    }
  }
  .form-item-small {
    width: 99%;
    margin-top: -2px;
    .elmo-formitem__control__item {
      display: block;
      .elmo-radio-buttons {
        width: 100%;
        display: flex;
        align-items: center;
        .elmo-radio {
          width: 100%;
        }
      }

      .elmo-radio-buttons .elmo-radio__label,
      .elmo-radio-buttons .elmo-radio--checked .elmo-radio__label {
        padding: 0.5em 0;
        margin: 0 auto;
      }
    }
  }

  .custom-filters-wrapper {
    .elmo-col:first-child {
      padding-right: 5px;
    }

    .elmo-col:last-child {
      padding-left: 5px;
    }
  }
}

.people-shift-group {
  .elmo-radio {
    margin-right: 0;
    margin-bottom: 2px;
  }
}
