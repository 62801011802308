@import "../../../variables";
@import "./src/assets/css/variables";

.roster-map {
  .roster-focusable {
    &:focus {
      background: rgba(0, 0, 0, 0.125);
      transition: background-color .2s ease-in-out;
      cursor: pointer;
      outline: none;
      .shift-card-view {
        box-shadow: 0 0 1px 1px $selectedBorder;
      }
      .timesheet-card-view {
        box-shadow: 0 0 1px 1px $selectedBorder;
      }
      input {
        border-color: $selectedBorder;
      }
    }
  }
}