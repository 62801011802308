.duration-input-wrapper {
  .mask-input {
    display: none;
  }
  &.mask-visible {
    .state-input {
      display: none;
    }
    .mask-input {
      display: block;
    }
  }
}