@import '../../../../assets/css/variables';
@import '../../../../assets/css/mixin';

.legend-placeholder {
  flex: 0 0 auto;
  width: map_get($legendWidth, 'default');
  height: 10px;
  margin-right: $yGap;

  @include mobile {
    width: map_get($legendWidth, 'mobile');
  }
}
