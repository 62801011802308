.covid-tracing-mode-active {
  .resizer,
  .add-another-shift-btn,
  .delete-shift-icon,
  .shift-card__icons,
  .shift-card-view__icons {
    display: none !important;
  }
  .future-cell .shift-card-view,
  .future-cell .shift-card {
    display: none;
  }
  .elmo-roster-cell {
    background-image: none !important;
    cursor: auto !important;
  }
  .total-info.future-cell {
    span {
      display: none;
    }
  }
  .toggle-panel-button {
    display: none;
  }
  .roster-focusable {
    &:focus {
      .shift-card-view,
      .shift-card {
        box-shadow: none;
      }
    }
  }
  .marked-contacted {
    .shift-card__content,
    .shift-card-view__content {
      box-shadow: 0 0 1px 1px #ff585d inset;
    }
    .shift-card-view__location,
    .shift-card__location {
      top: 1px;
      position: relative;
      height: calc(100% - 2px);
      width: 3px;
      margin-left: 1px;
    }
  }
  .marked-covid {
    .shift-card__content,
    .shift-card-view__content {
      box-shadow: 0 0 1px 2px #ff585d inset;
    }
    .shift-card-view__location,
    .shift-card__location {
      top: 2px;
      position: relative;
      height: calc(100% - 4px);
      width: 2px;
      margin-left: 2px;
    }
  }
  .no-contacted-shifts {
    opacity: .5;
    pointer-events: none;
  }
}