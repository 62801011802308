.printing-content {
  padding-top: 50px;
  display: none;

  .area-table {
    page-break-after: always
  }

  .elmo-roster-unavailability-cell {
    .elmo-typography.text-size-sm {
      font-size: 12px;
    }
  }

  .elmo-roster-cell--placeholder {
    display: none;
  }

  .events-table {
    .week-rosters-grid__group-day-block {
      border-right: 1px solid #ccc !important;
      border-bottom: 1px solid #ccc !important;
      padding: 0;
      min-width: 74px;
      min-height: 56px;
      height: 56px;
      background: none;

      &:last-child {
        border-right: none !important;
      }

      .elmo-roster-cell--top {
        border-radius: 0;
        background: none;
      }

      .event-shift-card {
        border-radius: 0;
        margin: 1px;
        box-shadow: none;
      }
    }

    .total-info {
      background: none;
      height: 20px;
      min-height: 20px;
      border-bottom: none !important;
      box-shadow: none;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse !important;
    border-spacing: 0;
    overflow: hidden;

    td {
      padding: 0;
      vertical-align: top;
      page-break-inside: avoid;
      white-space: nowrap;
    }

    td.border-cell {
      border: 1px solid #cccccc;
      table {
        border-bottom: 1px solid #cccccc;
        &:last-child {
          border-bottom: none;
        }
      }
    }

    td.border-right {
      border-right: 1px solid #cccccc;
    }

    td.border-right {
      border-right: 1px solid #cccccc;
    }

    .dayCell {
      border-right: 1px solid #cccccc;
      .wrap {
        min-height: 50px;
      }
    }
  }

  .shiftWrapper {
    min-height: 56px;
    width: 118px;
    padding: 0;
    box-sizing: content-box;
    &.timeOffWrapper {
      vertical-align: middle;
      padding: 0;
      span {
        letter-spacing: 0;
      }
      [data-testid~="single-time-off"],
      [data-testid="multiple-time-offs"] {
        padding: 15px 0;
      }
      [data-testid~="leave-card"] {
        padding: 20px 5px 0;
      }
    }
  }

  .timesheetWrapper {
    border-top: 1px dashed gray;
    height: 56px;
    width: 118px;
    padding: 0;
    box-sizing: content-box;
  }

  .firstCellContent {
    padding: 5px 10px;
    width: 200px;
  }

  .roleName {
    font-size: 14px;

    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    white-space: nowrap;
  }

  .shift-card {
    box-shadow: none!important;
  }
  .timesheet-card {
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 48px solid rgba(0,0,0, .1);
    }
  }

  .duration {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    svg {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }
  .header-day {
    width: calc(100% / 7);
    text-align: center;
  }
}

@media print {

  html, body {
   /* width: 210mm; // A4 Paper width
    height: 100%;*/
  }
  @page {
    /*size: landscape;
    margin-top: 0mm;
    margin-bottom: 0mm;*/
  }
  .roster-view-scrollable-wrapper__content-wrapper,
  .not-published,
  .is-collapsable,
  .summary-mobile,
  .summary-desktop,
  .elmo-layout__main-header,
  .roster-view-controls-v2,
  .elmo-nav-header-mobile__icon .elmo-btn__icon,
  .roster-view-scrollable-wrapper__header-wrapper,
  .elmo-nav-header-mobile__draw-menu-wrapper,
  .elmo-loadingoverlay,
  .elmo-notification,
  /*.elmo-layout__main-menu*/
  {
    display: none !important;
  }

  .printing-content {
    display: block;
  }

  .toggle-contextual-menu {
    display: none !important;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    margin-top: 0;
    margin-bottom: 0;
  }
  @page {
    size: landscape;
    margin: 0 15mm;
  }
}
