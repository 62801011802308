@import "src/assets/css/_param.scss";
@import "../../variables";

.covid-tracing-header {
  position: relative;
  z-index: 4;
  .elmo-header-wrapper {
    z-index: 3;
  }
  h1 {
    margin-top: 0;
  }
  @media (min-width: $desktop-min) {
    .elmo-header__base {
      padding: 18px 0;
    }
  }
  .elmo-header__close-btn {
    @media (min-width: $small-mobile-max) {
      margin-right: 10px;
    }
    @media (min-width: $desktop-lg-min) {
      margin-right: 32px;
    }
  }
  &__left {
    display: flex;
    align-items: center;
    padding-left: 10px;
    @media (min-width: $small-mobile-max) {
      padding-left: 0;
    }
    h3 {
      margin: 0 10px;
    }
    svg {
      color: #000;
    }
  }
}
